import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { Button, FormLabel, Stack, TextField, MenuItem, FormHelperText, Typography } from '@mui/material';
import { NavigateFunction, useLocation, useNavigate, useParams } from 'react-router-dom';
import { RoutesUrl } from '../../enum/routes-url';
import { getAllCategoryApi, getAllEntityApi, getAllRolesApi, getRolesApi, postRolesApi, putRolesApi } from '../../config/api';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import ErrorModal from '../others/modals/error-modal';
import Loading from '../others/loading.component';
import CardComponent from '../others/modals/card-component';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { getAllMenuProfileApi, getMenuProfileApi, postMenuProfileApi, putMenuProfileApi } from '../../config/api/api-configs/menu-profile-config';
import Axios from '../../config/axios-config';

const MenuProfileForm: React.FC = () => {

    const [state, setState] = React.useState<State>({
        name: "",
        parent_id: "",
        category_id: "",
        icons: "",
        sort_id: "",
        role_id: "",
        form_io: false,
        active: true,
    });
    const [check, setCheck] = React.useState(false);
    const [locationState, setLoactionState] = React.useState<"create" | "view" | "update">();
    const [disabled, setDisabled] = React.useState<boolean>(false);
    const [updatedisabled, setUpdateDisabled] = React.useState<boolean>(false);
    const [helperText, setHelperText] = React.useState<any>({});
    const [categoryId, setCategoryId] = React.useState([]);
    const [rolesId, setRolesId] = React.useState([]);
    const [menuProfile, setMenuProfile] = React.useState([]);
    const [open, setOpen] = React.useState(false);

    const handleClose = () => setOpen(false);

    const navigate: NavigateFunction = useNavigate();
    const location = useLocation();
    const { orgId, id, menuProfileId } = useParams();
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state: any) => state.tasks);
    const Icon = ["AcUnit", "Abc", "AddCard", "AddLocation", "AddLink"];

    useEffect(() => {
        setLoactionState(location.state.action);
        if (location.state.action === `${RoutesUrl.VIEW}`) {
            (async () => {
                const data = await getMenuProfileApi(orgId, id, menuProfileId, dispatch);
                console.log(data);

                if (data) setState(data);
            })();
        }

        if (location.state.action === `${RoutesUrl.UPDATE}`) {
            setDisabled(true);
            (async () => {
                const data = await getMenuProfileApi(orgId, id, menuProfileId, dispatch);
                console.log(data);

                if (data) setState(data);
            })();
        }
    }, []);

    useEffect(() => {
        (async () => {
            const data = await getAllCategoryApi(orgId, id, dispatch);
            if (data) setCategoryId(data);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const data = await getAllCategoryApi(orgId, id, dispatch);
            if (data) setCategoryId(data);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const data = await getAllRolesApi(orgId, id, dispatch);
            if (data) setRolesId(data);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const response = await getAllMenuProfileApi(orgId, id, dispatch);
            if (response) {
                const resFilter = response?.filter((e: any) => e.parent_id === null);
                setMenuProfile(resFilter);
            }
        })();
    }, []);

    const handleNavigate = () => {
        navigate(-1);
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (locationState === `${RoutesUrl.CREATE}`) {
            handleValidation();
        }
        else if (locationState === `${RoutesUrl.UPDATE}`) {
            handleValidation();
        }
    }

    const handleValidation = async () => {
        const error = {} as any
        if (!state.name) {
            error.name = "Name field is required"
        } else if (!/^[a-zA-Z0-9_]*$/.test(state.name)) {
            error.name = "Name should not contain special character except _";
        } else {
            error.name = ""
        }
        if (state.parent_id) {
            delete state.icons;
            if (state.parent_id !== "select any option") {
                error.parent_id = "";
                // error.iconInput = true;
            } 
            // else if (state.parent_id === "select any option") {
            //     state.parent_id = "";
            //     error.parent_id = "Parent ID must be a valuable option"
            // }
            // else {
            //     error.iconInput = false;
            // }
        }

        if (state.icons) {
            if (state.icons === "select any option") {
                state.icons = "";
                error.icons = "select any valuable option"
            } else {
                state.parent_id = "";
            }
            error.parent_id = "";
        }

        if (state.sort_id) {
            error.sort_id = ""
        } else {
            error.sort_id = "Sort ID is required"
        }

        if (state.role_id) {
            if (state.role_id !== "select any option") {
                error.role_id = "";
                // error.iconInput = true;
            } else if (state.role_id === "select any option") {
                state.role_id = "";
                error.role_id = "Category ID must be a valuable option"
            }
            // else {
            //     error.iconInput = false;
            // }
        } else {
            error.role_id = "Roles ID is required"
        }
console.log(error);

if (!error.name && !error.parent_id && !error.sort_id) {
            console.log(locationState);
            const obj = Object.assign({}, state) as any;
            if (!obj.parent_id) {
                delete obj.parent_id;
            } else {
                delete obj.category_id;
                delete obj.icons;
            }
            if (obj.sort_id) {
                obj.sort_id = +(obj.sort_id);
            }
            if(obj.role_id) {
                obj.role_id = +(obj.role_id);
                delete obj.role_id;
            }
            delete obj.icons;
            if (locationState === `${RoutesUrl.CREATE}`) {
                console.log("create");
                
                const postRoles = await postMenuProfileApi(orgId, id, obj, dispatch);
                if (postRoles) {
                    setState({
                        name: "",
                        parent_id: "",
                        category_id: "",
                        sort_id: "",
                        role_id: "",
                        icons: "",
                        form_io: false,
                        active: true,
                    });
                    toast.success('Created successfully', {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                } else {
                    setOpen(true);
                }
            }

            if (locationState === `${RoutesUrl.UPDATE}`) {
                const obj = Object.assign({}, state) as any;
                if (obj.id) {
                    delete obj.id
                }
                if (obj.parent_id) {
                    delete obj.parent_id
                }
                if(obj.sort_id) {
                    obj.sort_id = +(obj.sort_id);
                }

                const updateRoles = await putMenuProfileApi(orgId, id, menuProfileId, obj, dispatch);
                if (updateRoles) {
                    toast.success('Orgsform Successfully Updated', {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                } else {
                    setOpen(true);
                }
            }
        }
        setHelperText({ ...helperText, ...error });
    }
    // e.target.value === 'select any option' ? e.target.value = "" :
    const handleChange = (e: React.ChangeEvent<HTMLInputElement> | any) => {
        let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

        if (state.active === "true") {
            state.active = true;
        }
        else if (state.active === "false") {
            state.active = false;
        }
        setState({
            ...state,
            [e.target.name]: value
        });
        setUpdateDisabled(true);
    }


    if (isLoading) {
        return <Loading />
    }

    if (location.state.action === 'view') {

        return (
            <Stack direction='row' flexDirection='column' alignItems='center'>
                <Typography variant='h2'>{location.state.setFormName}</Typography>
                <CardComponent state={state} />
            </Stack>
        )
    }

    return (
        <Box sx={{ width: '100ch', margin: '2ch auto' }}>
            <Typography variant='h2' textAlign='center'>{location.state.setFormName}</Typography>
            <form onSubmit={(e) => { handleSubmit(e) }}>
                <Stack>
                    <FormLabel sx={{ marginBottom: '0.5ch' }}>Name</FormLabel>
                    <TextField
                        type='text'
                        size='small'
                        name='name'
                        value={state.name}
                        onChange={handleChange}
                    />
                </Stack>
                <FormHelperText id="outlined-weight-helper-text" error>{helperText.name || " "}</FormHelperText>
                <Stack>
                    <FormLabel sx={{ marginBottom: '0.5ch' }}>Parent ID</FormLabel>
                    <FormControl>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={state.parent_id || ""}
                            size='small'
                            name="parent_id"
                            disabled={disabled}
                            onChange={handleChange}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: 150
                                    }
                                }
                            }}
                        >
                            <MenuItem value="select any option" disabled={state.parent_id === '' ? true : state.parent_id === 'select any option' ? true : false}>
                                <em>{state.parent_id === 'select any option' ? "select any option" : 'none'}</em>
                            </MenuItem>
                            {
                                menuProfile.map((menu: any, index: any) => (
                                    <MenuItem key={index} value={menu.id}>{menu.name}</MenuItem>

                                ))
                            }
                        </Select>
                    </FormControl>
                    <FormHelperText id="outlined-weight-helper-text" error>{helperText.parent_id || " "}</FormHelperText>
                </Stack>
                <Stack>
                    <FormLabel sx={{ marginBottom: '0.5ch' }}>Role ID</FormLabel>
                    <FormControl>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={state.role_id || ""}
                            size='small'
                            name="role_id"
                            disabled={disabled}
                            onChange={handleChange}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: 150
                                    }
                                }
                            }}
                        >
                            <MenuItem value="select any option" disabled={state.role_id === '' ? true : state.role_id === 'select any option' ? true : false}>
                                <em>{state.role_id === 'select any option' ? "select any option" : 'none'}</em>
                            </MenuItem>
                            {
                                rolesId.map((entity: any, index: any) => (
                                    <MenuItem key={index} value={entity.id}>{entity.name}</MenuItem>

                                ))
                            }
                        </Select>
                    </FormControl>
                    <FormHelperText id="outlined-weight-helper-text" error>{helperText.role_id || " "}</FormHelperText>
                </Stack>
                {!state.parent_id && <Stack>
                    <FormLabel sx={{ marginBottom: '0.5ch' }}>Category ID</FormLabel>
                    <FormControl>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={state.category_id || ""}
                            size='small'
                            name="category_id"
                            disabled={disabled}
                            onChange={handleChange}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: 150
                                    }
                                }
                            }}
                        >
                            <MenuItem value="select any option" disabled={state.category_id === '' ? true : state.category_id === 'select any option' ? true : false}>
                                <em>{state.category_id === 'select any option' ? "select any option" : 'none'}</em>
                            </MenuItem>
                            {
                                categoryId.map((entity: any, index: any) => (
                                    <MenuItem key={index} value={entity.id}>{entity.name}</MenuItem>

                                ))
                            }
                        </Select>
                    </FormControl>
                    <FormHelperText id="outlined-weight-helper-text" error>{helperText.category_id || " "}</FormHelperText>
                </Stack>}
                {
                    state.parent_id === "" || state.parent_id === "select any option" ? <Stack>
                        <FormLabel sx={{ marginBottom: '0.5ch' }}>Icons</FormLabel>
                        <FormControl>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={state.icons || ""}
                                size='small'
                                name="icons"
                                // disabled={helperText.iconInput}
                                onChange={handleChange}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 150
                                        }
                                    }
                                }}
                            >
                                <MenuItem value="select any option" disabled={state.icons === '' ? true : state.icons === 'select any option' ? true : false}>
                                    <em>{state.icons === 'select any option' ? "select any option" : 'none'}</em>
                                </MenuItem>
                                {
                                    Icon.map((val: any, index: any) => (
                                        <MenuItem key={index} value={val}>{val}</MenuItem>

                                    ))
                                }
                            </Select>
                        </FormControl>
                        <FormHelperText id="outlined-weight-helper-text" error>{helperText.icons || " "}</FormHelperText>
                    </Stack> : null
                }
                <Stack>
                    <FormLabel sx={{ marginBottom: '0.5ch' }}>Sort ID</FormLabel>
                    <TextField
                        type='text'
                        size='small'
                        name='sort_id'
                        value={state.sort_id}
                        onChange={handleChange}
                    />
                    <FormHelperText id="outlined-weight-helper-text" error>{helperText.sort_id || " "}</FormHelperText>
                </Stack>
                <Stack>
                    <FormControlLabel
                        name='form_io'
                        value={state.form_io}
                        control={<Checkbox checked={state.form_io} onChange={handleChange} />}
                        label="Form IO"
                    />
                </Stack>
                <FormHelperText id="outlined-weight-helper-text" error>{helperText.form_io || " "}</FormHelperText>
                <Stack>
                    {
                        location.state.action === 'Create' ?
                            <Stack>
                                <FormControlLabel
                                    name='active'
                                    value={state.active}
                                    control={<Checkbox checked={state.active} onChange={handleChange} />}
                                    label="Active"
                                />
                                <FormHelperText id="outlined-weight-helper-text" error>{helperText.active || " "}</FormHelperText>
                            </Stack>
                            : null
                    }
                </Stack>

                <Stack direction='row' justifyContent='center' alignItems='center' spacing={3}>
                    <Button
                        style={{ maxWidth: '20%' }}
                        variant='contained'
                        onClick={handleNavigate}
                    >Back
                    </Button>
                    {
                        locationState === `${RoutesUrl.VIEW}` ? null :
                            <Button variant='contained' color='success' type='submit' disabled={!updatedisabled}>
                                {locationState === `${RoutesUrl.CREATE}` ? "create" : "update"}
                            </Button>}

                </Stack>
            </form>
            <ToastContainer autoClose={1000} />
            <ErrorModal open={open} setOpen={setOpen} handleClose={handleClose} />
        </Box>
    )
}
interface State {
    name: string;
    parent_id?: string;
    category_id?: string;
    icons?: string;
    role_id?: string;
    sort_id?: string;
    form_io: boolean;
    active: any;
}

export default MenuProfileForm