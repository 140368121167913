import React from 'react'
import PagesTemplate from '../components/others/pages-template';
import { Outlet, useLocation } from 'react-router-dom';
import { RoutesUrl } from '../enum/routes-url';
import EntityFieldMappingTable from '../components/page-entity-field-mapping/entity-field-mapping-table';

const EntityFieldMapping: React.FC = () => {
    const location = useLocation();  
  
    return (
        <PagesTemplate>
          <EntityFieldMappingTable />
        </PagesTemplate>
    )
}

export default EntityFieldMapping