import PagesTemplate from '../components/others/pages-template';
import UsersTable from '../components/page-users/users-table';

const UsersPage: React.FC = () => {
  return (
        <PagesTemplate>
          <UsersTable />
        </PagesTemplate>
  )
}

export default UsersPage