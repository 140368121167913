import { ACCESS_TOKEN, REFRESH_TOKEN } from "../constant/constant";
import { getStorage, removeStorage, setStorage } from "../utils/function";
import axios from "axios";

export const API_DOMAIN = process.env.REACT_APP_API_BASE_URL;

export const API_PATH = {
    requestOtp: "auth/requestOtp",
    otpGenerator: "auth/Login",
    refreshToken: "auth/refresh",
    logout: "auth/logout",
    usersURL: "admins/me?expandAll=true",
    organizations: "organizations",
    apps: "apps",
    exportSpec : "exportSpec",
    rolesPath : "user-roles",
    usersPath : "users",
    fieldsType : "field-types",
    entityMetaData : "entity-metadata",
    admins : "admins",
    adminRoles : "admin-roles",
    permissions: "permissions",
    myPermissions: "permissions/me?expandAll=true",
    entityFieldMapping : "entity-field-map",
    menuProfile : "menu-profile",
    relations : "relations",
    menuProfileRoles : "menu-profile-roles",
    category: "category",
    menuProfileDetails : "menu-profile-details",
};

const Axios = axios.create({
  baseURL: API_DOMAIN,
  // timeout: 4000,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Add a request interceptor
Axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  let token = getStorage(ACCESS_TOKEN);
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
Axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    // console.log("check 401 authorized");
    const originalRequest = error.config;
    // If the error status is 401 and there is no originalRequest._retry flag,
    // it means the token has expired and we need to refresh it
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      console.log("401");
      
      try {
        const refreshToken = getStorage(REFRESH_TOKEN);
        if (refreshToken) {
          originalRequest.headers['Authorization'] = `Bearer ${refreshToken}`;
        }
        const response = await axios.post(API_DOMAIN + API_PATH.refreshToken);
        console.log("refresh token response", response);
        setStorage(ACCESS_TOKEN, response.data.accessToken);
        setStorage(REFRESH_TOKEN, response.data.refreshToken);
        // Retry the original request with the new token
        originalRequest.headers.Authorization = `Bearer ${response.data.accessToken}`;
        return axios(originalRequest);
      } catch (error) {
        console.log("refresh token error", error);
        // Handle refresh token error or redirect to login
        removeStorage(ACCESS_TOKEN);
        removeStorage(REFRESH_TOKEN);
        window.location.href = "/login";
      }
    }
    return Promise.reject(error);
  }
);

export default Axios;

