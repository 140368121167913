import React, { useEffect } from 'react';
import { getAdminRoles_AppManagerApi, postAdminRoles_AppManagerApi, putAdminRoles_AppManagerApi } from '../../config/api';
import Box from '@mui/material/Box';
import { Button, FormHelperText, FormLabel, Stack, TextField, Typography, FormControlLabel, Checkbox } from '@mui/material';
import { NavigateFunction, useLocation, useNavigate, useParams } from 'react-router-dom';
import { RoutesUrl } from '../../enum/routes-url';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import ErrorModal from '../others/modals/error-modal';
import Loading from '../others/loading.component';
import CardComponent from '../others/modals/card-component';

const AdminRolesForm: React.FC = () => {

  const [adminsRoles, setAdminsRoles] = React.useState<State>({
    name: "",
    defaults: false,
    active: true,
  });
  const [helperText, setHelperText] = React.useState<any>({
    name: "",
    defaults: ""
  });
  const [open, setOpen] = React.useState(false);

  const { adminsRolesId } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoading } = useSelector((state: any) => state.tasks);

  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (location.state.action === `${RoutesUrl.VIEW}` || location.state.action === `${RoutesUrl.UPDATE}`) {
      (async () => {
        const data = await getAdminRoles_AppManagerApi(adminsRolesId, dispatch);
        console.log(data);
        if (data) setAdminsRoles(data);
      })();
    }
  }, []);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (location.state.action === `${RoutesUrl.CREATE}` || location.state.action === `${RoutesUrl.UPDATE}`) {
      handleValidate();
    }
  };

  const handleValidate = async () => {
    const error = {} as any
    if (!adminsRoles.name) {
      error.name = "Name field is required"
    } else if (!(adminsRoles.name.trim().length > 0)) {
      error.name = "Name should not contain empty space";
    } else {
      error.name = ""
    }
    if (!adminsRoles.defaults) {
      error.defaults = "Defaults field is required"
    } else {
      error.deaults = ""
    }

    if (!error.name) {
      if (location.state.action === `${RoutesUrl.CREATE}`) {
        const postAdminsRoles = await postAdminRoles_AppManagerApi(adminsRoles, dispatch);
        if (postAdminsRoles) {
          setAdminsRoles({
            name: "",
            defaults: false,
            active: true,
          });
          toast.success('Created successfully', {
            position: toast.POSITION.BOTTOM_RIGHT
          });
        } else {
          setOpen(true);
        }
      }

      if (location.state.action === `${RoutesUrl.UPDATE}`) {
        const obj = Object.assign({}, adminsRoles) as any;
        if (obj.id) delete obj.id;
        const updateAdminsRoles = await putAdminRoles_AppManagerApi(adminsRolesId, obj, dispatch);
        if (updateAdminsRoles) {
          toast.success('Orgsform Successfully Updated', {
            position: toast.POSITION.BOTTOM_RIGHT
          });
        } else {
          setOpen(true);
        }
      }
    }
    setHelperText({ ...helperText, ...error });
  }


  const handleNavigate = () => {
    navigate(-1);
  }

  const handleChange = (e: any) => {
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
    if (adminsRoles.active === "true") {
      adminsRoles.active = true;
    }
    else if (adminsRoles.active === "false") {
      adminsRoles.active = false;
    }
    if (adminsRoles.defaults === "true") {
      adminsRoles.defaults = true;
    }
    else if (adminsRoles.defaults === "false") {
      adminsRoles.defaults = false;
    }
    setAdminsRoles({
      ...adminsRoles,
      [e.target.name]: value
    })
  }

  if (isLoading) {
    return <Loading />
  }

  if (location.state.action === `${RoutesUrl.VIEW}`) {
    return (
      <Box>
        <Typography variant='h2' textAlign='center'>{location.state.formName}</Typography>
        <CardComponent state={adminsRoles} />
      </Box>
    )
  }

  return (
    <Box sx={{ width: '100ch', margin: '2ch auto' }}>
      <Typography variant='h2' margin={2} textAlign='center'>{location.state.formName}</Typography>
      <form onSubmit={(e) => { handleSubmit(e) }}>
        <Stack>
          <FormLabel sx={{ marginBottom: '0.5ch' }}>Name</FormLabel>
          <TextField
            type='text'
            size='small'
            name='name'
            value={adminsRoles.name}
            onChange={handleChange}
          />
        </Stack>
        <FormHelperText id="outlined-weight-helper-text" error>{helperText.name || " "}</FormHelperText>
        <Stack>
          <FormControlLabel
            name='defaults'
            value={adminsRoles.defaults}
            control={<Checkbox checked={adminsRoles.defaults} onChange={handleChange} />}
            label="Defaults"
          />
        </Stack>
        {
          location.state.action === `${RoutesUrl.CREATE}` ? null : <Stack>
          <FormControlLabel
            name='active'
            value={adminsRoles.active}
            control={<Checkbox checked={adminsRoles.active} onChange={handleChange} />}
            label="Active"
          />
        </Stack>
        }
        <Stack direction='row' justifyContent='center' alignItems='center' spacing={3}>
          <Button
            style={{ maxWidth: '20%' }}
            variant='contained'
            onClick={handleNavigate}
          >Back
          </Button>
          {
            location.state.action === `${RoutesUrl.VIEW}` ? null :
              <Button variant='contained' color='success' type='submit'>
                {location.state.action === `${RoutesUrl.CREATE}` ? "create" : "update"}
              </Button>
          }
        </Stack>
      </form>
      <ToastContainer autoClose={1000} />
      <ErrorModal open={open} setOpen={setOpen} handleClose={handleClose} />
    </Box>

  )
}

interface State {
  name: string;
  defaults: any;
  active: any;
}

export default AdminRolesForm