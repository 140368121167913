import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { Button, FormHelperText, FormLabel, Stack, TextField, Typography, capitalize } from '@mui/material';
import { NavigateFunction, useLocation, useNavigate, useParams } from 'react-router-dom';
import { RoutesUrl } from '../../enum/routes-url';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// import { Box, Button, Stack, Typography } from '@mui/material';

import ErrorModal from '../others/modals/error-modal';
import Loading from '../others/loading.component';
import { getAdmins_AppManagerApi, getAllRolesApi, postAdmins_AppManagerApi, putAdmins_AppManagerApi } from '../../config/api';
import CardComponent from '../others/modals/card-component';



const AdminsForm: React.FC = () => {

  const [admins, setAdmins] = React.useState<State>({
    name: "",
    email: "",
    phone: "",
    role_id: ""
  });
  const [helperText, setHelperText] = React.useState<any>({
    name: "",
    email: "",
    phone: "",
    role_id: ""
  });
  const [open, setOpen] = React.useState(false);
  const [activeCreate,setActiveCreate] = React.useState(true);

  const { adminsId } = useParams();
  const dispatch = useDispatch();
  const navigate: NavigateFunction = useNavigate();
  const location = useLocation();
  const { isLoading } = useSelector((state: any) => state.tasks);

  const handleClose = () => setOpen(false);


  useEffect(() => {
    if (location.state.action === `${RoutesUrl.VIEW}` || location.state.action === `${RoutesUrl.UPDATE}`) {
      (async () => {
        const data = await getAdmins_AppManagerApi(adminsId, dispatch);
        if (data) setAdmins(data);
      })();
    }
  }, []);

  const handleNavigate = () => {
    navigate(-1);
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (location.state.action === `${RoutesUrl.CREATE}` || location.state.action === `${RoutesUrl.UPDATE}`) {
      handleValidate();
    }
  };

  const handleValidate = async () => {
    const error = {} as any
    if (!admins.name) {
      error.name = "Name field is required"
    } else if (!(admins.name.trim().length > 0)) {
      error.name = "Name should not contain empty space";
    } else {
      error.name = ""
    }
    if (!admins.email) {
      error.email = "Email field is required"
    } else {
      error.email = ""
    }
    if (!admins.phone) {
      error.phone = "Phone field is required"
    } else if (!/^\d{3}-?\d{3}-?\d{4}$/.test(admins.phone)) {
      error.phone = "Phone number should be valid";
    } else {
      error.phone = ""
    }
    if (!admins.role_id) {
      error.role_id = "Rule_Type is required"
    }
    else {
      error.role_id = ""
    }

    if (!error.name && !error.email && !error.role && !error.roleId && !error.phone) {
      if (location.state.action === `${RoutesUrl.CREATE}`) {
        const postAdmins = await postAdmins_AppManagerApi(admins, dispatch);
        if (postAdmins) {
          setAdmins({
            name: "",
            email: "",
            phone: "",
            role_id: ""
          })
          toast.success('Created successfully', {
            position: toast.POSITION.BOTTOM_RIGHT
          });

        } else {
          setOpen(true);
        }
      }

      if (location.state.action === `${RoutesUrl.UPDATE}`) {
        let obj = Object.assign({}, admins) as any;
        if (obj.id) delete obj.id;
        const updateAdmins = await putAdmins_AppManagerApi(adminsId, obj, dispatch);
        if (updateAdmins?.status === 200) {
          toast.success('Orgsform Successfully Updated', {
            position: toast.POSITION.BOTTOM_RIGHT
          });
        } else {
          setOpen(true);
        }
      }
    }
    setHelperText({ ...helperText, ...error });
  }

  const handleChange = (e: any) => {
    let value;
    if (e.target.name === "role_id") value = +e.target.value;
    else value = e.target.value;
    setAdmins({
      ...admins,
      [e.target.name]: value
    });
    setActiveCreate(false);
  };

  if (isLoading) {
    return <Loading />
  }

  if (location.state.action === `${RoutesUrl.VIEW}`) {
    return (
      < Box >
        <Typography variant='h2' textAlign='center'>{admins.name}_Details</Typography>
        <CardComponent state={admins} />
      </Box >
    )


  }

  return (
    <Box sx={{ width: '100ch', margin: '2ch auto' }}>
      <Typography variant='h2' textAlign='center'>{location.state.formName}</Typography>
      <form onSubmit={(e) => { handleSubmit(e) }}>
        <Stack>
          <FormLabel sx={{ marginBottom: '0.5ch' }}>Name</FormLabel>
          <TextField
            type='text'
            size='small'
            name='name'
            value={admins.name}
            onChange={handleChange}
          />
        </Stack>
        <FormHelperText id="outlined-weight-helper-text" error>{helperText.name || " "}</FormHelperText>
        <Stack>
          <FormLabel sx={{ marginBottom: '0.5ch' }}>Email</FormLabel>
          <TextField
            type='email'
            size='small'
            name='email'
            value={admins.email}
            onChange={handleChange}
          />
        </Stack>
        <FormHelperText id="outlined-weight-helper-text" error>{helperText.email || " "}</FormHelperText>
        <Stack>
          <FormLabel sx={{ marginBottom: '0.5ch' }}>Phone</FormLabel>
          <TextField
            type='text'
            size='small'
            name='phone'
            value={admins.phone}
            onChange={handleChange}
          />
        </Stack>
        <FormHelperText id="outlined-weight-helper-text" error>{helperText.phone || " "}</FormHelperText>
        <Stack>
          <FormLabel sx={{ marginBottom: '0.5ch' }}>Role Type</FormLabel>
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={admins.role_id}
              size='small'
              name="role_id"
              onChange={handleChange}
            >
              <MenuItem value="1">Platform Admin</MenuItem>
              <MenuItem value="2">Support Admin</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <FormHelperText id="outlined-weight-helper-text" error>{helperText.role_id || " "}</FormHelperText>
        <Stack direction='row' justifyContent='center' alignItems='center' spacing={3}>
          <Button
            style={{ maxWidth: '20%' }}
            variant='contained'
            onClick={handleNavigate}
          >Back
          </Button>
          {
            location.state.action === `${RoutesUrl.VIEW}` ? null :
              <Button variant='contained' color='success' type='submit' disabled={activeCreate} >
                {location.state.action === `${RoutesUrl.CREATE}` ? "create" : "update"}
              </Button>
          }
        </Stack>
      </form>
      <ToastContainer autoClose={1000} />
      <ErrorModal open={open} setOpen={setOpen} handleClose={handleClose} />
    </Box>

  )
}

interface State {
  name: string;
  email: string;
  phone: string;
  role_id: string;
}

export default AdminsForm