import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/globals.scss';
import App from './App';
import { AppThemeComponent } from './components/theme/app-theme-provider.component';
import { StoreProviderComponent } from './store/store-provider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <React.StrictMode>
      <AppThemeComponent>
        <StoreProviderComponent>
          <App />
        </StoreProviderComponent>
      </AppThemeComponent>
  </React.StrictMode>
);

