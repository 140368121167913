import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { Button, FormLabel, Stack, TextField, MenuItem, FormHelperText, Typography } from '@mui/material';
import { NavigateFunction, useLocation, useNavigate, useParams } from 'react-router-dom';
import { RoutesUrl } from '../../enum/routes-url';
import { getAllEntityApi, getCategoryApi, getRolesApi, postCategoryApi, postRolesApi, putCategoryApi, putRolesApi } from '../../config/api';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import ErrorModal from '../others/modals/error-modal';
import Loading from '../others/loading.component';
import CardComponent from '../others/modals/card-component';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { getAllMenuProfileApi, getMenuProfileApi, postMenuProfileApi, putMenuProfileApi } from '../../config/api/api-configs/menu-profile-config';
import Axios from '../../config/axios-config';

const CategoryForm: React.FC = () => {

    const [state, setState] = React.useState<State>({
        name: "",
        sort_id: "",
    });
    const [check, setCheck] = React.useState(false);
    const [locationState, setLoactionState] = React.useState<"create" | "view" | "update">();
    const [disabled, setDisabled] = React.useState<boolean>(false);
    const [updatedisabled, setUpdateDisabled] = React.useState<boolean>(false);
    const [helperText, setHelperText] = React.useState<any>({});
    const [getEntityId, setGetEntityId] = React.useState([]);
    const [menuProfile, setMenuProfile] = React.useState([]);
    const [open, setOpen] = React.useState(false);

    const handleClose = () => setOpen(false);

    const navigate: NavigateFunction = useNavigate();
    const location = useLocation();
    const { orgId, id, categoryId } = useParams();
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state: any) => state.tasks);
    const Icon = ["AcUnit", "Abc", "AddCard", "AddLocation", "AddLink"];

    useEffect(() => {
        setLoactionState(location.state.action);
        if (location.state.action === `${RoutesUrl.VIEW}`) {
            (async () => {
                const data = await getCategoryApi(orgId, id, categoryId, dispatch);
                console.log(data);

                if (data) setState(data);
            })();
        }

        if (location.state.action === `${RoutesUrl.UPDATE}`) {
            setDisabled(true);
            (async () => {
                const data = await getCategoryApi(orgId, id, categoryId, dispatch);
                console.log(data);

                if (data) setState(data);
            })();
        }
    }, []);

    // useEffect(() => {
    //     (async () => {
    //         const data = await getAllEntityApi(orgId, id, dispatch);
    //         if (data) setGetEntityId(data);

    //         const response = await getAllMenuProfileApi(orgId, id, dispatch);
    //         if (response) {
    //             const resFilter = response?.filter((e: any) => e.parent_id === null);
    //             setMenuProfile(resFilter);
    //         }
    //     })();
    // }, []);

    const handleNavigate = () => {
        navigate(-1);
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (locationState === `${RoutesUrl.CREATE}`) {
            handleValidation();
        }
        else if (locationState === `${RoutesUrl.UPDATE}`) {
            handleValidation();
        }
    }

    const handleValidation = async () => {
        const error = {} as any
        if (!state.name) {
            error.name = "Name field is required"
        } else if (!/^[a-zA-Z0-9_]*$/.test(state.name)) {
            error.name = "Name should not contain special character except _";
        } else {
            error.name = ""
        }
        if (!state.sort_id) {
            error.sort_id = "Sort ID is required"
        } else {
            error.sort_id = ""
        }

        if (!error.name && !error.sort_id) {
            const obj = Object.assign({}, state) as any;
            if(obj.sort_id){
                obj.sort_id = +(obj.sort_id);
            }
            if (locationState === `${RoutesUrl.CREATE}`) {
                const postRoles = await postCategoryApi(orgId, id, obj, dispatch);
                if (postRoles) {
                    setState({
                        name: "",
                        sort_id: "",
                    });
                    toast.success('Created successfully', {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                } else {
                    setOpen(true);
                }
            }

            if (locationState === `${RoutesUrl.UPDATE}`) {
                const obj = Object.assign({}, state) as any;
                if(obj.id){
                    delete obj.id;
                }
                if(obj.active){
                    delete obj.active;
                }
                const updateRoles = await putCategoryApi(orgId, id, categoryId, obj, dispatch);
                if (updateRoles) {
                    toast.success('Orgsform Successfully Updated', {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                } else {
                    setOpen(true);
                }
            }
        }
        setHelperText({ ...helperText, ...error });
    }
    // e.target.value === 'select any option' ? e.target.value = "" :
    const handleChange = (e: React.ChangeEvent<HTMLInputElement> | any) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        });
        setUpdateDisabled(true);
    }


    if (isLoading) {
        return <Loading />
    }

    if (location.state.action === 'view') {

        return (
            <Stack direction='row' flexDirection='column' alignItems='center'>
                <Typography variant='h2'>{location.state.setFormName}</Typography>
                <CardComponent state={state} />
            </Stack>
        )
    }

    return (
        <Box sx={{ width: '100ch', margin: '2ch auto' }}>
            <Typography variant='h2' textAlign='center'>{location.state.setFormName}</Typography>
            <form onSubmit={(e) => { handleSubmit(e) }}>
                <Stack>
                    <FormLabel sx={{ marginBottom: '0.5ch' }}>Name</FormLabel>
                    <TextField
                        type='text'
                        size='small'
                        name='name'
                        value={state.name}
                        onChange={handleChange}
                    />
                    <FormHelperText id="outlined-weight-helper-text" error>{helperText.name || " "}</FormHelperText>
                </Stack>
                <Stack>
                    <FormLabel sx={{ marginBottom: '0.5ch' }}>Sort ID</FormLabel>
                    <TextField
                        type='text'
                        size='small'
                        name='sort_id'
                        value={state.sort_id}
                        onChange={handleChange}
                    />
                    <FormHelperText id="outlined-weight-helper-text" error>{helperText.sort_id || " "}</FormHelperText>
                </Stack>
                <Stack direction='row' justifyContent='center' alignItems='center' spacing={3}>
                    <Button
                        style={{ maxWidth: '20%' }}
                        variant='contained'
                        onClick={handleNavigate}
                    >Back
                    </Button>
                    {
                        locationState === `${RoutesUrl.VIEW}` ? null :
                            <Button variant='contained' color='success' type='submit' disabled={!updatedisabled}>
                                {locationState === `${RoutesUrl.CREATE}` ? "create" : "update"}
                            </Button>}

                </Stack>
            </form>
            <ToastContainer autoClose={1000} />
            <ErrorModal open={open} setOpen={setOpen} handleClose={handleClose} />
        </Box>
    )
}
interface State {
    name: string;
    sort_id: string;
}

export default CategoryForm