import { setLoading, showErrorModal } from "../../../store/slices/taskslice";
import Axios, { API_DOMAIN, API_PATH } from "../../axios-config";

const RELATION_API = {
    post_relation_api : (orgId: number, appId: number)=>`${orgId}/${appId}/${API_PATH.relations}`,
    get_all_relation_api : (orgId: number, appId: number)=>`${orgId}/${appId}/${API_PATH.relations}`,
    get_relation_api : (orgId: number, appId: number,relationId: number)=>`${orgId}/${appId}/${API_PATH.relations}/${relationId}`,
    delete_relation_api : (orgId: number, appId: number,relationId: number)=>`${orgId}/${appId}/${API_PATH.relations}/${relationId}`,
}

export const getAllRelationApi = async (orgId: any,appId: any,dispatch: any)=>{
    dispatch(setLoading(true));
    try {
        const response = await Axios.get(`${API_DOMAIN}${RELATION_API.get_all_relation_api(orgId,appId)}`);
        dispatch(setLoading(false));
        return response?.data
    }
    catch (error: any){
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const getRelationApi = async (orgId: any,appId: any,relationId: any,dispatch: any)=>{
    dispatch(setLoading(true));
    try {
        const response = await Axios.get(`${API_DOMAIN}${RELATION_API.get_relation_api(orgId,appId,relationId)}`)
        dispatch(setLoading(false));
        return response?.data
    }
    catch (error: any){
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const postRelationApi = async (orgId: any,appId: any,payload: any,dispatch: any)=>{
    dispatch(setLoading(true));
    try {
        const response = await Axios.post(`${API_DOMAIN}${RELATION_API.post_relation_api(orgId,appId)}`,payload)
        dispatch(setLoading(false));
        return response;
    }
    catch (error: any){
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const deleteRelationApi = async (orgId: any,appId: any,relationId: any,setRelation: any,dispatch: any,relations: any,rowsPerPage: number,setPage: any)=>{
    dispatch(setLoading(true));
    try {
        const response = await Axios.delete(`${API_DOMAIN}${RELATION_API.delete_relation_api(orgId,appId,relationId)}`)
        const data = await getAllRelationApi(orgId, appId, dispatch);
        if(data) {
            if(relations?.length > rowsPerPage){
                ((relations?.length - 1)) % rowsPerPage === 0 ? setPage((prev: number) => prev - 1) : setPage((prev: number) => prev);
             }
            setRelation(data);
        }
        dispatch(setLoading(false));
        return response;
    }
    catch (error: any){
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}