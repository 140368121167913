import React from 'react';
import {  Container } from '@mui/material';
import Box from '@mui/material/Box';

const PagesTemplate:React.FC<Props> = (props: Props) => {
  return (
    <Box>
      <Container maxWidth='xl'>
        {props.children}
      </Container>
    </Box>
  )
}

interface Props {
  children: React.ReactNode;
}

export default PagesTemplate