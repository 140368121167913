import { setLoading, showErrorModal } from "../../../store/slices/taskslice";
import Axios, { API_DOMAIN, API_PATH } from "../../axios-config";

const CATEGORY_API = {
    post_category_api: (orgId: number, appId: number) => `${orgId}/${appId}/${API_PATH.category}`,
    get_all_category_api: (orgId: number, appId: number) => `${orgId}/${appId}/${API_PATH.category}`,
    get_category_api: (orgId: number, appId: number, menuProfileId: number) => `${orgId}/${appId}/${API_PATH.category}/${menuProfileId}`,
    put_category_api: (orgId: number, appId: number, menuProfileId: number) => `${orgId}/${appId}/${API_PATH.category}/${menuProfileId}`,
    delete_category_api: (orgId: number, appId: number, menuProfileId: number) => `${orgId}/${appId}/${API_PATH.category}/${menuProfileId}`
}

export const getAllCategoryApi = async (orgId: any, appId: any, dispatch: any) => {
    dispatch(setLoading(true));
    try {
        const response = await Axios.get(`${API_DOMAIN}${CATEGORY_API.get_all_category_api(orgId, appId)}`)
        dispatch(setLoading(false));
        return response?.data;
    }
    catch (error: any) {
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const getCategoryApi = async (orgId: any, appId: any, menuProfileId: any, dispatch: any) => {
    dispatch(setLoading(true));
    try {
        const response = await Axios.get(`${API_DOMAIN}${CATEGORY_API.get_category_api(orgId, appId, menuProfileId)}`)
        dispatch(setLoading(false));
        return response?.data;
    }
    catch (error: any) {
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const putCategoryApi = async (orgId: any, appId: any, menuProfileId: any, payload: any, dispatch: any) => {
    dispatch(setLoading(true));
    try {
        const response = await Axios.put(`${API_DOMAIN}${CATEGORY_API.put_category_api(orgId, appId, menuProfileId)}`, payload)
        dispatch(setLoading(false));
        return response;
    }
    catch (error: any) {
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const postCategoryApi = async (orgId: any, appId: any, payload: any, dispatch: any) => {
    dispatch(setLoading(true));
    try {
        const response = await Axios.post(`${API_DOMAIN}${CATEGORY_API.post_category_api(orgId, appId)}`, payload)
        dispatch(setLoading(false));
        return response;
    }
    catch (error: any) {
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const deleteCategoryApi = async (orgId: any, appId: any, menuProfileId: any, dispatch: any, setUsers: any, menuProfile: any, rowsPerPage: number, setPage: any) => {
    dispatch(setLoading(true));
    try {
        const response = await Axios.delete(`${API_DOMAIN}${CATEGORY_API.delete_category_api(orgId, appId, menuProfileId,)}`)
        const data = await getAllCategoryApi(orgId, appId, dispatch);
        console.log(data);
        if (data) {
            if(menuProfile?.length > rowsPerPage){
               ((menuProfile?.length - 1)) % rowsPerPage === 0 ? setPage((prev: number) => prev - 1) : setPage((prev: number) => prev);
            }
            setUsers(data);
        } 
        dispatch(setLoading(false));
        return response;
    }
    catch (error: any) {
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}