import PagesTemplate from '../components/others/pages-template';
import FieldsTable from '../components/page-fields/fields-table';
import RelationTable from '../components/page-relation/relation-table';

const RelationPage: React.FC = () => {
  return (
        <PagesTemplate>
          <RelationTable />
        </PagesTemplate>
  )
}

export default RelationPage