import { createSlice } from "@reduxjs/toolkit";
import { getAppApi } from "../../config/api";
import { getMyAdminApi } from "../../config/api/api-configs/admin-api-config";

type RootState = {
    tasksList: {
        app_id: string;
        org_id: string;
        system_name: string;
    };
    users: {
        id: string;
        name: string;
        email: string;
        phone: string;
        active: string;
        role_id: {
            id: string;
            name: string;
            active: string;
            deleted: boolean;
        };
    };
    platformAdmin: boolean;
    isLoading: boolean;
    showError: [];
}

const initialState: RootState = {
    tasksList: {
        app_id: '',
        org_id: '',
        system_name: ''
    },
    users: {
        id: "",
        name: "",
        email: "",
        phone: "",
        active: "",
        role_id: {
            id: "",
            name: "",
            active: "",
            deleted: false,
        },
    },
    platformAdmin: false,
    isLoading: false,
    showError: []
}

const taskSlice = createSlice({
    name: "taskSlice",
    initialState,
    reducers: {
        addAppIdOrgId: (state, action) => {
            state.tasksList = { ...state.tasksList, ...action.payload }
        },
        usersDetail: (state, action) => {
            state.users = { ...state.users, ...action.payload }
            if (state.users.role_id.name === "platform_admin") {
                state.platformAdmin = true;
            }
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        showErrorModal: (state, action) => {
            state.showError = action.payload
        },
        findAdmin: (state, action) => {
            state.platformAdmin = action.payload
        }
    }
})

export const { addAppIdOrgId, usersDetail, setLoading, showErrorModal, findAdmin } = taskSlice.actions;
export default taskSlice.reducer;


export const getMyAdmin = async ({ dispatch = (user: any) => { } },navigate: any) => {
    const res = await getMyAdminApi({ dispatch });
    if (res) {
        dispatch(usersDetail({
            id: res.id,
            name: res.name,
            phone: res.phone,
            email: res.email,
            active: res.email,
            roleId: {
                id: res.role_id.id,
                name: res.role_id.name,
                active: res.role_id.active,
                deleted: res.role_id.deleted
            }
        }));
        if (res.role_id.name === "platform_admin") {
            dispatch(findAdmin(true));
        }
    }
    return res;
}

export const getApp = async (org_id: number, app_id: number, dispatch: (addAppIdOrgId: any) => {}) => {
    const res = await getAppApi(org_id, app_id, dispatch);
    if (res) {
        dispatch(addAppIdOrgId({ "org_id": res.org_id, "app_id": res.id, "system_name": res.system_name }));
    }
}