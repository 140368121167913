import { setLoading, showErrorModal } from "../../../store/slices/taskslice";
import Axios, { API_DOMAIN, API_PATH } from "../../axios-config";

export const ADMINS_APP_MANAGER_API = {
    post_admins : `${API_DOMAIN}${API_PATH.admins}`,
    get_admins_admin_id : (admins_id: number)=>`${API_DOMAIN}${API_PATH.admins}/${admins_id}`,
    get_admins_all : `${API_DOMAIN}${API_PATH.admins}?expandAll=true`,
    put_admins: (admins_id: number)=>`${API_DOMAIN}${API_PATH.admins}/${admins_id}`,
    delete_admins: (admins_id: number)=>`${API_DOMAIN}${API_PATH.admins}/${admins_id}`
}

export const postAdmins_AppManagerApi = async (payload: any,dispatch: any) => {
    dispatch(setLoading(true));
    try {
        const response = await Axios.post(`${ADMINS_APP_MANAGER_API.post_admins}`,payload);
        dispatch(setLoading(false));
        return response;
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const getAdmins_AppManagerApi = async (adminsId: any,dispatch: any) => {
    dispatch(setLoading(true));
    try {
        const response = await Axios.get(`${ADMINS_APP_MANAGER_API.get_admins_admin_id(adminsId)}`);
        dispatch(setLoading(false));
        return response?.data;
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const getAllAdmins_AppManagerApi = async (dispatch: any) => {
    dispatch(setLoading(true));
    try {
        const response = await Axios.get(`${ADMINS_APP_MANAGER_API.get_admins_all}`);
        dispatch(setLoading(false));
        return response?.data;
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const getAdminByEmail_AppManagerApi = async (email: string, dispatch: any) => {
    dispatch(setLoading(true));
    try {
        const response = await Axios.get(`${ADMINS_APP_MANAGER_API.get_admins_all}&email=${email}`);
        dispatch(setLoading(false));
        return response?.data;
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const putAdmins_AppManagerApi = async (adminsId: any,payload: any, dispatch: any) => {
    dispatch(setLoading(true));
    try {
        const response = await Axios.put(`${ADMINS_APP_MANAGER_API.put_admins(adminsId)}`,payload);
        dispatch(setLoading(false));
        return response;
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const deleteAdmins_AppManagerApi = async (deleteId: any,setAdmins: any, dispatch: any,admins: any,rowsPerPage: number,setPage: any) => {
    dispatch(setLoading(true));
    try {
        const response = await Axios.delete(`${ADMINS_APP_MANAGER_API.delete_admins(deleteId)}`);
        const data = await getAllAdmins_AppManagerApi(dispatch);
        if(data){
            if(admins?.length > rowsPerPage){
                ((admins?.length - 1)) % rowsPerPage === 0 ? setPage((prev: number) => prev - 1) : setPage((prev: number) => prev);
             }
            setAdmins(data);
        }
        dispatch(setLoading(false));
        return response;
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}