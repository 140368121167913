import PagesTemplate from '../components/others/pages-template';
import { Outlet, useLocation } from 'react-router-dom';
import { RoutesUrl } from '../enum/routes-url';
import AdminRolesTable from '../components/page-admins-roles/admins_roles_table';



const AdminsRolesPage: React.FC = () => {

  const location = useLocation();  

  return (
      <PagesTemplate>
        {
          (location.pathname === `/${RoutesUrl.ADMINS_ROLES}`) ? <AdminRolesTable /> : <Outlet />
        }
      </PagesTemplate>
  )
}


export default AdminsRolesPage