import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { Button, FormLabel, Stack, TextField, Typography, FormHelperText, FormControlLabel, Checkbox, TextareaAutosize } from '@mui/material';
import { NavigateFunction, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import { RoutesUrl } from '../../enum/routes-url';
import { getOrgsApi, postOrgsApi, putOrgsApi } from '../../config/api';
import ErrorModal from '../others/modals/error-modal';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../others/loading.component';
import OrgCard from './org-details-card';

const OrgsForm: React.FC = () => {

    const [state, setState] = React.useState<State>({
        name: "",
        legal_name: "",
        system_name: "",
        address: "",
        gst_number: "",
        pan_number: "",
        logo: "",
        active: true,
    });
    const [locationState, setLoactionState] = React.useState<"create" | "view" | "update">();
    const [disabled, setDisabled] = React.useState<boolean>(false);
    const [updatedisabled, setUpdateDisabled] = React.useState<boolean>(false);
    const [helperText, setHelperText] = React.useState<any>({
        name: "",
        legal_name: "",
        system_name: "",
        address: "",
        gst_number: "",
        pan_number: "",
        logo: ""
    });
    const [open, setOpen] = React.useState(false);

    const handleClose = () => setOpen(false);
    const navigate: NavigateFunction = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state: any) => state.tasks);
    const { id } = useParams();

    useEffect(() => {
        setLoactionState(location.state.action);
        if (location.state.action === `${RoutesUrl.VIEW}`) {
            setDisabled(true);
            (async () => {
                const data = await getOrgsApi(id, dispatch);
                if (data) setState(data);
            })();
        }

        if (location.state.action === `${RoutesUrl.UPDATE}`) {
            (async () => {
                const data = await getOrgsApi(id, dispatch);
                if (data) setState(data);
            })();
        }
    }, []);

    const handleNavigate = () => {
        navigate(`/${RoutesUrl.ORGS}`, { state: { showMenu: false } });
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (locationState === `${RoutesUrl.CREATE}`) {
            handleValidate();
        }
        else if (locationState === `${RoutesUrl.UPDATE}`) {
            handleValidate();
        }
    }

    const handleValidate = async () => {
        const error = {} as any;
        const urlPattern =
            /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;
        if (!state.name) {
            error.name = "Name field must be required";
        } else if (!(state.name.trim().length > 0)) {
            error.name = "Name should not contain empty space";
        }
        else {
            error.name = "";
        }
        if (!state.system_name) {
            error.system_name = "System Name field must be required";
        } else if (!/^[a-zA-Z0-9_]*$/.test(state.system_name)) {
            error.system_name = "System Name should not contain special character";
        } else {
            error.system_name = "";
        }
        if (!state.legal_name) {
            error.legal_name = "Legal name field must be required";
        } else if (!(state.legal_name.trim().length > 0)) {
            error.legal_name = "Legal name should not contain empty space";
        } else {
            error.legal_name = "";
        }
        if (state.logo) {
            if (!urlPattern.test(state.logo)) {
                error.logo = "Enter correct URL";
            } else {
                error.logo = "";
            }
        } else {
            delete state.logo;
        }
        if (!state.address) {
            error.address = "Address must be required";
        }else {
            error.address = "";
        }
        if (!state.gst_number) {
            error.gst_number = "GST number must be required";
        }else {
            error.gst_number = "";
        }
        if (!state.pan_number) {
            error.pan_number = "PAN number must be required";
        }else {
            error.pan_number = "";
        }

        if (!error.name && !error.legal_name && !error.system_name && !error.logo) {
            if (locationState === `${RoutesUrl.CREATE}`) {
                const postOrgs = await postOrgsApi(state, dispatch);
                if (postOrgs) {
                    setState({
                        name: "",
                        legal_name: "",
                        system_name: "",
                        address: "",
                        pan_number: "",
                        gst_number: "",
                        logo: "",
                        active: true,
                    })
                    toast.success('Created successfully', {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                } else {
                    setOpen(true);
                }
            }
            if (locationState === `${RoutesUrl.UPDATE}`) {
                let obj = Object.assign({}, state) as any;
                if (obj.id) {
                    delete obj.id
                }
                if (obj.system_name) {
                    delete obj.system_name
                }
                const updateOrgs = await putOrgsApi(id, obj, dispatch);
                if (updateOrgs) {
                    toast.success('Orgsform Successfully Updated', {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                } else {
                    setOpen(true);
                }
            }
        }
        setHelperText({ ...helperText, ...error });
    }

    const handleChange = (e: any) => {
        let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
        setState({
            ...state,
            [e.target.name]: value
        });
        setUpdateDisabled(true);
    }

    if (isLoading) {
        return (
            <Loading />
        )
    }

    if (location.state.action === "view") {
        return <OrgCard state={state} />
    }

    return (

        <Box sx={{ width: '100ch', margin: '2ch auto' }}>
            <Typography variant='h1'>{location.state.formName}</Typography>
            {
                location.state?.action === "view" ? <OrgCard Name={state.name} Legal_Name={state.legal_name} System_Name={state.system_name} Logo={state.logo} /> :
                    <form onSubmit={(e) => { handleSubmit(e) }}>
                        <Stack>
                            <FormLabel sx={{ marginBottom: '0.5ch' }}>Name</FormLabel>
                            <TextField
                                type='text'
                                size='small'
                                name='name'
                                placeholder='Enter organization name'
                                value={state.name}
                                onChange={handleChange}
                            />
                        </Stack>
                        <FormHelperText id="outlined-weight-helper-text" error>{helperText.name || " "}</FormHelperText>
                        <Stack>
                            <FormLabel sx={{ marginBottom: '0.5ch' }}>Legal Name</FormLabel>
                            <TextField
                                type='text'
                                size='small'
                                name='legal_name'
                                placeholder='Enter Legal Name'
                                value={state.legal_name}
                                onChange={handleChange}
                            />
                        </Stack>
                        <FormHelperText id="outlined-weight-helper-text" error>{helperText.legal_name || " "}</FormHelperText>
                        <Stack>
                            <FormLabel sx={{ marginBottom: '0.5ch' }}>System Name</FormLabel>
                            <TextField
                                type='text'
                                size='small'
                                name='system_name'
                                placeholder='Enter System Name'
                                disabled={location.state.action === `${RoutesUrl.UPDATE}` ? true : false}
                                value={state.system_name}
                                onChange={handleChange}
                            />
                        </Stack>
                        <FormHelperText id="outlined-weight-helper-text" error>{helperText.system_name || " "}</FormHelperText>
                        <Stack>
                            <FormLabel sx={{ marginBottom: '0.5ch' }}>GST Number</FormLabel>
                            <TextField
                                type='text'
                                size='small'
                                name='gst_number'
                                placeholder='Enter your GST number'
                                disabled={location.state.action === `${RoutesUrl.UPDATE}` ? true : false}
                                value={state.gst_number}
                                onChange={handleChange}
                            />
                        </Stack>
                        <FormHelperText id="outlined-weight-helper-text" error>{helperText.gst_number || " "}</FormHelperText>
                        <Stack>
                            <FormLabel sx={{ marginBottom: '0.5ch' }}>PAN Number</FormLabel>
                            <TextField
                                type='text'
                                size='small'
                                name='pan_number'
                                placeholder='Enter your PAN number'
                                disabled={location.state.action === `${RoutesUrl.UPDATE}` ? true : false}
                                value={state.pan_number}
                                onChange={handleChange}
                            />
                        </Stack>
                        <FormHelperText id="outlined-weight-helper-text" error>{helperText.pan_number || " "}</FormHelperText>
                        <Stack>
                            <FormLabel sx={{ marginBottom: '0.5ch' }}>Address</FormLabel>
                            <TextField
                                multiline
                                rows={4}
                                placeholder="Enter your address"
                                variant="outlined"
                                name="address"
                                fullWidth
                                sx={{ overflowWrap: "break-word" }}
                                value={state.address}
                                onChange={handleChange}
                            />
                        </Stack>
                        <FormHelperText id="outlined-weight-helper-text" error>{helperText.address || " "}</FormHelperText>
                        <Stack>
                            <FormLabel sx={{ marginBottom: '0.5ch' }}>Logo</FormLabel>
                            <TextField
                                type='text'
                                size='small'
                                name='logo'
                                placeholder='Enter your logo'
                                value={state.logo}
                                onChange={handleChange}
                            />
                        </Stack>
                        <FormHelperText id="outlined-weight-helper-text" error>{helperText.logo || " "}</FormHelperText>
                        {
                            location.state.action === `${RoutesUrl.CREATE}` ? null : <Stack>
                                <FormControlLabel
                                    name='active'
                                    value={state.active}
                                    control={<Checkbox checked={state.active} onChange={handleChange} />}
                                    label="Active"
                                />
                            </Stack>
                        }
                        <Stack direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Button
                                style={{ maxWidth: '20%' }}
                                variant='contained'
                                onClick={handleNavigate}
                            >Back
                            </Button>
                            {
                                locationState === `${RoutesUrl.VIEW}` ? null :
                                    <Button variant='contained' color='success' type='submit' disabled={!updatedisabled}>
                                        {locationState === `${RoutesUrl.CREATE}` ? "create" : "update"}
                                    </Button>}

                        </Stack>
                    </form>
            }
            <ToastContainer autoClose={1000} />
            <ErrorModal open={open} setOpen={setOpen} handleClose={handleClose} />
        </Box>
    )
}
interface State {
    name: string;
    legal_name: string;
    system_name: string;
    address: string;
    gst_number: string;
    pan_number: string;
    logo?: string;
    active: any;
}

export default OrgsForm