import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';

 const theme = createTheme({
  palette: {
    primary: {
      main: '#3498db',
    },
    secondary:{
        main: '#fff',
    },
    background: {
      default: "#3498db",
    },
    mode: 'light'
  },
  typography:{
    h1:{
      fontSize: '2rem',
      fontWeight: 700,
      textAlign: 'center',
      marginTop: '1rem',
      marginBottom: '1rem'
    },
    h2:{
      fontSize: '1.8rem',
      fontWeight: 600,
      textAlign: 'right',
      marginTop: '0.2rem',
      marginBottom: '0.7rem',
    },
    h3:{
      fontSize: '1.8rem',
      fontWeight: 500,
      textAlign: 'left',
      marginTop: '0.2rem',
      marginBottom: '0.7rem',
    },
    caption:{
      marginBottom: '0.5rem',
      fontSize: '1rem',
      userSelect: 'none'
    },
  },
  components:{
    MuiInputBase: {
      styleOverrides: {
          input: {
              // marginBottom: '0',
              // marginTop: '0'
          }
      }
  }
  }
});

export const AppThemeComponent =(props: {children: React.ReactNode})=>{
    return(
        <ThemeProvider theme={theme}>
            {props.children}
        </ThemeProvider>
    )
}