import React, { Suspense } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ActionButton from '../others/actionbutton';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import DeleteModal from '../others/modals/delete-modal';
import { Box, Button, Pagination, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { RoutesUrl } from '../../enum/routes-url';
import { getApp, getMyAdmin, setLoading } from '../../store/slices/taskslice';
import { getAllAppApi, getAppSupportAdminApi } from '../../config/api';
import Loading from '../others/loading.component';
import { exportAppApi } from '../../config/api/api-configs/app-api-config';
import { ToastContainer } from 'react-toastify';
import ErrorModal from '../others/modals/error-modal';
import useSWR from 'swr';
import Axios from '../../config/axios-config';

const AppTable: React.FC = () => {

    const [app, setApp] = React.useState([]);
    const [open, setOpen] = React.useState<boolean>(false);
    const [deleteId, setDeleteId] = React.useState();
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const navigate: NavigateFunction | any = useNavigate();
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state: any) => state.tasks);
    const location = useLocation();

    // const fetcher = (url: string)=> Axios.get(url).then((res)=> res);

    // const { data,isLoading, error } = useSWR('apps', fetcher);
    // console.log(data);
    // console.log(error);
    // console.log(isLoading);


    React.useEffect(() => {
        dispatch(setLoading(true));


        (async () => {
            const res = await getMyAdmin({ dispatch }, navigate);
            if (res?.role_id.name === 'platform_admin') {
                let data = await getAllAppApi(dispatch, page);
                dispatch(setLoading(false));
                if (data) {
                    data = data?.map((item: any) => ({ permission: 'Create/Read/Update/Delete', ...item }));
                    let sortData = data?.sort((data1: any,data2: any)=>(data1.id > data2.id) ? 1 : (data1.id < data2.id) ? -1 : 0);
                    setApp(sortData);
                }
            }
            else {
                const data = await getAppSupportAdminApi(dispatch);
                if (data) {
                    let apps = data?.map((item: any) => {
                        const permissions = [];
                        if (item.create) permissions.push('Create');
                        if (item.read) permissions.push('Read');
                        if (item.update) permissions.push('Update');
                        if (item.remove) permissions.push('Delete');
                        return { permission: permissions.join('/'), ...item.app_id };
                    })
                    setApp(apps);
                }
            }
        })();
    }, []);

    const handleOpen = (id: any) => {
        setDeleteId(id);
        setOpen(true);
    };
    const handleClose = () => setOpen(false);

    const handleView = async (id: number, org_id: number) => {
        navigate(`/${org_id}/${RoutesUrl.APPS}/${id}`, { state: { action: "view", showMenu: true, formName: "Apps_", setName: "Apps_Details" } });
    };

    const handleEdit = (id: number, org_id: number) => {
        navigate(`/${org_id}/${RoutesUrl.APPS}/${id}`, { state: { action: "update", showMenu: true, formName: "Apps_", setName: "Apps_Update" } });
        getApp(org_id, id, dispatch);
    };

    const handleDelete = () => {
        // const deleteBtn = app?.filter((app: any) => app.id !== Id);
        // setApp(deleteBtn);
        // setOpen(false);
    };

    const handleExport = (id: number) => {
        const items: any = app?.find((e: any) => e.id === id);
        console.log(items);

        if (items) {
            exportAppApi(items.org_id, items.id, dispatch);
        } else {
            setOpen(true);
        }
    }

    const handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    if (isLoading) {
        return (
            <Loading />
        )
    }

    return (
        <Box>

            <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                my={2}
            >
                <Typography variant='h1' sx={{ cursor: 'default' }}>Apps</Typography>
            </Stack>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" sx={{ fontWeight: 700 }}>ID</TableCell>
                            <TableCell align="center" sx={{ fontWeight: 700 }}>NAME</TableCell>
                            <TableCell align="center" sx={{ fontWeight: 700 }}>SYSTEM NAME</TableCell>
                            <TableCell align="center" sx={{ fontWeight: 700 }}>PERMISSIONS</TableCell>
                            <TableCell align="center" sx={{ fontWeight: 700 }}>ACTIONS</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            app?.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((applist: any, index: number) => (
                                <TableRow key={index}>
                                    <TableCell align='center'>{applist.id}</TableCell>
                                    <TableCell align='center'>{applist.name}</TableCell>
                                    <TableCell align='center'>{applist.system_name}</TableCell>
                                    <TableCell align='center'>{applist.permission}</TableCell>
                                    <TableCell align='center'>
                                        <Stack direction='row' justifyContent='space-around' spacing={2}>
                                            <ActionButton
                                                handleView={() => handleView(applist.id, applist.org_id)}
                                                handleOpen={() => handleOpen(applist.id)}
                                                handleEdit={() => handleEdit(applist.id, applist.org_id)}
                                                showDelete={true}
                                            />
                                            <Button variant='contained' color='warning' onClick={() => handleExport(applist.id)}>Export</Button>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
                <DeleteModal handleDelete={handleDelete} open={open} setOpen={setOpen} deleteId={deleteId} />
            </TableContainer>
            <Stack marginY={2} alignItems='center' direction='row' flexDirection='column'>
                <Pagination count={Math.ceil(app?.length / rowsPerPage)} page={page} onChange={handlePagination} color='primary' />
            </Stack>
            <ToastContainer autoClose={1000} />
            <ErrorModal open={open} setOpen={setOpen} handleClose={handleClose} />
        </Box>
    )
}

export default AppTable