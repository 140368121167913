import React, { FormEvent, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Button, Checkbox, FormControlLabel, FormHelperText, FormLabel, Stack, TextField, Typography } from '@mui/material';
import { NavigateFunction, useLocation, useNavigate, useParams } from 'react-router-dom';
import { RoutesUrl } from '../../enum/routes-url';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import ErrorModal from '../others/modals/error-modal';
import Loading from '../others/loading.component';
import { getAllRolesApi, getOneUsersApi, postUsersApi, putUsersApi } from '../../config/api';
import CardComponent from '../others/modals/card-component';
import { INITIAL_LIMIT } from '../../constant/constant';

const UsersForm: React.FC = () => {

    const [state, setState] = React.useState<State>({
        name: "",
        email: "",
        phone: "",
        role_id: "",
        active: true,
    });
    const [rolesGetId, setRolesGetId] = React.useState([]);
    const [locationState, setLoactionState] = React.useState<"create" | "view" | "update">();
    const [disabled, setDisabled] = React.useState<boolean>(false);
    const [updatedisabled, setUpdateDisabled] = React.useState<boolean>(false);
    const [helperText, setHelperText] = React.useState<any>({
        name: "",
        email: "",
        phone: "",
        role_id: ""
    });
    const [open, setOpen] = React.useState(false);
    const navigate: NavigateFunction = useNavigate();
    const location = useLocation();
    const { orgId, id, usersId } = useParams();
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state: any) => state.tasks);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        setLoactionState(location.state.action);
        if (location.state.action === `${RoutesUrl.VIEW}`) {
            setDisabled(true);
            (async () => {
                const data = await getOneUsersApi(orgId, id, usersId, dispatch);
                if (data) setState(data);
            })();
        }

        if (location.state.action === `${RoutesUrl.UPDATE}`) {
            (async () => {
                let data = await getOneUsersApi(orgId, id, usersId, dispatch);
                if (data) setState(data);
            })();
        }
    }, []);

    useEffect(() => {
        (async () => {
            const data = await getAllRolesApi(orgId, id, dispatch);
            if (data) setRolesGetId(data);
        })();
    }, [])

    const handleNavigate = () => {
        navigate(-1);
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (locationState === `${RoutesUrl.CREATE}` || locationState === `${RoutesUrl.UPDATE}`) {
            handleValidate();
        }
    };

    const handleValidate = async () => {
        const error = {} as any
        if (!state.name) {
            error.name = "Name field is required"
        } else if (!(state.name.trim().length > 0)) {
            error.name = "Name should not contain empty space";
        } else {
            error.name = ""
        }
        if (!state.email) {
            error.email = "Email field is required"
        } else {
            error.email = ""
        }
        if (!state.phone) {
            error.phone = "Phone field is required"
        } else if (!/^\d{3}-?\d{3}-?\d{4}$/.test(state.phone)) {
            error.phone = "Phone should contain only number";
        } else {
            error.phone = ""
        }
        if (!state.role_id) {
            error.role_id = "Role ID is required"
        }

        if (!error.name && !error.email && !error.role && !error.roleId && !error.phone) {
            console.log(state);
            if (locationState === `${RoutesUrl.CREATE}`) {
                const postRoles = await postUsersApi(orgId, id, state, dispatch);
                if (postRoles?.status === 201) {
                    setState({
                        name: "",
                        email: "",
                        phone: "",
                        role_id: "",
                        active: true,
                    });
                    toast.success('Created successfully', {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                } else {
                    setOpen(true);
                }
            }

            if (locationState === `${RoutesUrl.UPDATE}`) {
                const obj = Object.assign({}, state) as any
                console.log(obj);
                if (obj.id) {
                    delete obj.id
                }
                const updateUsers = await putUsersApi(orgId, id, usersId, obj, dispatch);
                if (updateUsers?.status === 200) {
                    toast.success('Orgsform Successfully Updated', {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                } else {
                    setOpen(true);
                }
            }
        }
        setHelperText({ ...helperText, ...error });
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement> | any) => {
        let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value

        if (state.active === "true") {
            state.active = true;
        }
        else if (state.active === "false") {
            state.active = false;
        }
        setState({
            ...state,
            [e.target.name]: value
        });
        setUpdateDisabled(true);
    };

    if (isLoading) {
        return <Loading />
    }

    if (location.state.action === `${RoutesUrl.VIEW}`) {
        return (
            <Stack direction='row' flexDirection='column' alignItems='center'>
                <Typography variant='h2'>{location.state.setFormName}</Typography>
                <CardComponent state={state} />
            </Stack>
        )
    }

    return (
        <Box sx={{ width: '100ch', margin: '2ch auto' }}>
            <Typography variant='h2' textAlign='center'>{location.state.setFormName}</Typography>
            <form onSubmit={(e) => { handleSubmit(e) }}>
                <Stack>
                    <FormLabel sx={{ marginBottom: '0.5ch' }}>Name</FormLabel>
                    <TextField
                        type='text'
                        size='small'
                        name='name'
                        disabled={disabled}
                        value={state.name}
                        onChange={handleChange}
                    />
                </Stack>
                <FormHelperText id="outlined-weight-helper-text" error>{helperText.name || " "}</FormHelperText>
                <Stack>
                    <FormLabel sx={{ marginBottom: '0.5ch' }}>Email</FormLabel>
                    <TextField
                        type='email'
                        size='small'
                        name='email'
                        disabled={disabled}
                        value={state.email}
                        onChange={handleChange}
                    />
                </Stack>
                <FormHelperText id="outlined-weight-helper-text" error>{helperText.email || " "}</FormHelperText>
                <Stack>
                    <FormLabel sx={{ marginBottom: '0.5ch' }}>Phone</FormLabel>
                    <TextField
                        type='text'
                        size='small'
                        name='phone'
                        disabled={disabled}
                        value={state.phone}
                        onChange={handleChange}
                    />
                </Stack>
                <FormHelperText id="outlined-weight-helper-text" error>{helperText.phone || " "}</FormHelperText>
                <Stack>
                    <FormLabel sx={{ marginBottom: '0.5ch' }}>Role ID</FormLabel>
                    <FormControl>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={state.role_id}
                            size='small'
                            name="role_id"
                            disabled={disabled}
                            onChange={handleChange}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: 150
                                    }
                                }
                            }}
                        >
                            {
                                rolesGetId.map((roles: any, index: any) => (
                                    <MenuItem key={index} value={roles.id}>{roles.name}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Stack>
                <FormHelperText id="outlined-weight-helper-text" error>{helperText.role_id || " "}</FormHelperText>
                {
                    location.state.action === `${RoutesUrl.CREATE}` ? null : <Stack>
                        <FormControlLabel
                            name='active'
                            value={state.active}
                            control={<Checkbox checked={state.active} onChange={handleChange} />}
                            label="Active"
                        />
                        <FormHelperText id="outlined-weight-helper-text" error>{helperText.active || " "}</FormHelperText>
                    </Stack>
                }

                <Stack direction='row' justifyContent='center' alignItems='center' spacing={3}>
                    <Button
                        style={{ maxWidth: '20%' }}
                        variant='contained'
                        onClick={handleNavigate}
                    >Back
                    </Button>
                    {
                        locationState === `${RoutesUrl.VIEW}` ? null :
                            <Button variant='contained' color='success' type='submit' disabled={!updatedisabled}>
                                {locationState === `${RoutesUrl.CREATE}` ? "create" : "update"}
                            </Button>
                    }

                </Stack>
            </form>
            <ToastContainer autoClose={1000} />
            <ErrorModal open={open} setOpen={setOpen} handleClose={handleClose} />
        </Box>
    )
}
interface State {
    name: string;
    email: string;
    phone: string;
    role_id: number | string;
    active: any;
}

export default UsersForm