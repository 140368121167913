import React from 'react'
import {  Navigate, Outlet } from "react-router-dom";
import { ACCESS_TOKEN } from "../constant/constant";
import { getStorage } from "./function"


const isAuth = ()=>{
  const token = getStorage(ACCESS_TOKEN);
  if(token){
    return true
  }else{
    return false
  }
}


export const ProtectionRoute: React.FC = (props: any)=>{
  const auth = isAuth();
  return auth ? <Outlet /> : <Navigate to='/login' />
}

export const PublicRoute: React.FC = (props: any)=>{
  const auth = isAuth();
  return auth ? <Navigate to='/apps' /> : <Outlet />
}
