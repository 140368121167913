import React, { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ActionButton from '../others/actionbutton';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import DeleteModal from '../others/modals/delete-modal';
import { RoutesUrl } from '../../enum/routes-url';
import { Box, Button, Stack, Typography, Pagination } from '@mui/material';
import { getAllOrgsApi, getAppSupportAdminApi } from '../../config/api';
import Loading from '../others/loading.component';
import { useDispatch, useSelector } from 'react-redux';
import { getMyAdmin, setLoading } from '../../store/slices/taskslice';
import { INITIAL_LIMIT } from '../../constant/constant';

const OrgTable: React.FC = () => {

    const [orgs, setOrgs] = React.useState([]);
    const [open, setOpen] = React.useState<boolean>(false);
    const [deleteId, setDeleteId] = React.useState<number>();
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const navigate: NavigateFunction = useNavigate();
    const { isLoading } = useSelector((state: any) => state.tasks);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setLoading(true));
        (async () => {

            // if (res?.role_id.name === 'platform_admin') {
                const data = await getAllOrgsApi(dispatch);
                if (data) setOrgs(data);
            // }
            // const res = await getMyAdmin({ dispatch }, navigate);
            // if (res?.role_id.name === 'platform_admin') {
            //     const data = await getAllOrgsApi(dispatch, INITIAL_LIMIT);
            //     if (data) setOrgs(data);
            // }
            // else {
            //     const data = await getAppSupportAdminApi(dispatch);
            //     if (data) {
            //         console.log(data);
                    
            //         // setOrgs(data);
            //     }
            // }
        })();
    }, []);

    const handleOpen = (id: any) => {
        setDeleteId(id);
        setOpen(true);
    };

    const handleView = async (id: number) => {
        navigate(`/${RoutesUrl.ORGS}/${id}`, { state: { action: "view", showMenu: false, formName: "" } });
    };

    const handleEdit = (id: number) => {
        navigate(`/${RoutesUrl.ORGS}/${id}`, { state: { action: "update", showMenu: false, formName: "Update Org" } });
    };

    const handleDelete = () => {
        // const deleteBtn = orgs.filter((org: any) => org.id !== Id)
        // setOrgs(deleteBtn);
        // setOpen(false);
    };

    const handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const handleCreate = () => {
        navigate(`/${RoutesUrl.ORGS}/${RoutesUrl.CREATE}`, { state: { action: "create", showMenu: false, formName: "Create Org" } });
    }

    if (isLoading) {
        return (
            <Loading />
        )
    }

    return (
        <Box>
            <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                my={2}
            >
                <Typography variant='h4' sx={{ cursor: 'default' }}>Organization</Typography>
                <Button variant='contained' color='success' onClick={handleCreate}>Add Orgs</Button>
            </Stack>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" sx={{ fontWeight: 700 }}>ID</TableCell>
                            <TableCell align="center" sx={{ fontWeight: 700 }}>NAME</TableCell>
                            <TableCell align="center" sx={{ fontWeight: 700 }}>LEGAL NAME</TableCell>
                            <TableCell align="center" sx={{ fontWeight: 700 }}>SYSTEM NAME</TableCell>
                            <TableCell align="center" sx={{ fontWeight: 700 }}>ACTIONS</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            orgs?.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((orglist: any, index: number) => (
                                <TableRow key={index}>
                                    <TableCell align='center'>{orglist.id}</TableCell>
                                    <TableCell align='center'>{orglist.name}</TableCell>
                                    <TableCell align='center'>{orglist.legal_name}</TableCell>
                                    <TableCell align='center'>{orglist.system_name}</TableCell>
                                    <TableCell align='center'>
                                        <ActionButton
                                            handleView={() => handleView(orglist.id)}
                                            handleOpen={() => handleOpen(orglist.id)}
                                            handleEdit={() => handleEdit(orglist.id)}
                                            showDelete={true}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
                <DeleteModal handleDelete={handleDelete} open={open} setOpen={setOpen} deleteId={deleteId} />
            </TableContainer>
            <Stack marginY={2} alignItems='center' direction='row' flexDirection='column'>
                <Pagination count={Math.ceil(orgs.length / rowsPerPage)} page={page} onChange={handlePagination} color='primary' />
            </Stack>
        </Box>
    )
}

export default OrgTable