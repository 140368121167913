import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { deletePermissionApi, getAllPermissionApi } from '../../config/api/api-configs/permissions-app-manager-api-config';
import { Box, Button, Pagination, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Loading from '../others/loading.component';
import { NavigateFunction, useLocation, useNavigate, useParams } from 'react-router-dom';
import { RoutesUrl } from '../../enum/routes-url';
import DeleteModal from '../others/modals/delete-modal';
import ActionButton from '../others/actionbutton';

const PermissionsTable: React.FC = () => {

  const [permissions, setPermissions] = React.useState([]);
  const [deleteId, setDeleteId] = React.useState();
  const [open, setOpen] = React.useState<boolean>(false);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const dispatch = useDispatch();
  const navigate: NavigateFunction = useNavigate();
  const location = useLocation();

  const { orgId, id } = useParams();
  const { isLoading } = useSelector((state: any) => state.tasks);

  React.useEffect(() => {
    (async () => {
      const data = await getAllPermissionApi(id, dispatch);
      console.log(data);

      if (data) {
        let apps = data.map((item: any) => {
          const permissions = [];
          if (item.create) permissions.push('Create');
          if (item.read) permissions.push('Read');
          if (item.update) permissions.push('Update');
          if (item.remove) permissions.push('Delete');
          if (!item.create && !item.read && !item.update && !item.remove) {
            permissions.push('No permission');
          }
          return { permission: permissions.join('/'), ...item };
        })
        let sortData = apps?.sort((data1: any, data2: any) => (data1.id > data2.id) ? 1 : (data1.id < data2.id) ? -1 : 0);
        setPermissions(sortData);
      }

    })();
  }, []);

  const handleView = (user_id: number) => {
    navigate(`${user_id}`, { state: { action: "view", showMenu: true, formName: "Permissions_", setFormName: "Permission_Details" } });
  };

  const handleEdit = (user_id: number) => {
    console.log(user_id);

    navigate(`${user_id}`, { state: { action: "update", showMenu: true, formName: "Permissions_", setFormName: "Permission_Update" } });
  };

  const handleCreate = () => {
    navigate(`${RoutesUrl.CREATE}`, { state: { action: "create", showMenu: true, formName: "Permissions_", setFormName: "Permission_Create" } });
  }

  const handleDelete = async () => {
    const obj = { user_id: deleteId };
    await deletePermissionApi(id, obj, setPermissions, dispatch, permissions, rowsPerPage, setPage);
    setOpen(false);
  };

  const handleOpen = (id: any) => {
    setDeleteId(id);
    setOpen(true);
  };

  const handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  if (isLoading) {
    return <Loading />
  }

  return (
    <Box>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='flex-end'
        my={2}
      >
        <Button variant='contained' color='success' onClick={handleCreate}>Add Permissions</Button>
      </Stack>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{ fontWeight: 700 }}>ID</TableCell>
              <TableCell align="center" sx={{ fontWeight: 700 }}>PERMISSIONS</TableCell>
              <TableCell align="center" sx={{ fontWeight: 700 }}>ACTIONS</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              permissions?.map((permissionList: any, index: number) => (
                <TableRow key={index}>
                  <TableCell align='center'>{permissionList.user_id.id}</TableCell>
                  <TableCell align='center'>{permissionList.permission}</TableCell>
                  <TableCell align='center'>
                    <ActionButton
                      handleView={() => handleView(permissionList.user_id.id)}
                      handleOpen={() => handleOpen(permissionList.user_id.id)}
                      handleEdit={() => handleEdit(permissionList.user_id.id)}
                    />
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
        <DeleteModal handleDelete={handleDelete} open={open} setOpen={setOpen} deleteId={deleteId} />
      </TableContainer>
      <Stack marginY={2} alignItems='center' direction='row' flexDirection='column'>
        <Pagination count={Math.ceil(permissions?.length / rowsPerPage)} page={page} onChange={handlePagination} color='primary' />
      </Stack>
    </Box>
  )
}


export default PermissionsTable