import PagesTemplate from '../components/others/pages-template';
import RulesTable from '../components/page-rules/rules-table';

const RulesPage: React.FC = () => {
  return (
        <PagesTemplate>
          <RulesTable />
        </PagesTemplate>
  )
}

export default RulesPage