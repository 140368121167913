import { setLoading, showErrorModal } from "../../../store/slices/taskslice";
import Axios, { API_DOMAIN, API_PATH } from "../../axios-config";

const FIELDS_API = {
    post_fields_api : (orgId: number, appId: number)=>`${orgId}/${appId}/${API_PATH.fieldsType}`,
    get_all_fields_api : (orgId: number, appId: number)=>`${orgId}/${appId}/${API_PATH.fieldsType}`,
    get_fields_api : (orgId: number, appId: number, fieldId: number)=>`${orgId}/${appId}/${API_PATH.fieldsType}/${fieldId}`,
    put_fields_api : (orgId: number, appId: number, fieldId: number)=>`${orgId}/${appId}/${API_PATH.fieldsType}/${fieldId}`,
    delete_fields_api : (orgId: number, appId: number, fieldId: number)=>`${orgId}/${appId}/${API_PATH.fieldsType}/${fieldId}`
}

export const getAllFieldsApi = async (orgId: any,appId: any,dispatch: any)=>{
    dispatch(setLoading(true));
    try {
        const response = await Axios.get(`${API_DOMAIN}${FIELDS_API.get_all_fields_api(orgId,appId)}`)
        dispatch(setLoading(false));
        return response?.data;
    }
    catch (error: any){
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const getFieldsApi = async (orgId: any,appId: any,fieldId: any,dispatch: any)=>{
    dispatch(setLoading(true));
    try {
        const response = await Axios.get(`${API_DOMAIN}${FIELDS_API.get_fields_api(orgId,appId,fieldId)}`)
        dispatch(setLoading(false));
        return response?.data;
    }
    catch (error: any){
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const putFieldsApi = async (orgId: any,appId: any,rolesId: any,payload: any,dispatch: any)=>{
    dispatch(setLoading(true));
    try {
        const response = await Axios.put(`${API_DOMAIN}${FIELDS_API.put_fields_api(orgId,appId,rolesId)}`,payload)
        dispatch(setLoading(false));
        return response;
    }
    catch (error: any){
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const postFieldsApi = async (orgId: any,appId: any,payload: any,dispatch: any)=>{
    dispatch(setLoading(true));
    try {
        const response = await Axios.post(`${API_DOMAIN}${FIELDS_API.post_fields_api(orgId,appId)}`,payload)
        dispatch(setLoading(false));
        return response;
    }
    catch (error: any){
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const deleteFieldsApi = async (orgId: any,appId: any,fieldId: any, dispatch: any,setFields: any,fields: any,rowsPerPage: number,setPage: any)=>{
    dispatch(setLoading(true));
    try {
        const response = await Axios.delete(`${API_DOMAIN}${FIELDS_API.delete_fields_api(orgId,appId,fieldId,)}`)
        const data = await getAllFieldsApi(orgId, appId, dispatch);
        if(data) {
            if(data){
                if(fields?.length > rowsPerPage){
                    ((fields?.length - 1)) % rowsPerPage === 0 ? setPage((prev: number) => prev - 1) : setPage((prev: number) => prev);
                 }
                setFields(data);
            }
        }
        dispatch(setLoading(false));
        return response;
    }
    catch (error: any){
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}