import React, { useEffect } from 'react'
import { deleteAdminRoles_AppManagerApi, getAllAdminRoles_AppManagerApi } from '../../config/api';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Pagination, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';

import ActionButton from '../others/actionbutton';
import { RoutesUrl } from '../../enum/routes-url';
import Loading from '../others/loading.component';
import DeleteModal from '../others/modals/delete-modal';
import { ToastContainer } from 'react-toastify';

const AdminRolesTable: React.FC = () => {

  const [adminRoles, setAdminRoles] = React.useState<string[]>([]);
  const [open, setOpen] = React.useState<boolean>(false);
  const [deleteId, setDeleteId] = React.useState<number>();
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const dispatch = useDispatch();
  const { isLoading } = useSelector((state: any) => state.tasks);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const data = await getAllAdminRoles_AppManagerApi(dispatch);
      if (data) {
        let sortData = data?.sort((data1: any, data2: any) => (data1.id > data2.id) ? 1 : (data1.id < data2.id) ? -1 : 0);
        setAdminRoles(sortData);
      }
    })();
  }, []);

  const handleOpen = (id: any) => {
    setDeleteId(id);
    setOpen(true);
  };

  const handleView = (id: number) => {
    navigate(`${id}`, { state: { action: "view", showMenu: false, formName: "Admin_roles_Details" } });
  }

  const handleEdit = (id: number) => {
    navigate(`${id}`, { state: { action: "update", showMenu: false, formName: "Admin_roles_Update" } });
  }

  const handleCreate = () => {
    navigate(`${RoutesUrl.CREATE}`, { state: { action: "create", showMenu: false, formName: "Admin_roles_Create" } });
  }

  const handleDelete = async () => {
    await deleteAdminRoles_AppManagerApi(deleteId, setAdminRoles, dispatch,adminRoles,rowsPerPage,setPage);
    setOpen(false);
  };

  const handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  if (isLoading) {
    return <Loading />
  }

  return (
    <Box>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        m={2}
      >
        <Typography variant='h3'>Admins_Roles</Typography>
        <Button variant='contained' color='success' onClick={handleCreate}>Add Roles</Button>
      </Stack>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{ fontWeight: 700 }}>ID</TableCell>
              <TableCell align="center" sx={{ fontWeight: 700 }}>NAME</TableCell>
              <TableCell align="center" sx={{ fontWeight: 700 }}>ACTIONS</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              adminRoles?.slice((page - 1) * rowsPerPage, page * rowsPerPage)?.map((admins_list: any, index: number) => (
                <TableRow key={index}>
                  <TableCell align='center'>{admins_list.id}</TableCell>
                  <TableCell align='center'>{admins_list.name}</TableCell>
                  <TableCell align='center'>
                    <ActionButton
                      handleView={() => handleView(admins_list.id)}
                      handleOpen={() => handleOpen(admins_list.id)}
                      handleEdit={() => handleEdit(admins_list.id)}
                      showDelete = {admins_list?.name === "platform_admin" || admins_list?.name === "support_admin"  ? true : false}
                      showEdit = {admins_list?.name === "platform_admin" || admins_list?.name === "support_admin"  ? true : false}
                    />
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
        <DeleteModal handleDelete={handleDelete} open={open} setOpen={setOpen} deleteId={deleteId} />
      </TableContainer>
      <Stack marginY={2} alignItems='center' direction='row' flexDirection='column'>
        <Pagination count={Math.ceil(adminRoles?.length / rowsPerPage)} page={page} onChange={handlePagination} color='primary' />
      </Stack>
      <ToastContainer autoClose={1000} />
    </Box>
  )
}

export default AdminRolesTable