import { setLoading, showErrorModal } from "../../../store/slices/taskslice";
import Axios, { API_DOMAIN, API_PATH } from "../../axios-config";

export const ADMIN__ROLES_APP_MANAGER_API = {
    post_admin_roles : `${API_DOMAIN}${API_PATH.adminRoles}`,
    get_admin_roles_admin_id : (admin_roles_id: number)=>`${API_DOMAIN}${API_PATH.adminRoles}/${admin_roles_id}`,
    get_admin_roles_all : `${API_DOMAIN}${API_PATH.adminRoles}`,
    put_admin_roles: (admin_roles_id: number)=>`${API_DOMAIN}${API_PATH.adminRoles}/${admin_roles_id}`,
    delete_admin_roles: (admin_roles_id: number)=>`${API_DOMAIN}${API_PATH.adminRoles}/${admin_roles_id}`
}

export const postAdminRoles_AppManagerApi = async (payload: any,dispatch: any) => {
    dispatch(setLoading(true));
    try {
        const response = await Axios.post(`${ADMIN__ROLES_APP_MANAGER_API.post_admin_roles}`,payload);
        dispatch(setLoading(false));
        return response;
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const getAdminRoles_AppManagerApi = async (adminRolesId: any,dispatch: any) => {
    dispatch(setLoading(true));
    try {
        const response = await Axios.get(`${ADMIN__ROLES_APP_MANAGER_API.get_admin_roles_admin_id(adminRolesId)}`);
        dispatch(setLoading(false));
        return response?.data;
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const getAllAdminRoles_AppManagerApi = async (dispatch: any) => {
    dispatch(setLoading(true));
    try {
        const response = await Axios.get(`${ADMIN__ROLES_APP_MANAGER_API.get_admin_roles_all}`);
        dispatch(setLoading(false));
        return response?.data;
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const putAdminRoles_AppManagerApi = async (adminRolesId: any,payload: any, dispatch: any) => {
    dispatch(setLoading(true));
    try {
        const response = await Axios.put(`${ADMIN__ROLES_APP_MANAGER_API.put_admin_roles(adminRolesId)}`,payload);
        dispatch(setLoading(false));
        return response;
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const deleteAdminRoles_AppManagerApi = async (deleteId: any,setAdminRoles: any,dispatch: any,adminsRoles: any,rowsPerPage: number,setPage: any) => {
    dispatch(setLoading(true));
    try {
        const response = await Axios.delete(`${ADMIN__ROLES_APP_MANAGER_API.delete_admin_roles(deleteId)}`);
        const data = await getAllAdminRoles_AppManagerApi(dispatch);
        if(data){
            if(adminsRoles?.length > rowsPerPage){
                ((adminsRoles?.length - 1)) % rowsPerPage === 0 ? setPage((prev: number) => prev - 1) : setPage((prev: number) => prev);
             }
            setAdminRoles(data);
        }
        dispatch(setLoading(false));
        return response;
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}