import PagesTemplate from '../components/others/pages-template';
import FieldsTable from '../components/page-fields/fields-table';

const FieldsPage: React.FC = () => {
  return (
        <PagesTemplate>
          <FieldsTable />
        </PagesTemplate>
  )
}

export default FieldsPage