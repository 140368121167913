import PagesTemplate from '../components/others/pages-template';
import MenuProfileDetailsTable from '../components/page-menu-profile-details/menu-profile-details-table';

const MenuProfileDetails: React.FC = () => {
  return (
        <PagesTemplate>
          <MenuProfileDetailsTable />
        </PagesTemplate>
  )
}

export default MenuProfileDetails