import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { Button, FormLabel, Stack, TextField, MenuItem, FormHelperText, Typography } from '@mui/material';
import { NavigateFunction, useLocation, useNavigate, useParams } from 'react-router-dom';
import { RoutesUrl } from '../../enum/routes-url';
import { getRolesApi, postRolesApi, putRolesApi } from '../../config/api';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import ErrorModal from '../others/modals/error-modal';
import Loading from '../others/loading.component';
import CardComponent from '../others/modals/card-component';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const RolesForm: React.FC = () => {

    const [state, setState] = React.useState<State>({
        name: "",
        active: true,
    });
    const [check, setCheck] = React.useState(false);
    const [locationState, setLoactionState] = React.useState<"create" | "view" | "update">();
    const [disabled, setDisabled] = React.useState<boolean>(false);
    const [updatedisabled, setUpdateDisabled] = React.useState<boolean>(false);
    const [helperText, setHelperText] = React.useState<any>({
        name: "",
    });
    const [open, setOpen] = React.useState(false);

    const handleClose = () => setOpen(false);

    const navigate: NavigateFunction = useNavigate();
    const location = useLocation();
    const { orgId, id, rolesId } = useParams();
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state: any) => state.tasks);

    useEffect(() => {
        setLoactionState(location.state.action);
        if (location.state.action === `${RoutesUrl.VIEW}`) {
            setDisabled(true);
            (async () => {
                const data = await getRolesApi(orgId, id, rolesId, dispatch);
                if (data) setState(data);
            })();
        }

        if (location.state.action === `${RoutesUrl.UPDATE}`) {
            (async () => {
                const data = await getRolesApi(orgId, id, rolesId, dispatch);
                if (data) setState(data);
            })();
        }

    }, []);

    const handleNavigate = () => {
        navigate(-1);
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (locationState === `${RoutesUrl.CREATE}`) {
            handleValidation();
        }
        else if (locationState === `${RoutesUrl.UPDATE}`) {
            handleValidation();
        }
    }

    const handleValidation = async () => {
        const error = {} as any
        if (!state.name) {
            error.name = "Name field is required"
        } else if (!/^[a-zA-Z0-9_]*$/.test(state.name)) {
            error.name = "Name should not contain special character except _";
        } else {
            error.name = ""
        } if (!state.active) {
            error.active = "Active field is required"
        } else {
            error.active = ""
        }

        if (!error.name) {
            if (locationState === `${RoutesUrl.CREATE}`) {
                const postRoles = await postRolesApi(orgId, id, state, dispatch);
                if (postRoles) {
                    setState({
                        name: "",
                        active: true,
                    });
                    toast.success('Created successfully', {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                } else {
                    setOpen(true);
                }
            }
            if (locationState === `${RoutesUrl.UPDATE}`) {
                const obj = Object.assign({}, state) as any
                console.log(obj);
                if (obj.id) {
                    delete obj.id
                }

                const updateRoles = await putRolesApi(orgId, id, rolesId, obj, dispatch);
                if (updateRoles) {
                    toast.success('Orgsform Successfully Updated', {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                } else {
                    setOpen(true);
                }
            }
        }
        setHelperText({ ...helperText, ...error });
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement> | any) => {
        let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value

        if (state.active === "true") {
            state.active = true;
        }
        else if (state.active === "false") {
            state.active = false;
        }
        setState({
            ...state,
            [e.target.name]: value
        });
        setUpdateDisabled(true);

    }


    if (isLoading) {
        return <Loading />
    }

    if (location.state.action === 'view') {

        return (
            <Stack direction='row' flexDirection='column' alignItems='center'>
                <Typography variant='h2'>{location.state.setFormName}</Typography>
                <CardComponent state={state} />
            </Stack>
        )
    }

    return (
        <Box sx={{ width: '100ch', margin: '2ch auto' }}>
            <Typography variant='h2' textAlign='center'>{location.state.setFormName}</Typography>
            <form onSubmit={(e) => { handleSubmit(e) }}>
                <Stack>
                    <FormLabel sx={{ marginBottom: '0.5ch' }}>Name</FormLabel>
                    <TextField
                        type='text'
                        size='small'
                        name='name'
                        disabled={disabled}
                        value={state.name}
                        onChange={handleChange}
                    />
                </Stack>
                <FormHelperText id="outlined-weight-helper-text" error>{helperText.name || " "}</FormHelperText>
                {
                    location.state.action === `${RoutesUrl.CREATE}` ? null : <Stack>
                        <FormControlLabel
                            name='active'
                            value={state.active}
                            control={<Checkbox checked={state.active} onChange={handleChange} />}
                            label="Active"
                        />
                        <FormHelperText id="outlined-weight-helper-text" error>{helperText.active || " "}</FormHelperText>
                    </Stack>
                }

                <Stack direction='row' justifyContent='center' alignItems='center' spacing={3}>
                    <Button
                        style={{ maxWidth: '20%' }}
                        variant='contained'
                        onClick={handleNavigate}
                    >Back
                    </Button>
                    {
                        locationState === `${RoutesUrl.VIEW}` ? null :
                            <Button variant='contained' color='success' type='submit' disabled={!updatedisabled}>
                                {locationState === `${RoutesUrl.CREATE}` ? "create" : "update"}
                            </Button>}

                </Stack>
            </form>
            <ToastContainer autoClose={1000} />
            <ErrorModal open={open} setOpen={setOpen} handleClose={handleClose} />
        </Box>
    )
}
interface State {
    name: string;
    active: any;
}

export default RolesForm