import PagesTemplate from '../components/others/pages-template';
import MenuProfileTable from '../components/page-menu-profile/menu-profile-table';

const MenuProfile: React.FC = () => {
  return (
        <PagesTemplate>
          <MenuProfileTable />
        </PagesTemplate>
  )
}

export default MenuProfile