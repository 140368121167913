import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, Stack, Pagination } from '@mui/material';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';

import ActionButton from '../others/actionbutton';
import DeleteModal from '../others/modals/delete-modal';
import { RoutesUrl } from '../../enum/routes-url';
import { getAllRulesApi } from '../../config/api';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../others/loading.component';
import { deleteRulesApi } from '../../config/api/api-configs/rules-api-config';
import { INITIAL_LIMIT } from '../../constant/constant';

const RulesTable: React.FC = () => {

  const [rules, setRules] = React.useState([]);

  const [open, setOpen] = React.useState<boolean>(false);
  const [deleteId, setDeleteId] = React.useState();
  const [entity_metaId, setEntityMetaId] = React.useState();
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const navigate: NavigateFunction = useNavigate();
  const { orgId, id } = useParams();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state: any) => state.tasks);

  React.useEffect(() => {
    (async () => {
      const data = await getAllRulesApi(orgId, id, dispatch);
      if (data){
        let sortData = data?.sort((data1: any,data2: any)=>(data1.id > data2.id) ? 1 : (data1.id < data2.id) ? -1 : 0);
        setRules(sortData);
      } 
    })();
  }, [])

  const handleDelete = async () => {
    await deleteRulesApi(orgId, id, entity_metaId, deleteId, dispatch, setRules,rules,rowsPerPage,setPage);
    setOpen(false);
  };

  const handleOpen = (id: any, entity_metadata_id: any) => {
    console.log(entity_metaId);

    setEntityMetaId(entity_metadata_id.id);
    setDeleteId(id);
    setOpen(true);
  };

  const handleView = (rulesId: number, entity_metadata_id: number) => {
    navigate(`${rulesId}`, { state: { action: "view", showMenu: true, formName: "Rules_", metadata_id: entity_metadata_id, setFormName: "Rules_Details" } });
  };

  const handleEdit = (rulesId: number, entity_metadata_id: number) => {
    navigate(`${rulesId}`, { state: { action: "update", showMenu: true, formName: "Rules_", metadata_id: entity_metadata_id, setFormName: "Rules_Update" } });
  };

  const handleCreate = () => {
    navigate(`${RoutesUrl.CREATE}`, { state: { action: "create", showMenu: true, formName: "Rules_", setFormName: "Rules_Create" } });
  }

  const handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  if (isLoading) {
    return <Loading />
  }

  return (
    <Box>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='flex-end'
        my={2}
      >
        <Button variant='contained' color='success' onClick={handleCreate}>Add Rules</Button>
      </Stack>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{ fontWeight: 700 }}>ID</TableCell>
              <TableCell align="center" sx={{ fontWeight: 700 }}>NAME</TableCell>
              <TableCell align="center" sx={{ fontWeight: 700 }}>ENTITY</TableCell>
              <TableCell align="center" sx={{ fontWeight: 700 }}>SCRIPT_TYPE</TableCell>
              <TableCell align="center" sx={{ fontWeight: 700 }}>RULE_TYPE</TableCell>
              <TableCell align="center" sx={{ fontWeight: 700 }}>TRIGGER</TableCell>
              <TableCell align="center" sx={{ fontWeight: 700 }}>ACTIONS</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              rules?.slice((page-1) * rowsPerPage, page * rowsPerPage).map((rulesList: any, index: number) => (
                <TableRow key={index}>
                  <TableCell align='center'>{rulesList.id}</TableCell>
                  <TableCell align='center'>{rulesList.name}</TableCell>
                  <TableCell align='center'>{rulesList.entity_metadata_id.name}</TableCell>
                  <TableCell align='center'>{rulesList.script_type}</TableCell>
                  <TableCell align='center'>{rulesList.rule_type}</TableCell>
                  <TableCell align='center'>{rulesList.trigger}</TableCell>
                  <TableCell align='center'>
                    <ActionButton
                      handleView={() => handleView(rulesList.id, rulesList.entity_metadata_id)}
                      handleOpen={() => handleOpen(rulesList.id, rulesList.entity_metadata_id)}
                      handleEdit={() => handleEdit(rulesList.id, rulesList.entity_metadata_id)}
                    />
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
        <DeleteModal handleDelete={handleDelete} open={open} setOpen={setOpen} deleteId={deleteId} />
      </TableContainer>
      <Stack marginY={2} alignItems='center' direction='row' flexDirection='column'>
        <Pagination count={Math.ceil(rules?.length / rowsPerPage)} page={page} onChange={handlePagination} color='primary' />
      </Stack>
    </Box>
  )
}

export default RulesTable