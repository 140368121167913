import React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const DeleteModal: React.FC<Props> = (props: Props) => {

    const { open, setOpen, handleDelete,deleteId } = props;

    const handleClose = () => setOpen(false);


    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2" textAlign='center'>
                    Do you want to delete this id: {deleteId}!
                </Typography>
                <Stack
                    direction="row"
                    justifyContent='center'
                    spacing={4}
                    mt={4}
                    mb={2}
                >
                    <Button variant='contained' color='error' onClick={handleDelete}>Yes</Button>
                    <Button variant='outlined' onClick={handleClose}>No</Button>
                </Stack>
            </Box>
        </Modal>
    )
}

interface Props {
    handleDelete: () => void;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    deleteId: number | undefined ;
}
export default DeleteModal