import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { Button, FormLabel, Stack, TextField, Typography, FormHelperText, FormControlLabel, Checkbox } from '@mui/material';
import { NavigateFunction, useLocation, useNavigate, useParams } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { RoutesUrl } from '../../enum/routes-url';
import { getFieldsApi } from '../../config/api';
import { useDispatch, useSelector } from 'react-redux';
import { postFieldsApi, putFieldsApi } from '../../config/api/api-configs/fields-api-config';
import { ToastContainer, toast } from 'react-toastify';
import ErrorModal from '../others/modals/error-modal';
import Loading from '../others/loading.component';
import { CURRENCY_LIST, DATA_TYPE, TYPE } from '../../constant/type/field-type-constant';
import FieldCardComponent from './fields-card-component';

const FieldForm: React.FC = () => {

    const [state, setState] = React.useState<State>({
        key: "",
        label: "",
        description: "",
        validateOn: "blur",
        type: "",
        data_type: "",
        currency: "",
        placeholder: "",
        daytype: "select",
        monthtype: "select",
        yeartype: "select",
        minLength: "",
        maxLength: "",
        pattern: "",
        valuesLabel: "",
        valuesValue: "",
        displaytimezone: "",
        time_24hr: false,
        timeZone: "",
        format: "",
        minYear: "",
        maxYear: "",
        storage: "",
        primary: false,
        unique: false,
        autofocus: false,
        active: true,
        image: false,
        uploadOnly: false,
        multiple: false,
    });
    const [locationState, setLoactionState] = React.useState<"create" | "view" | "update">();
    const [disabled, setDisabled] = React.useState<boolean>(false);
    const [updatedisabled, setUpdateDisabled] = React.useState<boolean>(false);
    const [helperText, setHelperText] = React.useState<any>({

    });
    const [open, setOpen] = React.useState(false);
    const [menuDataType, setMenuDataType] = React.useState<any>(DATA_TYPE);
    const [values, setValues] = React.useState([{ label: "", value: "" }]);
    const [questions, setQuestions] = React.useState([{ label: "", value: "" }]);

    const navigate: NavigateFunction = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { orgId, id, fieldsId } = useParams();
    const { isLoading } = useSelector((state: any) => state.tasks);

    const handleClose = () => setOpen(false);

    useEffect(() => {
        setLoactionState(location.state.action);
        if (location.state.action === `${RoutesUrl.VIEW}`) {
            setDisabled(true);
            (async () => {
                const data = await getFieldsApi(orgId, id, fieldsId, dispatch);
                if (data) setState(data);
            })();
        }

        if (location.state.action === `${RoutesUrl.UPDATE}`) {
            setDisabled(true);
            (async () => {
                const data = await getFieldsApi(orgId, id, fieldsId, dispatch);
                if (data) {
                    data.minLength = data.validate?.minLength;
                    data.maxLength = data.validate?.maxLength;
                    data.pattern = data.validate?.pattern;
                    data.type === "select" ? setValues(data.data.values) : setValues(data.values);
                    if (data.type === "day") {
                        data.minYear = data.fields?.year?.minYear;
                        data.maxYear = data.fields?.year?.maxYear;
                        data.daytype = data.fields?.day.type;
                        data.monthtype = data.fields?.day.type;
                        data.yeartype = data.fields?.year.type;
                    }
                    if (data.type === "datetime") {
                        data.format = data.widget.format;
                        data.displaytimezone = data.widget.displayInTimezone;
                        data.timeZone = data.widget.timezone;
                        data.time_24hr = data.widget.time_24hr;
                    }
                    setQuestions(data.questions);
                    setState(data);
                }
            })();
        }
    }, []);

    const handleNavigate = () => {
        navigate(-1);
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        handleValidate();
    };

    const handleValidate = async () => {
        const error = {} as any
        if (!state.key) {
            error.key = "Name field is required"
        } else if (!/^[a-zA-Z0-9_]*$/.test(state.key)) {
            error.key = "System Name should not contain special character except _";
        } else {
            error.key = ""
        }
        if (!state.label) {
            error.label = "Label field is required"
        } else if (!(state.label.trim().length > 0)) {
            error.label = "Label should not contain empty space";
        } else {
            error.label = ""
        }
        if (!state.type) {
            error.type = "Type field is required"
        } else {
            error.type = ""
        }
        if (!state.data_type) {
            error.data_type = "DataTypes field is required"
        } else {
            error.data_type = ""
        }
        if (state.minLength) {
            state.minLength = Number(state.minLength)
            state.minLength < 0 ? error.minLength = "Negative should not allow" : error.minLength = ""
        }
        if (state.maxLength) {
            state.maxLength = Number(state.maxLength)
            Number(state.maxLength) < 0 ? error.maxLength = "Negative should not allow" : error.maxLength = ""
            if (Number(state.minLength) >= Number(state.maxLength)) {
                error.maxLength = "Min should not be greater than or equal to Max"
            }
        }
        if (state.minYear) {
            if (!/^\d{4}$/.test(state.minYear)) {
                error.minYear = "min should only be 4 digit numbers only";
            } else {
                error.minYear = ""
            }
        }
        if (state.maxYear) {
            if (!/^\d{4}$/.test(state.maxYear)) {
                error.maxYear = "max should only be 4 digit numbers only";
            } else if (state.minYear > state.maxYear) {
                error.maxYear = "max year should be greater than min value"
            } else {
                error.maxYear = ""
            }
        }
        if (
            !error.name && !error.label && !error.type && !error.max && !error.data_type && !error.minYear && !error.maxYear && !error.minLength && !error.maxlength
        ) {
            let obj = Object.assign({}, state) as any;
            delete obj.relation;
            delete obj.relatedField;

            if (!obj.description) {
                delete obj.description;
            }

            if (!obj.pattern) {
                delete obj.pattern
            }
            if (!obj.minLength) {
                delete obj.minLength
            }
            if (!obj.maxLength) {
                delete obj.maxLength
            }

            if (obj.type === "textfield" || obj.type === "textarea" || obj.type === "password") {
                delete obj.currency;
                delete obj.daytype;
                delete obj.monthtype;
                delete obj.yeartype;
                delete obj.displaytimezone;
                delete obj.format;
                delete obj.maxYear;
                delete obj.minYear;
                delete obj.timeZone;
                delete obj.time_24hr;
                delete obj.valuesLabel;
                delete obj.valuesValue;
                delete obj.validate;
            }
            if (obj.type === "file") {
                delete obj.currency;
                delete obj.displaytimezone;
                delete obj.format;
                delete obj.maxLength;
                delete obj.maxYear;
                delete obj.minLength;
                delete obj.minYear;
                delete obj.pattern;
                delete obj.placeholder;
                delete obj.timeZone;
                delete obj.valuesLabel;
                delete obj.valuesValue;
                delete obj.daytype;
                delete obj.monthtype;
                delete obj.yeartype;
                delete obj.time_24hr;
            } else {
                delete obj.multiple;
                delete obj.image;
                delete obj.storage;
                delete obj.uploadOnly;
            }

            if (obj.type === "textarea") {
                delete obj.currency;
                delete obj.daytype;
                delete obj.monthtype;
                delete obj.yeartype;
                delete obj.displaytimezone;
                delete obj.format;
                delete obj.maxYear;
                delete obj.minYear;
                delete obj.timeZone;
                delete obj.time_24hr;
                delete obj.valuesLabel;
                delete obj.valuesValue;
                delete obj.validate;
                delete obj.pattern;
            }
            if (obj.type === "tags") {
                delete obj.currency;
                delete obj.daytype;
                delete obj.monthtype;
                delete obj.yeartype;
                delete obj.displaytimezone;
                delete obj.format;
                delete obj.maxYear;
                delete obj.minYear;
                delete obj.timeZone;
                delete obj.time_24hr;
                delete obj.valuesLabel;
                delete obj.valuesValue;
                delete obj.validate;
                delete obj.pattern;
                // delete obj.property;
                delete obj.minLength;
                delete obj.maxLength;
            }
            if (obj.type === "email" || obj.type === "url") {
                delete obj.currency;
                delete obj.daytype;
                delete obj.monthtype;
                delete obj.yeartype;
                delete obj.displaytimezone;
                delete obj.format;
                delete obj.maxYear;
                delete obj.minYear;
                delete obj.timeZone;
                delete obj.time_24hr;
                delete obj.valuesLabel;
                delete obj.valuesValue;
                delete obj.validate;
                delete obj.pattern;
                delete obj.minLength;
                delete obj.property;
            }

            if (obj.type === "number") {
                delete obj.validate;
                delete obj.currency;
                delete obj.daytype;
                delete obj.monthtype;
                delete obj.yeartype;
                delete obj.displaytimezone;
                delete obj.format;
                delete obj.maxYear;
                delete obj.minYear;
                delete obj.timeZone;
                delete obj.time_24hr;
                delete obj.valuesLabel;
                delete obj.valuesValue;
                delete obj.pattern;
            }
            if (obj.type === "selectboxes" || obj.type === "select" || obj.type === "radio") {
                delete obj.validate;
                delete obj.currency;
                delete obj.daytype;
                delete obj.monthtype;
                delete obj.yeartype;
                delete obj.displaytimezone;
                delete obj.format;
                delete obj.maxYear;
                delete obj.minYear;
                delete obj.timeZone;
                delete obj.time_24hr;
                delete obj.valuesLabel;
                delete obj.valuesValue;
                delete obj.minLength;
                delete obj.maxLength;
                delete obj.placeholder;
                delete obj.pattern;
                obj.values = values;
            }
            if (obj.type === "survey") {
                delete obj.validate;
                delete obj.currency;
                delete obj.daytype;
                delete obj.monthtype;
                delete obj.yeartype;
                delete obj.displaytimezone;
                delete obj.format;
                delete obj.maxYear;
                delete obj.minYear;
                delete obj.timeZone;
                delete obj.time_24hr;
                delete obj.valuesLabel;
                delete obj.valuesValue;
                delete obj.minLength;
                delete obj.maxLength;
                delete obj.placeholder;
                delete obj.pattern;
                obj.questions = questions
            }

            if (obj.type === "datetime") {
                obj.widget = {
                    format: obj.format,
                    time_24hr: obj.time_24hr,
                    displayInTimezone: obj.displaytimezone,
                    timezone: obj.timeZone
                }
                delete obj.currency;
                delete obj.daytype;
                delete obj.monthtype;
                delete obj.yeartype;
                delete obj.displaytimezone;
                delete obj.format;
                delete obj.minLength;
                delete obj.maxLength;
                delete obj.maxYear;
                delete obj.minYear;
                delete obj.timeZone;
                delete obj.time_24hr;
                delete obj.valuesLabel;
                delete obj.valuesValue;
                delete obj.validate;
                delete obj.pattern;
                delete obj.placeholder;
            }

            if (obj.type === "checkbox") {
                delete obj.daytype;
                delete obj.monthtype;
                delete obj.yeartype;
                delete obj.displaytimezone;
                delete obj.format;
                delete obj.minLength;
                delete obj.maxLength;
                delete obj.maxYear;
                delete obj.minYear;
                delete obj.timeZone;
                delete obj.time_24hr;
                delete obj.valuesLabel;
                delete obj.valuesValue;
                delete obj.validate;
                delete obj.pattern;
                delete obj.placeholder;
                delete obj.currency;
            }

            if (obj.type === "currency") {
                delete obj.daytype;
                delete obj.monthtype;
                delete obj.yeartype;
                delete obj.displaytimezone;
                delete obj.format;
                delete obj.minLength;
                delete obj.maxLength;
                delete obj.maxYear;
                delete obj.minYear;
                delete obj.timeZone;
                delete obj.time_24hr;
                delete obj.valuesLabel;
                delete obj.valuesValue;
                delete obj.validate;
                delete obj.pattern;
                delete obj.placeholder;
            }

            if (obj.type === "day") {
                if (obj.minYear) {
                    obj.minYear = Number(obj.minYear)
                }
                if (obj.maxYear) {
                    obj.maxYear = Number(obj.maxYear)
                }
                obj.fields = {
                    day: {
                        type: obj.daytype
                    },
                    month: {
                        type: obj.monthtype
                    },
                    year: {
                        type: obj.yeartype,
                        minYear: obj.minYear,
                        maxYear: obj.maxYear,
                    }
                }
                delete obj.validate;
                delete obj.currency;
                delete obj.daytype;
                delete obj.monthtype;
                delete obj.yeartype;
                delete obj.displaytimezone;
                delete obj.format;
                delete obj.maxYear;
                delete obj.minYear;
                delete obj.timeZone;
                delete obj.time_24hr;
                delete obj.valuesLabel;
                delete obj.valuesValue;
                delete obj.minLength;
                delete obj.maxLength;
                delete obj.placeholder;
                delete obj.pattern;
            }

            if (location.state.action === `${RoutesUrl.CREATE}`) {
                const postRoles = await postFieldsApi(orgId, id, obj, dispatch);
                if (postRoles) {
                    setState({
                        key: "",
                        label: "",
                        description: "",
                        validateOn: "blur",
                        type: "",
                        data_type: "",
                        currency: "",
                        placeholder: "",
                        daytype: "select",
                        monthtype: "select",
                        yeartype: "select",
                        minLength: "",
                        maxLength: "",
                        pattern: "",
                        valuesLabel: "",
                        valuesValue: "",
                        displaytimezone: "",
                        time_24hr: false,
                        timeZone: "",
                        format: "",
                        minYear: "",
                        maxYear: "",
                        storage: "",
                        primary: false,
                        unique: false,
                        autofocus: false,
                        active: true,
                        image: false,
                        uploadOnly: false,
                        multiple: false,
                    });
                    toast.success('Created successfully', {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                } else {
                    setOpen(true);
                }
            }

            if (locationState === `${RoutesUrl.UPDATE}`) {
                if (obj.type === 'textfield' || obj.type === 'textarea' || obj.type === 'number' || obj.type === 'password' || obj.type === 'checkbox' || obj.type === 'selectboxes' || obj.type === 'email') {
                    delete obj.components;
                    delete obj.customClass;
                    delete obj.displayInTimezone;
                    delete obj.fields;
                    delete obj.provider;
                    delete obj.providerOptions;
                    delete obj.questions;
                    delete obj.timezone;
                    //    delete obj.values;
                    delete obj.widget;
                }

                delete obj.data;
                delete obj.id;
                delete obj.key;
                delete obj.data_type;
                delete obj.type;
                delete obj.input;
                delete obj.unique;
                delete obj.required;
                delete obj.primary;
                console.log(obj);

                const updateRoles = await putFieldsApi(orgId, id, fieldsId, obj, dispatch);
                if (updateRoles) {
                    toast.success('Successfully Updated', {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                } else {
                    setOpen(true);
                }
            }
        }

        setHelperText({ ...helperText, ...error })
    }

    const handleChange = (e: any) => {
        if (e.target.name === "min" || e.target.name === "max") {
            setState({
                ...state,
                [e.target.name]: e.target.value
            });
            setMenuDataType(DATA_TYPE)
        } else if (e.target.name === "unique" || e.target.name === "autofocus" || e.target.name === "primary" || e.target.name === "active" || e.target.name === "time_24hr" || e.target.name === "image" || e.target.name === "uploadOnly" || e.target.name === "multiple") {
            let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
            if (e.target.value === "true") {
                e.target.value = true
            } else if (e.target.value === "false") {
                e.target.value = false
            }
            setState({
                ...state,
                [e.target.name]: value
            });
            setMenuDataType(DATA_TYPE);
        } else if (e.target.name === "type") {
            if (e.target.value === "textfield" || e.target.value === "textarea" || e.target.value === "password" || e.target.value === "email" || e.target.value === "url" || e.target.value === "radio" || e.target.value === "select" || e.target.value === "tags") {
                state.data_type = "text";
                setMenuDataType(DATA_TYPE);
            } else if (e.target.value === "selectboxes" || e.target.value === "address" || e.target.value === "survey") {
                state.data_type = "json";
                setMenuDataType(DATA_TYPE);
            } else if (e.target.value === "checkbox") {
                state.data_type = "boolean";
                setMenuDataType(DATA_TYPE);
            } else if (e.target.value === "datetime" || e.target.value === "day") {
                state.data_type = "timestamp with time zone";
                setMenuDataType(DATA_TYPE);
            } else if (e.target.value === "currency") {
                state.data_type = "decimal";
                setMenuDataType(DATA_TYPE);
            } else if (e.target.value === "number") {
                setMenuDataType([
                    "decimal", "integer", "serial"
                ])
            } else if (e.target.value === "file") {
                state.data_type = "bytea"
            } else {
                setMenuDataType(DATA_TYPE);
            }
            setState({
                ...state,
                [e.target.name]: e.target.value
            })
        } else
            setState({
                ...state,
                [e.target.name]: e.target.value
            });
        setUpdateDisabled(true);
    };

    const handleAddInput = () => {
        setValues([...values, { label: "", value: "" }]);
    }

    const handleAddQuestionInput = () => {
        setQuestions([...questions, { label: "", value: "" }]);
    }

    const handleChangeQuestions = (e: any, i: number) => {
        const { name, value } = e.target
        console.log(name, value);
        const onChangeVal: any = [...questions]
        onChangeVal[i][name] = value
        setQuestions(onChangeVal);
        setUpdateDisabled(true);
    }

    const handleChangeValues = (e: any, i: number) => {
        const { name, value } = e.target
        console.log(name, value);
        const onChangeVal: any = [...values]
        onChangeVal[i][name] = value
        setValues(onChangeVal);
        setUpdateDisabled(true);
    }

    const handleDeleInput = (i: number) => {
        const deleteVal = [...values]
        deleteVal.splice(i, 1);
        setValues(deleteVal);
        setUpdateDisabled(true);
    }

    const handleDeleInputSurvey = (i: number) => {
        const deleteVal = [...values]
        deleteVal.splice(i, 1);
        setQuestions(deleteVal);
        setUpdateDisabled(true);
    }

    if (isLoading) {
        return <Loading />
    }

    if (location.state.action === `${RoutesUrl.VIEW}`) {
        const obj = Object.assign({}, state) as any;

        return (
            <Stack direction='row' flexDirection='column' alignItems='center'>
                <Typography variant='h2'>{location.state.setFormName}</Typography>
                <FieldCardComponent state={state} />
            </Stack>
        )
    }

    return (
        <Box sx={{ width: '100ch', margin: '2ch auto' }}>
            <Typography variant='h2' textAlign='center'>{location.state.setFormName}</Typography>
            <form onSubmit={(e) => { handleSubmit(e) }}>
                <Stack>
                    <FormLabel sx={{ marginBottom: '0.5ch' }}>Key</FormLabel>
                    <TextField
                        type='text'
                        size='small'
                        name='key'
                        disabled={disabled}
                        value={state.key || ""}
                        onChange={handleChange}
                    />
                </Stack>
                <FormHelperText id="outlined-weight-helper-text" error>{helperText.key || " "}</FormHelperText>
                <Stack>
                    <FormLabel sx={{ marginBottom: '0.5ch' }}>Label</FormLabel>
                    <TextField
                        type='text'
                        size='small'
                        name='label'
                        value={state.label || ""}
                        onChange={handleChange}
                    />
                </Stack>
                <FormHelperText id="outlined-weight-helper-text" error>{helperText.label || " "}</FormHelperText>
                <Stack>
                    <FormLabel sx={{ marginBottom: '0.5ch' }}>Description</FormLabel>
                    <TextField
                        type='text'
                        size='small'
                        name='description'
                        value={state.description || ""}
                        onChange={handleChange}
                    />
                </Stack>
                <FormHelperText id="outlined-weight-helper-text" error>{helperText.description || " "}</FormHelperText>
                <Stack>
                    <FormLabel sx={{ marginBottom: '0.5ch' }}>Validate On</FormLabel>
                    <FormControl fullWidth>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={state.validateOn || ""}
                            size='small'
                            name="validateOn"
                            onChange={handleChange}
                        >
                            <MenuItem value='blur'>blur</MenuItem>
                            <MenuItem value='change'>Change</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>
                <FormHelperText id="outlined-weight-helper-text" error>{helperText.validateOn || " "}</FormHelperText>
                <Stack>
                    <FormLabel sx={{ marginBottom: '0.5ch' }}>Type</FormLabel>
                    <FormControl fullWidth>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={state.type || ""}
                            size='small'
                            name="type"
                            disabled={disabled}
                            onChange={handleChange}
                        >
                            {
                                TYPE.map((item: any, index: number) => {
                                    return (
                                        <MenuItem key={index} value={item}>{item}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                </Stack>
                <FormHelperText id="outlined-weight-helper-text" error>{helperText.type || " "}</FormHelperText>
                <Stack>
                    {
                        state.type === "number" ?
                            <Stack>
                                <FormLabel sx={{ marginBottom: '0.5ch' }}>Data Type</FormLabel>
                                <FormControl fullWidth>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={state.data_type || ""}
                                        size='small'
                                        name="data_type"
                                        onChange={handleChange}
                                    >
                                        {
                                            menuDataType.map((item: any, index: number) => {
                                                return (
                                                    <MenuItem key={index} value={item}>{item}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                <FormHelperText id="outlined-weight-helper-text" error>{helperText.data_type || " "}</FormHelperText>
                            </Stack>
                            : null
                    }
                </Stack>
                <Stack>
                    {
                        state.type === "currency" ?
                            <Stack>
                                <FormLabel sx={{ marginBottom: '0.5ch' }}>Currency List</FormLabel>
                                <FormControl fullWidth>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={state.currency || ""}
                                        size='small'
                                        name="currency"
                                        onChange={handleChange}
                                    >
                                        {
                                            CURRENCY_LIST?.map((item: any, index: number) => {
                                                return (
                                                    <MenuItem key={index} value={item.code}>{item.name}-{item.code}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Stack>
                            : null

                    }
                </Stack>
                <Stack>
                    {
                        state.type === "day" ?
                            <Stack sx={{ border: '1px solid #ccc', padding: '0.8rem' }}>
                                <Stack>
                                    <Typography variant='h5' textAlign='center'>Day</Typography>
                                    <FormLabel sx={{ marginBottom: '0.5ch' }}>Type</FormLabel>
                                    <FormControl fullWidth>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={state.daytype || ""}
                                            size='small'
                                            name="daytype"
                                            onChange={handleChange}
                                        >
                                            <MenuItem value='select'>Select</MenuItem>
                                            <MenuItem value='number'>Number</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormHelperText id="outlined-weight-helper-text" error>{helperText.daytype || " "}</FormHelperText>
                                </Stack>
                                <Stack>
                                    <Typography variant='h5' textAlign='center'>Month</Typography>
                                    <FormLabel sx={{ marginBottom: '0.5ch' }}>Type</FormLabel>
                                    <FormControl fullWidth>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={state.monthtype || ""}
                                            size='small'
                                            name="monthtype"
                                            onChange={handleChange}
                                        >
                                            <MenuItem value='select'>Select</MenuItem>
                                            <MenuItem value='number'>Number</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormHelperText id="outlined-weight-helper-text" error>{helperText.daytype || " "}</FormHelperText>
                                </Stack>
                                <Stack>
                                    <Typography variant='h5' textAlign='center'>Year</Typography>
                                    <FormLabel sx={{ marginBottom: '0.5ch' }}>Type</FormLabel>
                                    <FormControl fullWidth>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={state.yeartype || ""}
                                            size='small'
                                            name="yeartype"
                                            onChange={handleChange}
                                        >
                                            <MenuItem value='select'>Select</MenuItem>
                                            <MenuItem value='number'>Number</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormHelperText id="outlined-weight-helper-text" error>{helperText.daytype || " "}</FormHelperText>
                                </Stack>
                                <Stack>
                                    <FormLabel sx={{ marginBottom: '0.5ch' }}>Min Year</FormLabel>
                                    <TextField
                                        type='text'
                                        size='small'
                                        name='minYear'
                                        value={state.minYear || ""}
                                        onChange={handleChange}
                                    />
                                </Stack>
                                <FormHelperText id="outlined-weight-helper-text" error>{helperText.minYear || " "}</FormHelperText>
                                <Stack>
                                    <FormLabel sx={{ marginBottom: '0.5ch' }}>Max Year</FormLabel>
                                    <TextField
                                        type='text'
                                        size='small'
                                        name='maxYear'
                                        value={state.maxYear || ""}
                                        onChange={handleChange}
                                    />
                                </Stack>
                                <FormHelperText id="outlined-weight-helper-text" error>{helperText.maxYear || " "}</FormHelperText>

                            </Stack>
                            : null

                    }
                </Stack>
                <Stack>
                    {
                        state.type === "password" || state.type === "textfield" ?
                            <Stack>
                                <FormLabel sx={{ marginBottom: '0.5ch' }}>Pattern</FormLabel>
                                <TextField
                                    type='text'
                                    size='small'
                                    name='pattern'
                                    value={state.pattern || ""}
                                    onChange={handleChange}
                                />
                                <FormHelperText id="outlined-weight-helper-text" error>{helperText.pattern || " "}</FormHelperText>
                            </Stack>
                            : null

                    }
                </Stack>
                <Stack>
                    {
                        state.type === "password" || state.type === "textfield" || state.type === "number" || state.type === "textarea" ?
                            <Stack>
                                <Stack>
                                    <FormLabel sx={{ marginBottom: '0.5ch' }}>Min Length</FormLabel>
                                    <TextField
                                        type='text'
                                        size='small'
                                        name='minLength'
                                        value={state.minLength || ""}
                                        onChange={handleChange}
                                    />
                                </Stack>
                                <FormHelperText id="outlined-weight-helper-text" error>{helperText.minLength || " "}</FormHelperText>
                                <Stack>
                                    <FormLabel sx={{ marginBottom: '0.5ch' }}>Max Length</FormLabel>
                                    <TextField
                                        type='text'
                                        size='small'
                                        name='maxLength'
                                        value={state.maxLength || ""}
                                        onChange={handleChange}
                                    />
                                    <FormHelperText id="outlined-weight-helper-text" error>{helperText.maxLength || " "}</FormHelperText>
                                </Stack>
                            </Stack>
                            : null
                    }
                </Stack>
                <Stack>
                    {
                        state.type === "datetime" ?
                            <Stack border='1px solid #ccc' padding={2}>
                                <Typography variant='h5' textAlign='center'>Widget</Typography>
                                <Stack>
                                    <FormLabel sx={{ marginBottom: '0.5ch' }}>Format</FormLabel>
                                    <TextField
                                        type='text'
                                        size='small'
                                        name='format'
                                        value={state.format || ""}
                                        onChange={handleChange}
                                    />
                                    <FormHelperText id="outlined-weight-helper-text" error>{helperText.format || " "}</FormHelperText>
                                </Stack>
                                <Stack>
                                    <FormLabel sx={{ marginBottom: '0.5ch' }}>TimeZone</FormLabel>
                                    <TextField
                                        type='text'
                                        size='small'
                                        name='timeZone'
                                        value={state.timeZone}
                                        onChange={handleChange}
                                    />
                                    <FormHelperText id="outlined-weight-helper-text" error>{helperText.timeZone || " "}</FormHelperText>
                                </Stack>
                                <Stack>
                                    <FormLabel sx={{ marginBottom: '0.5ch' }}>Display Time Zone</FormLabel>
                                    <FormControl fullWidth>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={state.displaytimezone || ""}
                                            size='small'
                                            name="displaytimezone"
                                            onChange={handleChange}
                                        >
                                            <MenuItem value='submission'>submission</MenuItem>
                                            <MenuItem value='viewer'>viewer</MenuItem>
                                            <MenuItem value='location'>location</MenuItem>
                                            <MenuItem value='utc'>utc</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormHelperText id="outlined-weight-helper-text" error>{helperText.displaytimezone || " "}</FormHelperText>
                                </Stack>
                                <Stack>
                                    <FormControlLabel
                                        name='time_24hr'
                                        value={state.time_24hr}
                                        control={<Checkbox checked={state.time_24hr} onChange={handleChange} />}
                                        label="Time 24hr"
                                    />
                                </Stack>
                            </Stack>

                            : null
                    }
                </Stack>
                <Stack>
                    {
                        state.type === "selectboxes" || state.type === "select" || state.type === "option" || state.type === "radio" ?
                            <Stack>
                                <Button variant='contained' color='success' onClick={handleAddInput} sx={{ width: '15%', marginLeft: 'auto' }}>Add+</Button>
                                {
                                    values?.map((val: any, i: number) =>  (
                                            <Stack key={i} direction='row' alignItems='center' gap={2} marginBottom={2}>
                                                <Stack width='50%'>
                                                    <FormLabel sx={{ marginBottom: '0.5ch' }}>Label</FormLabel>
                                                    <TextField
                                                        type='text'
                                                        size='small'
                                                        name='label'
                                                        value={val.label || ""}
                                                        onChange={(e) => handleChangeValues(e, i)}
                                                    />
                                                </Stack>
                                                <Stack width='50%'>
                                                    <FormLabel sx={{ marginBottom: '0.5ch' }}>value</FormLabel>
                                                    <TextField
                                                        type='text'
                                                        size='small'
                                                        name='value'
                                                        value={val.value}
                                                        onChange={(e) => handleChangeValues(e, i)}
                                                    />
                                                </Stack>
                                                <Button variant='contained' color='warning' onClick={() => handleDeleInput(i)} sx={{ width: '15%', marginLeft: 'auto', marginTop: "1.5rem" }}>Delete</Button>
                                            </Stack>
                                        )
                                    )
                                }

                            </Stack>
                            : null
                    }
                </Stack>
                <Stack>
                    {
                        state.type === "survey" ?
                            <Stack>
                                <Button variant='contained' color='success' onClick={handleAddQuestionInput} sx={{ width: '15%', marginLeft: 'auto' }}>Add+</Button>
                                {
                                    questions?.map((val: any, i: number) => 
                                    (
                                        <Stack key={i} direction='row' alignItems='center' gap={2} marginBottom={2}>
                                            <Stack width='50%'>
                                                <FormLabel sx={{ marginBottom: '0.5ch' }}>Label</FormLabel>
                                                <TextField
                                                    type='text'
                                                    size='small'
                                                    name='label'
                                                    value={val.label || ""}
                                                    onChange={(e) => handleChangeQuestions(e, i)}
                                                />
                                            </Stack>
                                            <Stack width='50%'>
                                                <FormLabel sx={{ marginBottom: '0.5ch' }}>value</FormLabel>
                                                <TextField
                                                    type='text'
                                                    size='small'
                                                    name='value'
                                                    value={val.value}
                                                    onChange={(e) => handleChangeQuestions(e, i)}
                                                />
                                            </Stack>
                                            <Button variant='contained' color='warning' onClick={() => handleDeleInputSurvey(i)} sx={{ width: '15%', marginLeft: 'auto', marginTop: "1.5rem" }}>Delete</Button>
                                        </Stack>
                                    ))
                                }

                            </Stack>
                            : null
                    }
                </Stack>
                <Stack>
                    {
                        state.type === "textfield" || state.type === "textarea" || state.type === "number" || state.type === "password" || state.type === "email" || state.type === "address" || state.type === "url" || state.type === "tags" ?
                            <Stack>
                                <FormLabel sx={{ marginBottom: '0.5ch' }}>Place Holder</FormLabel>
                                <TextField
                                    type='text'
                                    size='small'
                                    name='placeholder'
                                    value={state.placeholder || ""}
                                    onChange={handleChange}
                                />
                                <FormHelperText id="outlined-weight-helper-text" error>{helperText.placeholder || " "}</FormHelperText>
                            </Stack> : null
                    }

                </Stack>
                {
                    state.type === 'file' ? <Stack padding={3} border='1px solid #ccc'>
                        <Stack>
                            <FormLabel sx={{ marginBottom: '0.5ch' }}>Storage</FormLabel>
                            <FormControl fullWidth>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={state.storage || ""}
                                    size='small'
                                    name="storage"
                                    onChange={handleChange}
                                >
                                    <MenuItem value='base64'>base64</MenuItem>
                                </Select>
                            </FormControl>
                            <FormHelperText id="outlined-weight-helper-text" error>{helperText.storage || " "}</FormHelperText>
                        </Stack>
                        <Stack>
                            <FormControlLabel
                                name='image'
                                value={state.image || ""}
                                control={<Checkbox checked={state.image} onChange={handleChange} />}
                                label="image"
                            />
                        </Stack>
                        <Stack>
                            <FormControlLabel
                                name='uploadOnly'
                                value={state.uploadOnly || ""}
                                control={<Checkbox checked={state.uploadOnly} onChange={handleChange} />}
                                label="uploadOnly"
                            />
                        </Stack>
                        <Stack>
                            <FormControlLabel
                                name='multiple'
                                value={state.multiple || ""}
                                control={<Checkbox checked={state.multiple} onChange={handleChange} />}
                                label="multiple"
                            />
                        </Stack>
                    </Stack> : null
                }

                {
                    location.state?.action === `${RoutesUrl.UPDATE}` ? null :
                        <Stack marginTop={2}>
                            <FormControlLabel
                                name='primary'
                                value={state.primary || ""}
                                control={<Checkbox checked={state.primary} onChange={handleChange} />}
                                label="Primary"
                            />
                        </Stack>
                }
                {
                    location.state?.action === `${RoutesUrl.UPDATE}` ? null :
                        <Stack>
                            <FormControlLabel
                                name='unique'
                                value={state.unique || ""}
                                control={<Checkbox checked={state.unique} onChange={handleChange} />}
                                label="Unique"
                            />
                        </Stack>
                }
                <Stack>
                    <FormControlLabel
                        name='autofocus'
                        value={state.autofocus || ""}
                        control={<Checkbox checked={state.autofocus} onChange={handleChange} />}
                        label="Auto Focus"
                    />
                </Stack>
                {
                    location.state?.action === `${RoutesUrl.CREATE}` ? null : <Stack>
                        <FormControlLabel
                            name='active'
                            value={state.active || ""}
                            control={<Checkbox checked={state.active} onChange={handleChange} />}
                            label="Active"
                        />
                    </Stack>
                }

                <Stack direction='row' justifyContent='center' alignItems='center' spacing={3}>
                    <Button
                        style={{ maxWidth: '20%' }}
                        variant='contained'
                        onClick={handleNavigate}
                    >Back
                    </Button>
                    {
                        locationState === `${RoutesUrl.VIEW}` ? null :
                            <Button variant='contained' color='success' type='submit' disabled={!updatedisabled}>
                                {locationState === `${RoutesUrl.CREATE}` ? "create" : "update"}
                            </Button>}

                </Stack>
            </form>
            <ToastContainer autoClose={1000} />
            <ErrorModal open={open} setOpen={setOpen} handleClose={handleClose} />
        </Box >
    )
}

interface State {
    key: string,
    label: string,
    description: string,
    validateOn: string,
    type: string,
    data_type: string,
    currency?: string,
    valuesLabel: string,
    valuesValue: string,
    minLength: string | number,
    maxLength: string | number,
    pattern: string,
    minYear: string,
    maxYear: string,
    format?: string,
    timeZone?: string,
    displaytimezone?: string,
    placeholder?: string
    time_24hr?: any,
    daytype: string;
    monthtype: string;
    yeartype: string;
    storage: string;
    unique?: any,
    primary?: any,
    autofocus?: any,
    active: any,
    image: any,
    uploadOnly: any,
    multiple: any,
}

export default FieldForm