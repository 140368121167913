import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { RoutesUrl } from '../../enum/routes-url';

const EntityCard: any = (props: any) => {

  const [state, setState] = React.useState({}) as any;
  const navigate: NavigateFunction = useNavigate();

  React.useEffect(() => {
    setState(props.state);
  }, [props]);

  const handleNavigate = () => {
    navigate(-1);
  }

  const handleEntityFieldMap = ()=>{
    navigate(`${RoutesUrl.ENTITY_FIELD_MAPPING}`, { state: { showMenu: false, setName: "Entity_Field_Map" } });
  }

  return (
    <Card sx={{ maxWidth: 1000, marginX: 'auto', marginY: '20px', width: '100%' , padding: '1rem' }}>
      <Stack
      direction='row'
      justifyContent='flex-end'
      >
        <Button
          style={{ maxWidth: '30%' }}
          variant='contained'
          color='success'
          onClick={handleEntityFieldMap}
        >view entity_field_map
        </Button>
      </Stack>
      <CardContent>
        <Stack direction='row' flexDirection='column'>
          {
            Object.keys(state).map((list: string, index) => {
              return <Stack key={index} direction='row' borderBottom='1px solid #ccc' spacing={2}>
                <Stack width='50%'>{list !== 'id' ? <Typography variant='h2'>{list} : </Typography> : null}</Stack>
                <Stack width='50%'>{list !== 'id' ? <Typography variant='h3'>{typeof (state[list]) === 'boolean' ? state[list].toString() : state[list] === null ? '--' : state[list]}</Typography> : null}</Stack>
              </Stack>
            })
          }
        </Stack>
      </CardContent>
      <CardActions sx={{ justifyContent: 'center' }}>
        <Button size="small" variant='contained' color='primary' onClick={handleNavigate}>Back</Button>
      </CardActions>
    </Card>
  )
}

export default EntityCard