import Axios, { API_DOMAIN, API_PATH } from "../../axios-config";
import { setLoading, showErrorModal } from "../../../store/slices/taskslice";

export const API_APPURL = {
    get_app_id: (orgId: number, appId: number) => `${orgId}/${API_PATH.apps}/${appId}`,
    put_app_id: (orgId: number, appId: number) => `${orgId}/${API_PATH.apps}/${appId}`,
    export_app_id: (orgId: number, appId: number) => `${orgId}/${API_PATH.apps}/${API_PATH.exportSpec}/${appId}`,
    post_app_id: (orgId: number) => `${orgId}/${API_PATH.apps}`,
    get_org_app_one: (orgId: number) => `${orgId}/${API_PATH.apps}`
}

export const getOrgAppIdApi = async (id: any, dispatch: any) => {
    dispatch(setLoading(true));
    try {
        const response = await Axios.get(`${API_DOMAIN}${API_APPURL.get_org_app_one(id)}`);
        dispatch(setLoading(false));
        return response?.data;
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const getAllAppApi = async (dispatch: any, page: number) => {
    dispatch(setLoading(true));
    try {
        const response = await Axios.get(`${API_DOMAIN}apps`);
        dispatch(setLoading(false));
        return response?.data;
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const getAppApi = async (orgId: any, appId: any, dispatch: any) => {
    dispatch(setLoading(true));
    try {
        const response = await Axios.get(`${API_DOMAIN}${API_APPURL.get_app_id(orgId, appId)}`);
        dispatch(setLoading(false));
        return response?.data;
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const putAppApi = async (orgId: any, appId: any, payload: any, dispatch: any) => {
    dispatch(setLoading(true));
    try {
        const response = await Axios.put(`${API_DOMAIN}${API_APPURL.put_app_id(orgId, appId)}`, payload);
        dispatch(setLoading(false));
        return response;
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const postAppApi = async (orgId: any, payload: any, dispatch: any) => {
    dispatch(setLoading(true));
    try {
        const response = await Axios.post(`${API_DOMAIN}${API_APPURL.post_app_id(orgId)}`, payload);
        dispatch(setLoading(false));
        return response;
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const exportAppApi = async (orgId: number, appId: number, dispatch: any) => {
    dispatch(setLoading(true));
    try {
        const res = await Axios.get(`${API_DOMAIN}${API_APPURL.export_app_id(orgId, appId)}`);
        console.log(res);

        const response = await Axios.get(`${API_DOMAIN}${API_APPURL.export_app_id(orgId, appId)}`, { responseType: 'blob' });
        dispatch(setLoading(false));
        if (response) {
            const url = window.URL.createObjectURL(response.data);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            // the filename you want
            a.download = `${res.data.organization.name}_${res.data.app.name}`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            alert("your file has downloaded!");

        }
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}
