import PagesTemplate from '../components/others/pages-template';
import MenuProfileRolesTable from '../components/page-menu-profile-role/menu-profile-roles-table';

const MenuProfileRoles: React.FC = () => {
  return (
        <PagesTemplate>
          <MenuProfileRolesTable />
        </PagesTemplate>
  )
}

export default MenuProfileRoles;