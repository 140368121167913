import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, Stack, Pagination } from '@mui/material';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';

import ActionButton from '../others/actionbutton';
import DeleteModal from '../others/modals/delete-modal';
import { RoutesUrl } from '../../enum/routes-url';
import { getAllUsersApi } from '../../config/api';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../others/loading.component';
import { deleteUsersApi } from '../../config/api/api-configs/users-api-config';

const UsersTable: React.FC = () => {

    const [users, setUsers] = React.useState([]);
    const [open, setOpen] = React.useState<boolean>(false);
    const [deleteId, setDeleteId] = React.useState();
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const navigate: NavigateFunction = useNavigate();
    const { orgId, id } = useParams();
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state: any) => state.tasks);

    React.useEffect(() => {
        (async () => {
            const data = await getAllUsersApi(orgId, id, dispatch);
            console.log(data);
            
            if (data) {
                let sortData = data?.sort((data1: any, data2: any) => (data1.id > data2.id) ? 1 : (data1.id < data2.id) ? -1 : 0);
                setUsers(sortData);
            }
        })();
    }, [])

    const handleDelete = async () => {
        await deleteUsersApi(orgId, id, deleteId, dispatch, setUsers,users,rowsPerPage,setPage);
        setOpen(false);
    };

    const handleOpen = (id: any) => {
        setDeleteId(id);
        setOpen(true);
    };

    const handleView = (usersId: number) => {
        navigate(`${usersId}`, { state: { action: "view", showMenu: true, formName: "Users_", setFormName: "Users_Details" } });
    };

    const handleEdit = (usersId: number) => {
        navigate(`${usersId}`, { state: { action: "update", showMenu: true, formName: "Users_", setFormName: "Users_Update" } });
    };

    const handleCreate = () => {
        navigate(`${RoutesUrl.CREATE}`, { state: { action: "create", showMenu: true, formName: "Users_", setFormName: "Users_Create" } });
    }

    const handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    if (isLoading) {
        return <Loading />
    }

    return (
        <Box>
            <Stack
                direction='row'
                alignItems='center'
                justifyContent='flex-end'
                my={2}
            >
                <Button variant='contained' color='success' onClick={handleCreate}>Add User</Button>
            </Stack>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" sx={{ fontWeight: 700 }}>ID</TableCell>
                            <TableCell align="center" sx={{ fontWeight: 700 }}>NAME</TableCell>
                            <TableCell align="center" sx={{ fontWeight: 700 }}>EMAIL</TableCell>
                            <TableCell align="center" sx={{ fontWeight: 700 }}>ROLE</TableCell>
                            <TableCell align="center" sx={{ fontWeight: 700 }}>ACTIONS</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            users?.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((usersList: any, index: number) => (
                                <TableRow key={index}>
                                    <TableCell align='center'>{usersList.id}</TableCell>
                                    <TableCell align='center'>{usersList.name}</TableCell>
                                    <TableCell align='center'>{usersList.email}</TableCell>
                                    <TableCell align='center'>{usersList.role_id.name}</TableCell>
                                    <TableCell align='center'>
                                        <ActionButton
                                            handleView={() => handleView(usersList.id)}
                                            handleOpen={() => handleOpen(usersList.id)}
                                            handleEdit={() => handleEdit(usersList.id)}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
                <DeleteModal handleDelete={handleDelete} open={open} setOpen={setOpen} deleteId={deleteId} />
            </TableContainer>
            <Stack marginY={2} alignItems='center' direction='row' flexDirection='column'>
                <Pagination count={Math.ceil(users?.length / rowsPerPage)} page={page} onChange={handlePagination} color='primary' />
            </Stack>
        </Box>
    )
}

export default UsersTable