import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ProtectionRoute, PublicRoute } from "./utils/privateroutes";
import Loginpage from "./pages/loginpage.page";
import Orgspage from "./pages/orgs.page";
import Home from './pages/home.page';
import Appspage from './pages/apps.page';
import AppsForm from './components/page-apps/app-form';
import OrgsForm from './components/page-orgs/orgs-form';
import RolesPage from './pages/roles.page';
import RulesPage from './pages/rules.page';
import FieldsPage from './pages/fields.page';
import EntitiesPage from './pages/entities.page';
import UsersPage from './pages/users.page';
import RolesForm from './components/page-roles/roles-form';
import UsersForm from './components/page-users/users-forms';
import FieldForm from './components/page-fields/fields-form';
import RulesForm from './components/page-rules/rules-form';
import EntitiesForm from './components/page-entities/entities-form';
import AdminsForm from './components/page-admins-appmanager/admins-form';
import AdminsPage from './pages/admins.page';
import AdminsRolesPage from './pages/adminsroles.page';
import AdminRolesForm from './components/page-admins-roles/admins_roles_form';
import PermissionAppManager from './pages/permissionsappmanager';
import PermissionsForm from './components/page-permission-app-manager/permissions-form';
import OrgInnerApp from './components/page-orgs/org-app-table';
import EntityFieldMapping from './pages/entityfieldmapping';
import EntityFieldMappingForm from './components/page-entity-field-mapping/entity-field-mapping-form';
import MenuProfile from './pages/menuprofile';
import MenuProfileForm from './components/page-menu-profile/menu-profile-form';
import RelationForm from './components/page-relation/relation-form';
import RelationPage from './pages/relation.page';
import MenuProfileRoles from './pages/menuprofileroles';
import MenuProfileRolesForm from './components/page-menu-profile-role/menu-profile-roles-form';
import CategoryComponent from './pages/category.page';
import CategoryForm from './components/page-category/category-form';
import MenuProfileDetails from './pages/menuprofiledetails';
import MenuProfileDetailsForm from './components/page-menu-profile-details/menu-profile-details-form';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ProtectionRoute />}>
          <Route path="/" element={<Home />} >
            <Route index element={<Appspage />} />
            <Route path="apps" element={<Appspage />} />
            <Route path=":orgId/apps/create" element={<AppsForm />} />
            <Route path=':orgId/apps/:id' element={<AppsForm />} />
            <Route path=':orgId/apps/:id/permissions' element={<PermissionAppManager />} />
            <Route path=':orgId/apps/:id/permissions/:userId' element={<PermissionsForm />} />
            <Route path=':orgId/apps/:id/roles' element={<RolesPage />} />
            <Route path=':orgId/apps/:id/roles/create' element={<RolesForm />} />
            <Route path=':orgId/apps/:id/roles/:rolesId' element={<RolesForm />} />
            <Route path=':orgId/apps/:id/users' element={<UsersPage />} />
            <Route path=':orgId/apps/:id/users/create' element={<UsersForm />} />
            <Route path=':orgId/apps/:id/users/:usersId' element={<UsersForm />} />
            <Route path=':orgId/apps/:id/field-types' element={<FieldsPage />} />
            <Route path=':orgId/apps/:id/field-types/create' element={<FieldForm />} />
            <Route path=':orgId/apps/:id/field-types/:fieldsId' element={<FieldForm />} />
            <Route path=':orgId/apps/:id/entities' element={<EntitiesPage />} />
            <Route path=':orgId/apps/:id/entities/create' element={<EntitiesForm />} />
            <Route path=':orgId/apps/:id/entities/:entityId' element={<EntitiesForm />} />
            <Route path=':orgId/apps/:id/entities/:entityId/entity-field-mapping' element={<EntityFieldMapping />} />
            <Route path=':orgId/apps/:id/entities/:entityId/entity-field-mapping/create' element={<EntityFieldMappingForm />} />
            <Route path=':orgId/apps/:id/entities/:entityId/entity-field-mapping/:fieldId' element={<EntityFieldMappingForm />} />
            <Route path=':orgId/apps/:id/rules' element={<RulesPage />} />
            <Route path=':orgId/apps/:id/rules/create' element={<RulesForm />} />
            <Route path=':orgId/apps/:id/rules/:rulesId' element={<RulesForm />} />
            <Route path=':orgId/apps/:id/menu-profile' element={<MenuProfile />} />
            <Route path=':orgId/apps/:id/menu-profile/:menuProfileId' element={<MenuProfileForm />} />
            <Route path=':orgId/apps/:id/menu-profile/create' element={<MenuProfileForm />} />
            <Route path=':orgId/apps/:id/menu-profile-roles' element={<MenuProfileRoles />} />
            <Route path=':orgId/apps/:id/menu-profile-roles/:menuProfileRolesId' element={<MenuProfileRolesForm />} />
            <Route path=':orgId/apps/:id/menu-profile-roles/create' element={<MenuProfileRolesForm />} />

            <Route path=':orgId/apps/:id/menu-profile-details' element={<MenuProfileDetails />} />
            <Route path=':orgId/apps/:id/menu-profile-details/:menuProfileDetailsId' element={<MenuProfileDetailsForm />} />
            <Route path=':orgId/apps/:id/menu-profile-details/create' element={<MenuProfileDetailsForm />} />
            
            <Route path=':orgId/apps/:id/category' element={<CategoryComponent />} />
            <Route path=':orgId/apps/:id/category/:categoryId' element={<CategoryForm />} />
            <Route path=':orgId/apps/:id/category/create' element={<CategoryForm />} />
            <Route path=':orgId/apps/:id/relations' element={<RelationPage />} />
            <Route path=':orgId/apps/:id/relations/:relationId' element={<RelationForm />} />
            <Route path=':orgId/apps/:id/relations/create' element={<RelationForm />} />

            <Route path="orgs" element={<Orgspage />} >
              <Route path='create' element={<OrgsForm />} />
              <Route path=':id' element={<OrgsForm />} />
              <Route path=':id/apps' element={<OrgInnerApp />} />
            </Route>
            <Route path='admins' element={<AdminsPage />} >
              <Route path=':adminsId' element={<AdminsForm />} />
              <Route path='create' element={<AdminsForm />} />
            </Route>
            <Route path='admins-roles' element={<AdminsRolesPage />} >
              <Route path=':adminsRolesId' element={<AdminRolesForm />} />
              <Route path='create' element={<AdminRolesForm />} />
            </Route>
            {/* <Route path='permissions' element={<PermissionAppManager />} >
              <Route path=':appId/:userId' element={<PermissionsForm />} />
              <Route path='create' element={<PermissionsForm />} />
            </Route> */}
          </Route>
        </Route>
        <Route path="/login" element={<PublicRoute />}>
          <Route path="/login" element={<Loginpage />} />
        </Route>
        <Route path="*" element={<div>Page Not Found!</div>} />
      </Routes>
    </Router>
  );
}

export default App;



{/* {['/",/apps"].map((path, index) =>
              <Route path={path} element={<Apps />} key={index} />
            )} */}