import PagesTemplate from '../components/others/pages-template';
import { Outlet, useLocation } from 'react-router-dom';
import { RoutesUrl } from '../enum/routes-url';
import AdminRolesTable from '../components/page-admins-roles/admins_roles_table';
import PermissionsTable from '../components/page-permission-app-manager/permissions-table';

const PermissionAppManager: React.FC = () => {

    const location = useLocation();  

  return (
    <PagesTemplate>
        <PermissionsTable />
      </PagesTemplate>
  )
}

export default PermissionAppManager