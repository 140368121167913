import PagesTemplate from '../components/others/pages-template'
import OrgTable from '../components/page-orgs/org-table'
import { Outlet, useLocation } from 'react-router-dom';

const Orgspage: React.FC = () => {

  const location = useLocation();  
  
  return (
      <PagesTemplate>
        {
          location.pathname === '/orgs' ? <OrgTable /> : <Outlet />
        }
      </PagesTemplate>
  )
}

export default Orgspage