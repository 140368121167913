import PagesTemplate from '../components/others/pages-template';
import AppTable from '../components/page-apps/app-table';
import { Outlet, useLocation } from 'react-router-dom';
import { RoutesUrl } from '../enum/routes-url';
import React, { Suspense } from 'react';
import Loading from '../components/others/loading.component';



const Appspage: React.FC = () => {

  const location = useLocation();
  // const AppPage = React.lazy(() => import("../components/others/loading.component")) as any;

  return (
    <PagesTemplate>
      {
        (location.pathname === `/${RoutesUrl.APPS}`) || (location.pathname === `${RoutesUrl.HOME}`) ? <AppTable /> : <Outlet />
      }
    </PagesTemplate>
  )
}


export default Appspage