import { setLoading, showErrorModal } from "../../../store/slices/taskslice";
import Axios, { API_DOMAIN, API_PATH } from "../../axios-config";

const ENTITIES_API = {
    post_enitity_api : (orgId: number, appId: number)=>`${orgId}/${appId}/${API_PATH.entityMetaData}`,
    get_all_enitity_api : (orgId: number, appId: number)=>`${orgId}/${appId}/${API_PATH.entityMetaData}`,
    get_enitity_api : (orgId: number, appId: number, enitiyId: number)=>`${orgId}/${appId}/${API_PATH.entityMetaData}/${enitiyId}`,
    put_enitity_api : (orgId: number, appId: number, enitiyId: number)=>`${orgId}/${appId}/${API_PATH.entityMetaData}/${enitiyId}`,
    delete_enitity_api : (orgId: number, appId: number, enitiyId: number)=>`${orgId}/${appId}/${API_PATH.entityMetaData}/${enitiyId}`,
    enitity_field_mapping_api : (orgId: number, appId: number)=>`${orgId}/${appId}/${API_PATH.entityFieldMapping}`,
}

export const entityFieldMapping = async (orgId: any,appId: any,dispatch: any,payload: any)=>{
    dispatch(setLoading(true));
    try {
        const response = await Axios.post(`${API_DOMAIN}${ENTITIES_API.enitity_field_mapping_api(orgId,appId)}`,payload)
        dispatch(setLoading(false));
        return response?.data;
    }
    catch (error: any){
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const getAllEntityApi = async (orgId: any,appId: any,dispatch: any)=>{
    dispatch(setLoading(true));
    try {
        const response = await Axios.get(`${API_DOMAIN}${ENTITIES_API.get_all_enitity_api(orgId,appId)}`)
        dispatch(setLoading(false));
        return response?.data;
    }
    catch (error: any){
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const getEntitysApi = async (orgId: any,appId: any,fieldId: any,dispatch: any)=>{
    dispatch(setLoading(true));
    try {
        const response = await Axios.get(`${API_DOMAIN}${ENTITIES_API.get_enitity_api(orgId,appId,fieldId)}`)
        dispatch(setLoading(false));
        return response?.data;
    }
    catch (error: any){
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const putEntityApi = async (orgId: any,appId: any,rolesId: any,payload: any,dispatch: any)=>{
    dispatch(setLoading(true));
    try {
        const response = await Axios.put(`${API_DOMAIN}${ENTITIES_API.put_enitity_api(orgId,appId,rolesId)}`,payload)
        dispatch(setLoading(false));
        return response;
    }
    catch (error: any){
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const postEntityApi = async (orgId: any,appId: any,payload: any,dispatch: any)=>{
    dispatch(setLoading(true));
    try {
        const response = await Axios.post(`${API_DOMAIN}${ENTITIES_API.post_enitity_api(orgId,appId)}`,payload)
        dispatch(setLoading(false));
        return response;
    }
    catch (error: any){
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const deleteEntityApi = async (orgId: any,appId: any,entityId: any,dispatch: any,setEntities: any,entities: any,rowsPerPage: number,setPage: any)=>{
    dispatch(setLoading(true));
    try {
        const response = await Axios.delete(`${API_DOMAIN}${ENTITIES_API.delete_enitity_api(orgId,appId,entityId)}`)
        const data = await getAllEntityApi(orgId, appId, dispatch);
        if(data){
            if(entities?.length > rowsPerPage){
                ((entities?.length - 1)) % rowsPerPage === 0 ? setPage((prev: number) => prev - 1) : setPage((prev: number) => prev);
             }
            setEntities(data);
        }
        dispatch(setLoading(false));
        return response;
    }
    catch (error: any){
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}