import { setLoading, showErrorModal } from "../../../store/slices/taskslice";
import Axios, { API_DOMAIN, API_PATH } from "../../axios-config";

export const ORG_API = {
    post_org_api: `${API_DOMAIN}${API_PATH.organizations}`,
    get_all_org_api: ()=>`${API_DOMAIN}${API_PATH.organizations}`,
    get_org_api: `${API_DOMAIN}${API_PATH.organizations}/`,
    put_org_api: `${API_DOMAIN}${API_PATH.organizations}/`,
    get_individual_app : (orgId: number)=>`${API_DOMAIN}${orgId}/apps`    
}

//get orgs to display on cards
export const getOrgsIdAppApi = async (id: any,dispatch: any)=>{   
    dispatch(setLoading(true)); 
    try {
        const response = await Axios.get(`${ORG_API.get_individual_app}`); 
        dispatch(setLoading(false)); 
        return response?.data;
    }catch(error: any){
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const postOrgsApi = async (payload: any,dispatch: any) => {
    dispatch(setLoading(true));    
    try{
        const response = await Axios.post(ORG_API.post_org_api, payload);
        dispatch(setLoading(false));    
        return response;
    }catch(error: any){
        dispatch(setLoading(false));    
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const getAllOrgsApi = async (dispatch: any)=>{
    dispatch(setLoading(true));
    try{
        const response = await Axios.get(ORG_API.get_all_org_api());
        dispatch(setLoading(false));
        return response?.data;
    }catch(error: any){
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }   
}

export const getOrgsApi = async (id: any,dispatch: any)=>{   
    dispatch(setLoading(true)); 
    try {
        const response = await Axios.get(`${ORG_API.get_org_api}${id}`); 
        dispatch(setLoading(false)); 
        return response?.data;
    }catch(error: any){
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const putOrgsApi = async (id: any, payload: any,dispatch: any)=>{   
    dispatch(setLoading(true)); 
    try {
        const response = await Axios.put(`${ORG_API.put_org_api}${id}`, payload);
        dispatch(setLoading(false));
        return response;
    }catch(error: any){
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}