import { setLoading, showErrorModal } from "../../../store/slices/taskslice";
import Axios, { API_DOMAIN, API_PATH } from "../../axios-config";

export const ADMIN_API = {
    get_my_admin_api: `${API_DOMAIN}${API_PATH.usersURL}`,
    get_all_admin_api: `${API_DOMAIN}${API_PATH.admins}`,
    get_adminId_api: (adminId: number) => `${API_DOMAIN}${API_PATH.admins}/${adminId}`,
    put_admin_api: (adminId: number) => `${API_DOMAIN}${API_PATH.admins}/${adminId}`,
    post_admin_api: `${API_DOMAIN}${API_PATH.admins}`,
    delete_admin_api: (adminId: number) => `${API_DOMAIN}${API_PATH.admins}/${adminId}`
}

export const getMyAdminApi = async ({ dispatch }: any) => {
    try {
        dispatch(setLoading(true));
        const response = await Axios.get(ADMIN_API.get_my_admin_api);
        dispatch(setLoading(false));
        return response?.data;
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const getAllAdminApi = async (dispatch: any) => {
    dispatch(setLoading(true));
    try {
        const response = await Axios.get(`${ADMIN_API.get_all_admin_api}`);
        dispatch(setLoading(false));
        return response?.data;
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const getAdminIdApi = async (dispatch: any) => {
    dispatch(setLoading(true));
    try {
        const response = await Axios.get(`${ADMIN_API.get_adminId_api}`);
        dispatch(setLoading(false));
        return response?.data;
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const putAdminApi = async (dispatch: any) => {
    dispatch(setLoading(true));
    try {
        const response = await Axios.put(`${ADMIN_API.put_admin_api}`);
        dispatch(setLoading(false));
        return response?.data;
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const postAdminApi = async (dispatch: any) => {
    dispatch(setLoading(true));
    try {
        const response = await Axios.post(`${ADMIN_API.post_admin_api}`);
        dispatch(setLoading(false));
        return response?.data;
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const deleteAdminApi = async (dispatch: any) => {
    dispatch(setLoading(true));
    try {
        const response = await Axios.delete(`${ADMIN_API.delete_admin_api}`);
        dispatch(setLoading(false));
        return response?.data;
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}