import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import styles from '../styles/loginpage.module.scss';
import 'react-toastify/dist/ReactToastify.css';
import Axios, { API_PATH } from '../config/axios-config';
// import { API_PATH } from '../config/api/apiconfig';
import { ACCESS_TOKEN, REFRESH_TOKEN } from '../constant/constant';
import { setStorage } from '../utils/function';
import { getAdminByEmail_AppManagerApi } from '../config/api/api-configs/admins-app-manager-config';
import { useDispatch } from 'react-redux';

const Loginpage: React.FC = () => {

  const [user, setUser] = React.useState<IUser>({
    email: "",
    otp: ""
  });
  const [otpField, setOtpField] = React.useState<boolean>(false);
  const [signBtnDisabled, setSignInBtnDisabled] = React.useState<boolean>(false);
  const [seconds, setSeconds] = React.useState<number>(0);
  const dispatch = useDispatch();

  const navigate: NavigateFunction = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000)
    return () => {
      clearInterval(interval);
    }
  }, [seconds])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUser({ ...user, [e.target.name]: e.target.value } as { [K in keyof IUser]: IUser[K] });
    if (user.otp?.length === 6) {
      setSignInBtnDisabled(false);
    }
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSignInBtnDisabled(true);
    let obj = Object.assign({}, user)
    if (!obj.otp) {
      delete obj.otp;
    }
    setOtpField(true);
    if (obj.email === "") {
      toast.error('Email must be entered!', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    } else {
      // const response = await getAdminByEmail_AppManagerApi(user.email,dispatch)
      // console.log(response);

      setSeconds(30);
      try {
        const response = await Axios.post(API_PATH.requestOtp, obj);
        console.log(response.data);
      } catch (error: any) {
        if (error?.response?.status === 404) {
          setSeconds(30);
        }
        toast.error('Please Enter valid Email!', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        console.log(error);
      }
    }
  }

  const handleToken = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (user.otp) {
      const otpPattern = /^[A-Za-z0-9]{6}$/;
      const result = otpPattern.test(user.otp);
      if (result) {
        try {
          const res = await Axios.post(API_PATH.otpGenerator, user)
          setStorage(ACCESS_TOKEN, res.data.accessToken);
          setStorage(REFRESH_TOKEN, res.data.refreshToken);
          navigate('/');
        } catch (error) {
          toast.error('Enter valid OTP!', {
            position: toast.POSITION.BOTTOM_RIGHT
          });
        };
      } else {
        toast.error('Enter valid OTP!', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }
    };
  }

  const handleResendOtp = async () => {
    setSeconds(30);
    const obj = Object.assign({}, user);
    delete obj.otp
    try {
      const response = await Axios.post(API_PATH.requestOtp, obj);
      console.log(response.data);
    } catch (error) {
      toast.error('Please Check the Email!', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
  }

  return (
    <Box className={styles.login_page}>
      <form onSubmit={otpField ? (e) => { handleToken(e) } : (e) => handleSubmit(e)}>
        <Stack sx={{ maxWidth: '45%', textAlign: 'center', margin: '0 auto' }} >
          <img src='../../../logo512.png' alt='logo' />
        </Stack>
        <Stack marginBottom={'10px'}>
          <Typography variant='h1' sx={{ textAlign: 'left', color: '#566a7f', fontSize: '1.25rem', marginBottom: '15px', fontWeight: '500' }}>Welcome!</Typography>
          <Typography variant='h1' sx={{ textAlign: 'left', color: '#566a7f', fontSize: '1rem', fontWeight: 400 }}>Please sign-in to your account</Typography>
        </Stack>
        <Stack sx={{marginBottom: otpField ? '0' : '15px'}}>
          <Typography variant='h2'>Email</Typography>
          <input type='email' placeholder='Enter Your Email' disabled={otpField} name='email' value={user.email} onChange={handleChange} />
        </Stack>
        <Stack>
          {otpField && <Stack marginTop={'5px'}>
            <Typography variant='h2'>OTP</Typography>
            <input type='text' placeholder='Enter Your OTP' name='otp' value={user.otp} onChange={handleChange} />

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              my={2}
            >
              <p>Time Remaining {seconds}</p>
              <button onClick={handleResendOtp} disabled={seconds === 0 ? false : true} className={seconds !== 0 ? styles.otp_btn_disable : styles.otp_btn}>Resend otp</button>
            </Stack>
          </Stack>
          }
          {
            otpField ? <Button variant="contained" className={user.otp?.length === 6 ? styles.signin_btn : styles.signin_btn_disabled} disabled={user.otp?.length === 6 ? false : true} type='submit'>sign In</Button>
              : <Button variant="contained" className={signBtnDisabled ? styles.signin_btn_disabled : styles.signin_btn} type='submit'>sign In</Button>
          }

        </Stack>
      </form>
      <ToastContainer />
    </Box>
  )
}

type IUser = {
  email: string;
  otp?: string;
}

export default Loginpage