import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { Button, FormLabel, Stack, TextField, Typography, FormHelperText } from '@mui/material';
import { NavigateFunction, useLocation, useNavigate, useParams } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { RoutesUrl } from '../../enum/routes-url';
import { getAllEntityApi, getRulesApi, postRulesApi, putRulesApi } from '../../config/api';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import ErrorModal from '../others/modals/error-modal';
import Loading from '../others/loading.component';
import CardComponent from '../others/modals/card-component';

const RulesForm: React.FC = () => {

    const { orgId, id, rulesId } = useParams();

    const [state, setState] = React.useState<any>({
        org_id: orgId,
        app_id: id,
        entity_metadata_id: "",
        name: "",
        description: "",
        script: "",
        script_type: "",
        rule_type: "",
        trigger: "",
        cron_job_schedule: "",
        scope: "",
    });
    const [locationState, setLoactionState] = React.useState<"create" | "view" | "update">();
    const [disabled, setDisabled] = React.useState<boolean>(false);
    const [updatedisabled, setUpdateDisabled] = React.useState<boolean>(false);
    const [helperText, setHelperText] = React.useState<any>({
        entity_metadata_id: "",
        name: "",
        description: "",
        script: "",
        script_type: "",
        rules_type: "",
        trigger: "",
        cron_job_schedule: "",
        scope: "",
    });
    const [open, setOpen] = React.useState(false);
    const [getEntityId, setGetEntityId] = React.useState([]);

    const navigate: NavigateFunction = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const handleClose = () => setOpen(false);
    const { isLoading } = useSelector((state: any) => state.tasks);
    console.log(location);

    useEffect(() => {
        (async () => {
            const data = await getAllEntityApi(orgId, id, dispatch);
            if (data) setGetEntityId(data);
        })();
    }, []);

    useEffect(() => {
        setLoactionState(location.state?.action);
        if (location.state?.action === `${RoutesUrl.VIEW}`) {
            setDisabled(true);
            (async () => {
                const data = await getRulesApi(orgId, id, location.state.metadata_id.id, rulesId, dispatch);
                if (data) setState(data);
            })();
        }

        if (location.state?.action === `${RoutesUrl.UPDATE}`) {
            (async () => {
                const data = await getRulesApi(orgId, id, location.state.metadata_id.id, rulesId, dispatch);
                console.log(data);

                if (data) setState(data);
            })();
        }
    }, []);

    const handleNavigate = () => {
        navigate(-1);
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (locationState === `${RoutesUrl.CREATE}`) {
            handleValidate();
        }
        else if (locationState === `${RoutesUrl.UPDATE}`) {
            handleValidate();
        }
    };

    const handleValidate = async () => {

        const error = {} as any
        if (!state.name) {
            error.name = "Name field is required"
        } else if (!(state.name.trim().length > 0)) {
            error.name = "Name should not contain empty space";
        }  else if (!/^[a-zA-Z0-9_]*$/.test(state.name)) {
            error.name = "System Name should not contain special character except _";
        }
        else {
            error.name = ""
        }
        if (!state.description) {
            delete state.description
        }
        if (!state.entity_metadata_id) {
            error.entity_metadata_id = "Entity MetaData Id field is required"
        } else {
            error.entity_metadata_id = ""
        }
        if (!state.rule_type) {
            error.rule_type = "Rule Type field is required"
        } else {
            error.rule_type = ""
        }
        if (!state.script) {
            error.script = "Script field is required"
        } else if (!(state.script.trim().length > 0)) {
            error.script = "Script should not contain empty space";
        } else {
            error.script = ""
        }
        if (!state.script_type) {
            error.script_type = "Script Type field is required"
        } else {
            error.script_type = ""
        }
        if (!state.trigger) {
            error.trigger = "Trigger field is required"
        } else {
            error.trigger = ""
        }
        if (state.cron_job_schedule) {
            if (!(state.cron_job_schedule.trim().length > 0)) {
                error.cron_job_schedule = "Cron Job Schedule should not contain empty space";
            }
        } else {
            delete state.cron_job_schedule
        }
        if (!state.scope) {
            error.scope = "Scope field is required"
        } else {
            error.scope = ""
        }
        console.log(error);
        
        if (!error.name && !error.entity_metadata_id && !error.entity && !error.rule_type && !error.script_type && !error.trigger) {
            console.log(error);
            
            if (locationState === `${RoutesUrl.CREATE}`) {
                const obj = Object.assign({}, state);
                if (obj.app_id) {
                    delete obj.app_id;
                }
                if (obj.org_id) {
                    delete obj.org_id;
                }
                if (obj.entity_metadata_id) {
                    delete obj.entity_metadata_id;
                }
                const postRules = await postRulesApi(orgId, id, state.entity_metadata_id, obj, dispatch);
                if (postRules) {
                    setState({
                        org_id: orgId,
                        app_id: id,
                        entity_metadata_id: "",
                        name: "",
                        description: "",
                        script: "",
                        script_type: "",
                        rule_type: "",
                        trigger: "",
                        cron_job_schedule: "",
                        scope: "",
                    });
                    toast.success('Created successfully', {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                } else {
                    setOpen(true);
                }
            }
            if (locationState === `${RoutesUrl.UPDATE}`) {
                const obj = Object.assign({}, state)
                if (obj.app_id) {
                    delete obj.app_id;
                }
                if (obj.org_id) {
                    delete obj.org_id;
                }
                if (obj.entity_metadata_id) {
                    delete obj.entity_metadata_id;
                }
                if(obj.id){
                    delete obj.id;
                }
                if (obj.emp_id === null || obj.emp_id) {
                    delete obj.emp_id
                }
                console.log(obj);

                const updateRules = await putRulesApi(orgId, id, state.entity_metadata_id, rulesId, obj, dispatch);
                if (updateRules) {
                    toast.success('Orgsform Successfully Updated', {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                } else {
                    setOpen(true);
                }
            }
        }
        setHelperText({ ...helperText, ...error });
    }

    const handleChange = (e: any) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        });
        setUpdateDisabled(true);
    };

    if (isLoading) {
        return <Loading />
    }

    if (location.state.action === `${RoutesUrl.VIEW}`) {
        return (
            <Stack direction='row' flexDirection='column' alignItems='center'>
                <Typography variant='h2'>{location.state.setFormName}</Typography>
                <CardComponent state={state} />
            </Stack>
        )
    }

    return (
        <Box sx={{ width: '100ch', margin: '2ch auto' }}>
            <Typography variant='h2' textAlign='center'>{location.state.setFormName}</Typography>
            <form onSubmit={(e) => { handleSubmit(e) }}>
                <Stack>
                    <FormLabel sx={{ marginBottom: '0.5ch' }}>Name</FormLabel>
                    <TextField
                        type='text'
                        size='small'
                        name='name'
                        disabled={disabled}
                        value={state.name}
                        onChange={handleChange}
                    />
                </Stack>
                <FormHelperText id="outlined-weight-helper-text" error>{helperText.name || " "}</FormHelperText>
                <Stack>
                    <FormLabel sx={{ marginBottom: '0.5ch' }}>Description</FormLabel>
                    <TextField
                        type='text'
                        size='small'
                        name='description'
                        disabled={disabled}
                        value={state.description || ""}
                        onChange={handleChange}
                    />
                </Stack>
                <FormHelperText id="outlined-weight-helper-text" error>{helperText.description || " "}</FormHelperText>
                <Stack>
                    <FormLabel sx={{ marginBottom: '0.5ch' }}>Entity MetaData ID</FormLabel>
                    <FormControl>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={state.entity_metadata_id}
                            size='small'
                            name="entity_metadata_id"
                            disabled={disabled}
                            onChange={handleChange}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: 150
                                    }
                                }
                            }}
                        >
                            {
                                getEntityId.map((entity: any, index: any) => (
                                    <MenuItem key={index} value={entity.id}>{entity.name}</MenuItem>

                                ))
                            }
                        </Select>
                    </FormControl>
                </Stack>
                <FormHelperText id="outlined-weight-helper-text" error>{helperText.entity_metadata_id || " "}</FormHelperText>
                <Stack>
                    <FormLabel sx={{ marginBottom: '0.5ch' }}>Script Type</FormLabel>
                    <FormControl fullWidth>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={state.script_type}
                            size='small'
                            name="script_type"
                            disabled={disabled}
                            onChange={handleChange}
                        >
                            <MenuItem value='js'>Js</MenuItem>
                            <MenuItem value='stored_proc'>Stored_Proc</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>
                <FormHelperText id="outlined-weight-helper-text" error>{helperText.script_type || " "}</FormHelperText>
                <Stack>
                    <FormLabel sx={{ marginBottom: '0.5ch' }}>Rule Type</FormLabel>
                    <FormControl fullWidth>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={state.rule_type}
                            size='small'
                            name="rule_type"
                            disabled={disabled}
                            onChange={handleChange}
                        >
                            <MenuItem value='validation'>Validation</MenuItem>
                            <MenuItem value='other'>Other</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>
                <FormHelperText id="outlined-weight-helper-text" error>{helperText.rule_type || " "}</FormHelperText>
                <Stack>
                    <FormLabel sx={{ marginBottom: '0.5ch' }}>Script</FormLabel>
                    <TextField
                        type='text'
                        size='small'
                        name='script'
                        disabled={disabled}
                        value={state.script}
                        onChange={handleChange}
                    />
                </Stack>
                <FormHelperText id="outlined-weight-helper-text" error>{helperText.script || " "}</FormHelperText>
                <Stack>
                    <FormLabel sx={{ marginBottom: '0.5ch' }}>Trigger</FormLabel>
                    <FormControl fullWidth>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={state.trigger}
                            size='small'
                            name="trigger"
                            disabled={disabled}
                            onChange={handleChange}
                        >
                            <MenuItem value='before_create'>Before Create</MenuItem>
                            <MenuItem value='after_create'>After Create</MenuItem>
                            <MenuItem value='before_update'>Before Update</MenuItem>
                            <MenuItem value='after_update'>After Update</MenuItem>
                            <MenuItem value='before_delete'>Before Delete</MenuItem>
                            <MenuItem value='After_delete'>After Delete</MenuItem>
                            <MenuItem value='before_read_one'>Before Read One</MenuItem>
                            <MenuItem value='after_read_one'>After Read One</MenuItem>
                            <MenuItem value='before_read_many'>Before Read Many</MenuItem>
                            <MenuItem value='after_read_many'>After Read Many</MenuItem>
                            <MenuItem value='before_logout'>Before Logout</MenuItem>
                            <MenuItem value='after_login'>After Login</MenuItem>
                            <MenuItem value='schedule'>Schedule</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>
                <FormHelperText id="outlined-weight-helper-text" error>{helperText.trigger || " "}</FormHelperText>
                <Stack>
                    <FormLabel sx={{ marginBottom: '0.5ch' }}>Cron Job Schedule</FormLabel>
                    <TextField
                        type='text'
                        size='small'
                        name='cron_job_schedule'
                        disabled={disabled}
                        value={state.cron_job_schedule || ""}
                        onChange={handleChange}
                    />
                </Stack>
                <FormHelperText id="outlined-weight-helper-text" error>{helperText.cron_job_schedule || " "}</FormHelperText>
                <Stack>
                    <FormLabel sx={{ marginBottom: '0.5ch' }}>Scope</FormLabel>
                    <FormControl fullWidth>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={state.scope}
                            size='small'
                            name="scope"
                            disabled={disabled}
                            onChange={handleChange}
                        >
                            <MenuItem value='entity_single'>Entity Single</MenuItem>
                            <MenuItem value='db_read_only'>Db Read Only</MenuItem>
                            <MenuItem value='db_write'>Db Write</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>
                <FormHelperText id="outlined-weight-helper-text" error>{helperText.scope || " "}</FormHelperText>
                <Stack direction='row' justifyContent='center' alignItems='center' spacing={3}>
                    <Button
                        style={{ maxWidth: '20%' }}
                        variant='contained'
                        onClick={handleNavigate}
                    >Back
                    </Button>
                    {
                        locationState === `${RoutesUrl.VIEW}` ? null :
                            <Button variant='contained' color='success' type='submit' disabled={!updatedisabled}>
                                {locationState === `${RoutesUrl.CREATE}` ? "create" : "update"}
                            </Button>}

                </Stack>
            </form>
            <ToastContainer autoClose={1000} />
            <ErrorModal open={open} setOpen={setOpen} handleClose={handleClose} />
        </Box>
    )
}
interface State {
    org_id: string | number;
    app_id: string | number;
    entity_metaData_id: string | number;
    name: string;
    description?: string;
    script_type: string;
    rule_type: string;
    trigger: string;
    cron_job_schedule?: string;
    scope: string;
    script: string;
}

export default RulesForm