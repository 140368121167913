import PagesTemplate from '../components/others/pages-template';
import CategoryTable from '../components/page-category/category-table';

const CategoryComponent: React.FC = () => {
  return (
        <PagesTemplate>
          <CategoryTable />
        </PagesTemplate>
  )
}

export default CategoryComponent;