import React, { SetStateAction, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Link, useLocation, useParams } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import { Button, Select, SelectChangeEvent, alpha } from '@mui/material';
import { styled } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import SearchRounded from '@mui/icons-material/SearchRounded';
import MenuItem from '@mui/material/MenuItem';
import InputBase from '@mui/material/InputBase';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';
import { BiMenu } from 'react-icons/bi';
import Drawer from "@mui/material/Drawer";
import Axios from '../../config/axios-config';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import PeopleIcon from '@mui/icons-material/People';
import GradingIcon from '@mui/icons-material/Grading';
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import GavelIcon from '@mui/icons-material/Gavel';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { getAllOrgsApi, getAppApi } from '../../config/api';
import { ToastContainer } from 'react-toastify';
import ErrorModal from './modals/error-modal';
import { setLoading, showErrorModal } from '../../store/slices/taskslice';
import { RoutesUrl } from '../../enum/routes-url';
import { getOrgAppIdApi } from '../../config/api/api-configs/app-api-config';

const StyledToolbar = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: '#ecf0f1'
});

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '22ch',
    },
  },
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const Input = styled('input')({
  display: 'none',
});

type Anchor = "top" | "left" | "bottom" | "right";

const Navbar = (props: any) => {

  const [showMenu, setShowMenu] = React.useState<boolean>(true);
  const { handleSelect, dropDown, setDropDown } = props;
  const [file, setFile] = React.useState<any>();
  const [state, setState] = React.useState({
    left: false
  });
  const [systemName, setsystemName] = React.useState('');
  const [open, setOpen] = React.useState<boolean>(false);
  const [orgs, setOrgs] = useState([]);
  const [getOrgId, setGetOrgId] = useState('');
  const [apps, setApps] = useState([]);
  const [getAppId, setGetAppId] = useState('');

  const handleClose = () => setOpen(false);

  const { orgId, id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.pathname.includes('orgs')) {
      setDropDown('orgs');
    } else if (location.pathname.includes('apps')) {
      setDropDown('apps');
    } else {
      setDropDown('apps');
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.state?.showMenu) {
      (async () => {
        const data = await getAppApi(orgId, id, dispatch);
        setsystemName(data?.system_name);
      })();
    }
    setShowMenu(location.state?.showMenu);
  }, [location.state]);

  useEffect(() => {
    dispatch(setLoading(true));
    (async () => {
      const data = await getAllOrgsApi(dispatch);
      if (data) setOrgs(data);
    })();
  }, []);

  
  useEffect(() => {
    dispatch(setLoading(true));
    (async () => {
      const data = await getOrgAppIdApi(getOrgId, dispatch);
      if (data) setApps(data);
      setGetAppId('');
    })();
  }, [getOrgId]);

  const handleFileChange = async (e: any) => {

    const formData = new FormData();
    const parsedData = await readJsonFile(e.target.files[0]) as any;
    const data = JSON.stringify(parsedData);
    console.log(data);

    formData.append("file", data);
    dispatch(setLoading(true));

    Axios
      .post(`upload-spec`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      })
      .then((res: any) => {
        dispatch(setLoading(false));
        console.log(res);
        alert("File Upload success");
      })
      .catch((err: any) => {
        setOpen(true);
        dispatch(setLoading(false));
        dispatch(showErrorModal(err.response?.data.message));
        // setFile(undefined);
      });



    // if (e.target.files) {
    //   const parsedData = await readJsonFile(e.target.files[0]) as any;
    //   const data = JSON.stringify(parsedData)
    //   console.log(data); 
    //   try {
    //     const res = await Axios.post('/upload-spec', data,{
    //       headers: {
    //         'Content-Type': 'application/json'
    //         }
    //     })
    //     console.log(res);
    //   }catch (err: any){
    //     console.log(err);
    //   }
    // }

  };

  const readJsonFile = (file: Blob) =>
    new Promise((resolve, reject) => {
      const fileReader = new FileReader()

      fileReader.onload = event => {
        if (event.target) {
          resolve(JSON.parse(event.target.result as string))
        }
      }

      fileReader.onerror = error => reject(error)
      fileReader.readAsText(file)
    })

  const uploadSpec = () => {
    if (!file) {
      return;
    }

    // const formData = new FormData();
    // formData.append("name", "name");
    // formData.append("file", file);
    // console.log(formData);
    // dispatch(setLoading(true));

    // Axios
    //   .post(`/upload-spec`, formData)
    //   .then((res: any) => {
    //     dispatch(setLoading(false));
    //     console.log(res);
    //     alert("File Upload success");
    //   })
    //   .catch((err: any) => {
    //     setOpen(true);
    //     dispatch(setLoading(false));
    //     dispatch(showErrorModal(err.response?.data.message));
    //     // setFile(undefined);
    //   });
  }

  const toggleDrawer = (anchor: Anchor, open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleOrgDropdown = (event: SelectChangeEvent<string>, child: React.ReactNode) => {
    setGetOrgId(event.target.value);
    props.setOrgID(event.target.value);
  }

  const handleAppDropdown = (event: SelectChangeEvent<string>, child: React.ReactNode) => {
    setGetAppId(event.target.value);
    props.setAppID(event.target.value);
  }

  const list = (anchor: Anchor) => (
    <Box
      sx={{ padding: "1rem", cursor: "pointer", width: anchor === "top" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {
        id && <React.Fragment>
          <Link to={`${orgId}/${RoutesUrl.APPS}/${id}/${RoutesUrl.APP_ROLES}`} state={{ showMenu: true, formName: "Roles_" }}>
            <Stack direction='row' alignItems='center' gap={1} marginY={1}>
              <SupervisedUserCircleIcon style={{ color: '#566a7f' }} />
              <Typography variant='h6' color={'#566a7f'}>Roles</Typography>
            </Stack>
          </Link>
          <Link to={`${orgId}/${RoutesUrl.APPS}/${id}/${RoutesUrl.APP_USERS}`} state={{ showMenu: true, formName: "Users_" }}>
            <Stack direction='row' alignItems='center' gap={1} marginY={1}>
              <PeopleIcon style={{ color: '#566a7f' }} />
              <Typography variant='h6' color={'#566a7f'}>Users</Typography>
            </Stack>
          </Link>
          <Link to={`${orgId}/${RoutesUrl.APPS}/${id}/${RoutesUrl.APP_FIELDS}`} state={{ showMenu: true, formName: "Fields_" }}>
            <Stack direction='row' alignItems='center' gap={1} marginY={1}>
              <GradingIcon style={{ color: '#566a7f' }} />
              <Typography variant='h6' color={'#566a7f'}>Fields</Typography>
            </Stack>
          </Link>
          <Link to={`${orgId}/${RoutesUrl.APPS}/${id}/${RoutesUrl.APP_ENTITIES}`} state={{ showMenu: true, formName: "Entities_" }}>
            <Stack direction='row' alignItems='center' gap={1} marginY={1}>
              <BrandingWatermarkIcon style={{ color: '#566a7f' }} />
              <Typography variant='h6' color={'#566a7f'}>Entities</Typography>
            </Stack>
          </Link>
          <Link to={`${orgId}/${RoutesUrl.APPS}/${id}/${RoutesUrl.RELATION}`} state={{ showMenu: true, formName: "Relation_" }}>
            <Stack direction='row' alignItems='center' gap={1} marginY={1}>
              <CastForEducationIcon style={{ color: '#566a7f' }} />
              <Typography variant='h6' color={'#566a7f'}>Relation</Typography>
            </Stack>
          </Link>
          <Link to={`${orgId}/${RoutesUrl.APPS}/${id}/${RoutesUrl.APP_RULES}`} state={{ showMenu: true, formName: "Rules_" }}>
            <Stack direction='row' alignItems='center' gap={1} marginY={1}>
              <GavelIcon style={{ color: '#566a7f' }} />
              <Typography variant='h6' color={'#566a7f'}>Rules</Typography>
            </Stack>
          </Link>
          <Link to={`${orgId}/${RoutesUrl.APPS}/${id}/${RoutesUrl.MENU_PROFILE}`} state={{ showMenu: true, formName: "Menu_Profile_" }}>
            <Stack direction='row' alignItems='center' gap={1} marginY={1}>
              <AccountCircleIcon style={{ color: '#566a7f' }} />
              <Typography variant='h6' color={'#566a7f'}>Menu_Profile</Typography>
            </Stack>
          </Link>
        </React.Fragment>
      }
      <Link to='/admins'>
        <Stack direction='row' alignItems='center' gap={1} marginY={1}>
          <SupervisorAccountIcon style={{ color: '#566a7f' }} />
          <Typography variant='h6' color={'#566a7f'}>Admins</Typography>
        </Stack>
      </Link>
      <Link to='/admins-roles'>
        <Stack direction='row' alignItems='center' gap={1} marginY={1}>
          <AdminPanelSettingsIcon style={{ color: '#566a7f' }} />
          <Typography variant='h6' color={'#566a7f'}>Admins Roles</Typography>
        </Stack>
      </Link>
    </Box>
  );

  return (
    <AppBar position='sticky'>
      <StyledToolbar>
        {/* <Stack direction='row' alignItems='center' spacing={2} > */}
        {/* {(["left"] as const).map((anchor) => (
            <React.Fragment key={anchor}>
              <Button onClick={toggleDrawer(anchor, true)} color='secondary' size='large'><BiMenu size={30} /></Button>
              <Drawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
              >
                {list(anchor)}
              </Drawer>
            </React.Fragment>
          ))} */}
        {/* <img src='/logo512.png' width='20%' />
          <Typography variant='h6'
            sx={{ cursor: 'pointer' }}
          >
            <Link to='/' style={{color: '#566a7f', display: 'flex', alignItems: 'center', gap: '10px'}}>Apyrn App Private Ltd</Link>
          </Typography> */}
        {/* </Stack> */}
        {/* <Search sx={{ display: { xs: 'none', sm: 'block' } }}>
          <SearchIconWrapper>
            <SearchRounded />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search bar to switch apps"
            inputProps={{ 'aria-label': 'search' }}
          />
        </Search> */}
        <Stack display={'flex'} gap={'10px'} flexDirection={'row'}>
          <FormControl sx={{ minWidth: '60px' }}>
            <Select
              sx={{
                m: 1, minWidth: 50,
                "& .MuiInputLabel-root": {
                  display: `${getOrgId ? "none" : 'inline-block'}`,
                },
              }}
              size='small'
              color='secondary'
              onChange={handleOrgDropdown}
              value={getOrgId}
              displayEmpty
              fullWidth>
              <MenuItem value="" disabled>
                Select Organization
              </MenuItem>
              {
                orgs?.map((org: any) => {
                  return <MenuItem value={org.id}>{org.system_name}</MenuItem>
                })
              }
            </Select>
          </FormControl>
          <FormControl sx={{ minWidth: '60px' }}>
            <Select
              sx={{
                m: 1, minWidth: 50,
                "& .MuiInputLabel-root": {
                  display: `${getAppId ? "none" : 'inline-block'}`,
                },
              }}
              inputProps={{ 'aria-label': 'Without label' }}
              size='small'
              color='secondary'
              onChange={handleAppDropdown}
              value={getAppId || ""}
              displayEmpty
              fullWidth>
              <MenuItem value="" disabled>
                Select App
              </MenuItem>
              {
                apps?.map((app: any) => {
                  return <MenuItem value={app.id}>{app.name}</MenuItem>
                })
              }
            </Select>
          </FormControl>
        </Stack>
        <Stack direction='row' marginX='3' alignItems='center'>
          {
            !showMenu ?
              <Stack>
                <label htmlFor="contained-button-file">
                  <Input accept="application/JSON" id="contained-button-file" multiple type="file" onChange={handleFileChange} />
                  <Button variant="contained" color='warning' size='small' component="span">
                    Upload Spec
                  </Button>
                </label>
                {/* <Button variant='contained' color='warning' size='small' onClick={uploadSpec}>Upload</Button> */}

                {/* {
                  !file ? <label htmlFor="contained-button-file">
                    <Input accept="application/JSON" id="contained-button-file" multiple type="file" onChange={handleFileChange} />
                    <Button variant="contained" color='warning' size='small' component="span">
                      Upload Spec
                    </Button>
                  </label> :
                    <Button variant='contained' color='warning' size='small' onClick={uploadSpec}>Upload</Button>
                } */}
              </Stack>

              : ""
          }
        </Stack>
      </StyledToolbar>
      <ToastContainer autoClose={1000} />
      <ErrorModal open={open} setOpen={setOpen} handleClose={handleClose} />
    </AppBar>
  )
}

interface Props {
  handleSelect: React.Dispatch<SetStateAction<number>>;
}

export default Navbar