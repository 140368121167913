import { setLoading, showErrorModal } from "../../../store/slices/taskslice";
import Axios, { API_DOMAIN, API_PATH } from "../../axios-config";

const USERS_API = {
    post_users_api : (orgId: number, appId: number)=>`${orgId}/${appId}/${API_PATH.usersPath}`,
    get_all_users_api : (orgId: number, appId: number)=>`${orgId}/${appId}/${API_PATH.usersPath}?expandAll=true`,
    get_users_api : (orgId: number, appId: number, userId: number)=>`${orgId}/${appId}/${API_PATH.usersPath}/${userId}`,
    put_users_api : (orgId: number, appId: number, userId: number)=>`${orgId}/${appId}/${API_PATH.usersPath}/${userId}`,
    delete_users_api : (orgId: number, appId: number, userId: number)=>`${orgId}/${appId}/${API_PATH.usersPath}/${userId}`
}

export const getAllUsersApi = async (orgId: any,appId: any,dispatch: any)=>{
    dispatch(setLoading(true));
    try {
        const response = await Axios.get(`${API_DOMAIN}${USERS_API.get_all_users_api(orgId,appId)}`);
        dispatch(setLoading(false));
        return response?.data;
    }
    catch (error: any){
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const getOneUsersApi = async (orgId: any,appId: any,userId: any,dispatch: any)=>{
    dispatch(setLoading(true));
    try {
        const response = await Axios.get(`${API_DOMAIN}${USERS_API.get_users_api(orgId,appId,userId)}`)
        dispatch(setLoading(false));
        return response?.data;
    }
    catch (error: any){
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const putUsersApi = async (orgId: any,appId: any,rolesId: any,payload: any,dispatch: any)=>{
    dispatch(setLoading(true));
    try {
        const response = await Axios.put(`${API_DOMAIN}${USERS_API.put_users_api(orgId,appId,rolesId)}`,payload)
        dispatch(setLoading(false));
        return response;
    }
    catch (error: any){
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const postUsersApi = async (orgId: any,appId: any,payload: any,dispatch: any)=>{
    dispatch(setLoading(true));
    try {
        const response = await Axios.post(`${API_DOMAIN}${USERS_API.post_users_api(orgId,appId)}`,payload)
        dispatch(setLoading(false));
        return response;
    }
    catch (error: any){
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const deleteUsersApi = async (orgId: any,appId: any,userId: any,dispatch: any,setUsers: any,users: any,rowsPerPage: number,setPage: any)=>{
    dispatch(setLoading(true));
    try {
        const response = await Axios.delete(`${API_DOMAIN}${USERS_API.delete_users_api(orgId,appId,userId)}`)
        const data = await getAllUsersApi(orgId, appId, dispatch);
        if(data){
            if(users?.length > rowsPerPage){
                ((users?.length - 1)) % rowsPerPage === 0 ? setPage((prev: number) => prev - 1) : setPage((prev: number) => prev);
             }
            setUsers(data);
        }
        dispatch(setLoading(false));
        return response;
    }
    catch (error: any){
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}