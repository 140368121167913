import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, Stack, Pagination } from '@mui/material';

import ActionButton from '../others/actionbutton';
import DeleteModal from '../others/modals/delete-modal';
import { NavigateFunction, useLocation, useNavigate, useParams } from 'react-router-dom';
import { RoutesUrl } from '../../enum/routes-url';
import { getAllRolesApi } from '../../config/api';
import { useDispatch, useSelector } from 'react-redux';
import { deleteRolesApi } from '../../config/api/api-configs/roles-api-config';
import Loading from '../others/loading.component';
import { INITIAL_LIMIT } from '../../constant/constant';

const RolesTable = () => {

  const [roles, setRoles] = React.useState([]);
  const [open, setOpen] = React.useState<boolean>(false);
  const [deleteId, setDeleteId] = React.useState();
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const { orgId, id } = useParams();
  const navigate: NavigateFunction = useNavigate();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state: any) => state.tasks);
  const location = useLocation();

  React.useEffect(() => {
    (async () => {
      let data = await getAllRolesApi(orgId, id, dispatch);
      if (data) {
        data = data?.map((item: any) => ({ permission: 'Create/Read/Update/Delete', ...item }));
        let sortData = data?.sort((data1: any, data2: any) => (data1.id > data2.id) ? 1 : (data1.id < data2.id) ? -1 : 0);
        setRoles(sortData);
      }
    })();
  }, [])

  const handleDelete = async () => {
    await deleteRolesApi(orgId, id, deleteId, dispatch, setRoles,roles,rowsPerPage,setPage);
    setOpen(false);
  };

  const handleOpen = (deleteId: any) => {
    setDeleteId(deleteId);
    setOpen(true);
  };

  const handleView = (rolesId: number) => {
    navigate(`${rolesId}`, { state: { action: "view", showMenu: true, formName: "Roles_", setFormName: "Roles_Details" } });
  };

  const handleEdit = (rolesId: number) => {
    navigate(`${rolesId}`, { state: { action: "update", showMenu: true, formName: "Roles_", setFormName: "Roles_Update" } });
  };

  const handleCreate = () => {
    navigate(`${RoutesUrl.CREATE}`, { state: { action: "create", showMenu: true, formName: "Roles_", setFormName: "Roles_Create" } });
  }

  const handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  if (isLoading) {
    return <Loading />
  }

  return (
    <Box>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='flex-end'
        my={2}
      >
        <Button variant='contained' color='success' onClick={handleCreate}>Add Roles</Button>
      </Stack>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{ fontWeight: 700 }}>ID</TableCell>
              <TableCell align="center" sx={{ fontWeight: 700 }}>NAME</TableCell>
              <TableCell align="center" sx={{ fontWeight: 700 }}>PERMISSIONS</TableCell>
              <TableCell align="center" sx={{ fontWeight: 700 }}>ACTIONS</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              roles?.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((rolesList: any, index: number) => {
                return (
                  <TableRow key={index}>
                    <TableCell align='center'>{rolesList.id}</TableCell>
                    <TableCell align='center'>{rolesList.name}</TableCell>
                    <TableCell align='center'>{rolesList.permission}</TableCell>
                    <TableCell align='center'>
                      <ActionButton
                        handleView={() => handleView(rolesList.id)}
                        handleOpen={() => handleOpen(rolesList.id)}
                        handleEdit={() => handleEdit(rolesList.id)}
                        showDelete = {rolesList?.name === "admin" ? true : false}
                        showEdit = {rolesList?.name === "admin" ? true : false}
                      />
                    </TableCell>
                  </TableRow>
                )
              }
              )
            }
          </TableBody>
        </Table>
        <DeleteModal handleDelete={handleDelete} open={open} setOpen={setOpen} deleteId={deleteId} />
      </TableContainer>
      <Stack marginY={2} alignItems='center' direction='row' flexDirection='column'>
        <Pagination count={Math.ceil(roles?.length / rowsPerPage)} page={page} onChange={handlePagination} color='primary' />
      </Stack>
    </Box>
  )
}


export default RolesTable