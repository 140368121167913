import { Box, Stack, Typography } from '@mui/material';
import { FadeLoader } from 'react-spinners';

const Loading = () => {
  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        height='100vh'
      >
        <FadeLoader color="#ccc" />
      </Stack>
    </Box>

  );
}

export default Loading;