import React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowY: "scroll"
};


const ErrorModal: React.FC<Props> = (props: Props) => {

    const { open, handleClose } = props;
    const { showError } = useSelector((state: any) => state.tasks);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} >
                <Stack >
                    <Typography id="modal-modal-title" variant="h6" component="h2" textAlign='center' color='error'>
                        <Stack textAlign='center'>
                            {
                                Array.isArray(showError) ? showError.map((e, index) => {
                                    return (
                                        <Stack key={index}>{`${index + 1}. ${e}`}</Stack>
                                    )
                                }) : showError
                            }
                        </Stack>
                    </Typography>
                </Stack>
                <Stack
                    direction="row"
                    justifyContent='center'
                    spacing={4}
                    mt={4}
                    mb={2}
                >
                    <Button variant='outlined' onClick={handleClose}>ok</Button>
                </Stack>
            </Box>
        </Modal>
    )
}

interface Props {
    handleClose: () => void;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default ErrorModal
