import PagesTemplate from '../components/others/pages-template';
import RolesTable from '../components/page-roles/roles-table';

const RolesPage: React.FC = () => {
  
  return (
        <PagesTemplate>
          <RolesTable />
        </PagesTemplate>
    )
}

export default RolesPage


