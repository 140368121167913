import { setLoading, showErrorModal } from "../../../store/slices/taskslice";
import Axios, { API_DOMAIN, API_PATH } from "../../axios-config";

const ROLES_API = {
    post_roles_api : (orgId: number, appId: number)=>`${orgId}/${appId}/${API_PATH.rolesPath}`,
    get_all_roles_api : (orgId: number, appId: number)=>`${orgId}/${appId}/${API_PATH.rolesPath}`,
    get_roles_api : (orgId: number, appId: number,roleId: number)=>`${orgId}/${appId}/${API_PATH.rolesPath}/${roleId}`,
    put_roles_api : (orgId: number, appId: number,roleId: number)=>`${orgId}/${appId}/${API_PATH.rolesPath}/${roleId}`,
    delete_roles_api : (orgId: number, appId: number,roleId: number)=>`${orgId}/${appId}/${API_PATH.rolesPath}/${roleId}`,
}

export const getAllRolesApi = async (orgId: any,appId: any,dispatch: any)=>{
    dispatch(setLoading(true));
    try {
        const response = await Axios.get(`${API_DOMAIN}${ROLES_API.get_all_roles_api(orgId,appId)}`)
        dispatch(setLoading(false));
        return response?.data
    }
    catch (error: any){
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const getRolesApi = async (orgId: any,appId: any,rolesId: any,dispatch: any)=>{
    dispatch(setLoading(true));
    try {
        const response = await Axios.get(`${API_DOMAIN}${ROLES_API.get_roles_api(orgId,appId,rolesId)}`)
        dispatch(setLoading(false));
        return response?.data
    }
    catch (error: any){
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const putRolesApi = async (orgId: any,appId: any,rolesId: any,payload: any,dispatch: any)=>{
    dispatch(setLoading(true));
    try {
        const response = await Axios.put(`${API_DOMAIN}${ROLES_API.put_roles_api(orgId,appId,rolesId)}`,payload)
        dispatch(setLoading(false));
        return response;
    }
    catch (error: any){
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const postRolesApi = async (orgId: any,appId: any,payload: any,dispatch: any)=>{
    dispatch(setLoading(true));
    try {
        const response = await Axios.post(`${API_DOMAIN}${ROLES_API.post_roles_api(orgId,appId)}`,payload)
        dispatch(setLoading(false));
        return response;
    }
    catch (error: any){
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const deleteRolesApi = async (orgId: any,appId: any,rolesId: any,dispatch: any,setRoles: any,roles: any,rowsPerPage: number,setPage: any)=>{
    dispatch(setLoading(true));
    try {
        const response = await Axios.delete(`${API_DOMAIN}${ROLES_API.delete_roles_api(orgId,appId,rolesId)}`)
        const data = await getAllRolesApi(orgId, appId, dispatch);
        console.log(data);
        if(data){
                if(roles?.length > rowsPerPage){
                    ((roles?.length - 1)) % rowsPerPage === 0 ? setPage((prev: number) => prev - 1) : setPage((prev: number) => prev);
                }
                setRoles(data);
        }
        dispatch(setLoading(false));
        return response;
    }
    catch (error: any){
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}