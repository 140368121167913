import { setLoading, showErrorModal } from "../../../store/slices/taskslice";
import Axios, { API_DOMAIN, API_PATH } from "../../axios-config";
// import dataFetch from "../wrap-promise/wrappromise";

const MENU_PROFILE_API = {
    post_menu_profile_api: (orgId: number, appId: number) => `${orgId}/${appId}/${API_PATH.menuProfile}`,
    get_all_menu_profile_api: (orgId: number, appId: number) => `${orgId}/${appId}/${API_PATH.menuProfile}`,
    get_menu_profile_api: (orgId: number, appId: number, menuProfileId: number) => `${orgId}/${appId}/${API_PATH.menuProfile}/${menuProfileId}`,
    put_menu_profile_api: (orgId: number, appId: number, menuProfileId: number) => `${orgId}/${appId}/${API_PATH.menuProfile}/${menuProfileId}`,
    delete_menu_profile_api: (orgId: number, appId: number, menuProfileId: number) => `${orgId}/${appId}/${API_PATH.menuProfile}/${menuProfileId}`
}

export const getAllMenuProfileApi = async (orgId: any, appId: any, dispatch: any) => {
    dispatch(setLoading(true));
    try {
        const response = await Axios.get(`${API_DOMAIN}${MENU_PROFILE_API.get_all_menu_profile_api(orgId, appId)}`)
        dispatch(setLoading(false));
        return response?.data;
    }
    catch (error: any) {
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const getMenuProfileApi = async (orgId: any, appId: any, menuProfileId: any, dispatch: any) => {
    dispatch(setLoading(true));
    try {
        const response = await Axios.get(`${API_DOMAIN}${MENU_PROFILE_API.get_menu_profile_api(orgId, appId, menuProfileId)}`)
        dispatch(setLoading(false));
        return response?.data;
    }
    catch (error: any) {
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const putMenuProfileApi = async (orgId: any, appId: any, menuProfileId: any, payload: any, dispatch: any) => {
    dispatch(setLoading(true));
    try {
        const response = await Axios.put(`${API_DOMAIN}${MENU_PROFILE_API.put_menu_profile_api(orgId, appId, menuProfileId)}`, payload)
        dispatch(setLoading(false));
        return response;
    }
    catch (error: any) {
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const postMenuProfileApi = async (orgId: any, appId: any, payload: any, dispatch: any) => {
    dispatch(setLoading(true));
    try {
        const response = await Axios.post(`${API_DOMAIN}${MENU_PROFILE_API.post_menu_profile_api(orgId, appId)}`, payload)
        dispatch(setLoading(false));
        return response;
    }
    catch (error: any) {
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const deleteMenuProfileApi = async (orgId: any, appId: any, menuProfileId: any, dispatch: any, setUsers: any, menuProfile: any, rowsPerPage: number, setPage: any) => {
    dispatch(setLoading(true));
    try {
        const response = await Axios.delete(`${API_DOMAIN}${MENU_PROFILE_API.delete_menu_profile_api(orgId, appId, menuProfileId,)}`)
        const data = await getAllMenuProfileApi(orgId, appId, dispatch);
        console.log(data);
        if (data) {
            if(menuProfile?.length > rowsPerPage){
               ((menuProfile?.length - 1)) % rowsPerPage === 0 ? setPage((prev: number) => prev - 1) : setPage((prev: number) => prev);
            }
            setUsers(data);
        } 
        dispatch(setLoading(false));
        return response;
    }
    catch (error: any) {
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}