import { configureStore } from "@reduxjs/toolkit";
import tasksReducer from './slices/taskslice'
import { Provider } from "react-redux";

const store = configureStore({
    reducer: {
        tasks: tasksReducer
    }
});

export const StoreProviderComponent = (props: any) => {
    return (
        <Provider store={store}>
            {props.children}
        </Provider>
    )
}