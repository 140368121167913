import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { RoutesUrl } from '../../enum/routes-url';
import { NavigateFunction, useLocation, useNavigate, useParams } from 'react-router-dom';
import { getOrgsApi } from '../../config/api';
import { useDispatch } from 'react-redux';
import { Stack } from '@mui/material';
import { getOrgsIdAppApi } from '../../config/api/api-configs/org-api-config';
import Axios from '../../config/axios-config';

const AppDetails: any = (props: any) => {

    const navigate: NavigateFunction = useNavigate();

    const handleNavigate = () => {
        navigate(-1);
    }

    const handlePermissions = () => {
        navigate(`${RoutesUrl.PERMISSIONS}`, { state: { action: "view", showMenu: true, formName: "Permissions_" }});
    }

    return (

        <Card sx={{ maxWidth: 1000, marginX: 'auto', marginY: '20px', width: '100%' }}>
            <Stack direction='row' justifyContent='flex-end' margin={2}>
                <Button variant='contained' color='success' onClick={handlePermissions}>Permissions</Button>
            </Stack>
            <CardContent>
                <Stack direction='row' flexDirection='column'>
                    {
                        Object.keys(props.state).map((list: string, index) => {
                            return <Stack key={index} direction='row' borderBottom='1px solid #ccc' spacing={2}>
                                <Stack width='50%'>{list !== 'id' ? <Typography variant='h2'>{list.replace(/_/g, " ").toLowerCase().replace(/\b\w/g, (c) => c.toUpperCase())} : </Typography> : null}</Stack>
                                <Stack width='50%'>{list !== 'id' ? <Typography variant='h3'>{typeof (props.state[list]) === 'boolean' ? props.state[list].toString() : props.state[list] === null ? '--' : props.state[list]}</Typography> : null}</Stack>
                            </Stack>
                        })
                    }
                </Stack>
            </CardContent>
            <CardActions sx={{ width: '100%', justifyContent: 'center', marginTop: 2 }}>
                <Button variant='contained' color='primary' onClick={handleNavigate}>Back</Button>
            </CardActions>
        </Card>
    )
}

export default AppDetails