import { setLoading, showErrorModal } from "../../../store/slices/taskslice";
import Axios, { API_DOMAIN, API_PATH } from "../../axios-config";

const ENTITIES_FIELD_MAPPING_API = {
    post_enitity_field_mapping_api : (orgId: number, appId: number)=>`${orgId}/${appId}/${API_PATH.entityFieldMapping}`,
    get_all_enitity_field_mapping_api : (orgId: number, appId: number,entityId: number)=>`${orgId}/${appId}/${API_PATH.entityFieldMapping}?entity_metadata_id=${entityId}&expandAll=true`,
    get_enitity_field_mapping_api : (orgId: number, appId: number, entityId: number,fieldId: number)=>`${orgId}/${appId}/${API_PATH.entityFieldMapping}?entity_metadata_id=${entityId}&expandAll=true&field_type_id=${fieldId}`,
    put_enitity_field_mapping_api : (orgId: number, appId: number, entityId: number,fieldId: number)=>`${orgId}/${appId}/${API_PATH.entityFieldMapping}/?entity_metadata_id=${entityId}&expandAll=true&field_type_id=${fieldId}`,
    delete_enitity_field_mapping_api : (orgId: number, appId: number, entityId: number,fieldId: number)=>`${orgId}/${appId}/${API_PATH.entityFieldMapping}`,
    // enitity_field_mapping_api : (orgId: number, appId: number)=>`${orgId}/${appId}/${API_PATH.entityFieldMapping}`,
}


export const getAllEntityFieldMappingApi = async (orgId: any,appId: any, dispatch: any,entityId: any)=>{
    dispatch(setLoading(true));
    try {
        const response = await Axios.get(`${API_DOMAIN}${ENTITIES_FIELD_MAPPING_API.get_all_enitity_field_mapping_api(orgId,appId,entityId)}`)
        dispatch(setLoading(false));
        return response?.data;
    }
    catch (error: any){
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const getEnitityFieldMappingApi = async (orgId: any,appId: any,entityId: any,dispatch: any,fieldId: any)=>{
    dispatch(setLoading(true));
    try {
        const response = await Axios.get(`${API_DOMAIN}${ENTITIES_FIELD_MAPPING_API.get_enitity_field_mapping_api(orgId,appId,entityId,fieldId)}`)
        dispatch(setLoading(false));
        return response?.data;
    }
    catch (error: any){
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const postEntityFieldMapping = async (orgId: any,appId: any,dispatch: any,payload: any)=>{
    dispatch(setLoading(true));
    try {
        const response = await Axios.post(`${API_DOMAIN}${ENTITIES_FIELD_MAPPING_API.post_enitity_field_mapping_api(orgId,appId)}`,payload)
        dispatch(setLoading(false));
        return response?.data;
    }
    catch (error: any){
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const putEnitityFieldMappingApi = async (orgId: any,appId: any,entityId: any,fieldId: any,dispatch: any,payload: any)=>{
    dispatch(setLoading(true));
    try {
        const response = await Axios.put(`${API_DOMAIN}${ENTITIES_FIELD_MAPPING_API.get_enitity_field_mapping_api(orgId,appId,entityId,fieldId)}`,payload)
        dispatch(setLoading(false));
        return response?.data;
    }
    catch (error: any){
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const deleteEntityFieldMappingApi = async (orgId: any, appId: any, entityId: any,fieldId: any,obj: any, setState: any,dispatch: any,state: any,rowsPerPage: number,setPage: any)=>{
    dispatch(setLoading(true));
    try {
        const response = await Axios.delete(`${API_DOMAIN}${ENTITIES_FIELD_MAPPING_API.delete_enitity_field_mapping_api(orgId,appId,entityId,fieldId)}`,{data:{entity_metadata_id: obj.entity_metadata_id,field_type_id:obj.field_type_id}})
        const data = await getAllEntityFieldMappingApi(orgId,appId, dispatch,entityId);
        if(data){
            if(data){
                if(state?.length > rowsPerPage){
                    ((state?.length - 1)) % rowsPerPage === 0 ? setPage((prev: number) => prev - 1) : setPage((prev: number) => prev);
                 }
                 setState(data);
            }
        }
        dispatch(setLoading(false));
        return response;
    }
    catch (error: any){
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}