import React, { Suspense, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { Link, NavigateFunction, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import { removeStorage } from '../utils/function';
import { ACCESS_TOKEN, REFRESH_TOKEN } from '../constant/constant';
import Axios, { API_PATH } from '../config/axios-config';
import Navbar from '../components/others/navbar';
import { RoutesUrl } from '../enum/routes-url';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getMyAdmin, setLoading } from '../store/slices/taskslice';
import FormTemplate from '../components/others/form-template';
import Loading from '../components/others/loading.component';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import PeopleIcon from '@mui/icons-material/People';
import GradingIcon from '@mui/icons-material/Grading';
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import GavelIcon from '@mui/icons-material/Gavel';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { BiLogOutCircle } from 'react-icons/bi';
import { getAllOrgsApi } from '../config/api';
import { IoIosApps, IoIosArrowDown, IoIosArrowUp, IoMdMenu } from "react-icons/io";
import { SlOrganization } from "react-icons/sl";
import { MdBackupTable, MdOutlineRuleFolder } from "react-icons/md";
import { TbCategoryFilled, TbCirclesRelation, TbListDetails } from "react-icons/tb";
import { FaUsers } from "react-icons/fa";
import { BsMenuUp } from "react-icons/bs";

const Home: React.FC = () => {

    const [dropDown, setDropDown] = React.useState<string>('');
    const [orgAppList, setOrgAppList] = useState(true);
    const [usersAndRoles, setUsersAndRoles] = useState(false);
    const [entitiesAndConfig, setEntitiesAndConfig] = useState(false);
    const [manageUsers, setManageUsers] = useState(false);
    const [appID, setAppID] = useState("");
    const [orgID, setOrgID] = useState("");

    const navigate: NavigateFunction = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { orgId, id } = useParams();

    useEffect(() => {
        getMyAdmin({ dispatch }, navigate);
    }, []);

    const handleDropDown = (value: string) => {
        if (value === 'apps') {
            setDropDown('apps');
            navigate(`${RoutesUrl.APPS}`, { state: { showMenu: false } });
        } else if (value === 'orgs') {
            setDropDown('orgs');
            navigate(`${RoutesUrl.ORGS}`, { state: { showMenu: false } });
        } else if (value === 'logout') {
            console.log("logout");
            handleLogout();
        }
    }

    const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value: any = event.target.value;
        handleDropDown(value);
    }

    const handleLogout = async () => {
        try {
            let logoutResponse = await Axios.post(API_PATH.logout);
            removeStorage(ACCESS_TOKEN);
            removeStorage(REFRESH_TOKEN);
            navigate(`/${RoutesUrl.LOGIN_PAGE}`);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Box display='flex' minHeight='100%'>
            <Stack width='30%' sx={{ width: '10%', borderRight: '1px solid #ccc', position: 'relative', height: '100vh' }}>
                <Stack direction='row' alignItems='center' spacing={2} sx={{ padding: '10px', cursor: 'pointer' }}>
                    <img src='/logo512.png' width='20%' />
                    <Typography variant='h6'
                        sx={{}}
                    >
                        <Link to='/' style={{ color: '#566a7f', display: 'flex', alignItems: 'center', gap: '10px' }}>Apyrn</Link>
                    </Typography>
                </Stack>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ marginBottom: '10px', paddingRight: '15px' }} onClick={() => setOrgAppList(!orgAppList)}>
                    <Typography sx={{ color: '#a1acb8', display: 'flex', alignItems: 'center', gap: '10px', cursor: 'pointer' }}>
                        <span style={{ width: '10px', height: '2px', backgroundColor: '#a1acb8', display: 'inline-block' }} ></span> Orgs and Apps</Typography>
                    {orgAppList ? <IoIosArrowDown style={{ color: '#a1acb8' }} /> : <IoIosArrowUp style={{ color: '#a1acb8' }} />}
                </Stack>
                {
                    orgAppList && <Stack sx={{ paddingLeft: '20px', cursor: 'pointer' }}> <Link to={`/orgs`} state={{ showMenu: true, formName: "Fields_" }}>
                        <Stack direction='row' alignItems='center' gap={1} marginBottom={1}>
                            <SlOrganization style={{ color: '#566a7f', fontSize: '1.2rem' }} />
                            <Typography variant='h6' color={'#566a7f'} sx={{ fontSize: '0.9rem' }} >Orgs</Typography>
                        </Stack>
                    </Link>
                        <Link to={`/apps`} state={{ showMenu: true, formName: "Entities_" }}>
                            <Stack direction='row' alignItems='center' gap={1} marginBottom={1}>
                                <IoIosApps style={{ color: '#566a7f', fontSize: '1.2rem' }} />
                                <Typography variant='h6' color={'#566a7f'} sx={{ fontSize: '0.9rem' }}>Apps</Typography>
                            </Stack>
                        </Link>
                    </Stack>
                }

                {/* {
                    id && <React.Fragment> */}
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ marginBottom: '10px', paddingRight: '15px' }} onClick={() => setEntitiesAndConfig(!entitiesAndConfig)}>
                    <Typography sx={{ color: '#a1acb8', display: 'flex', alignItems: 'center', gap: '10px', cursor: 'pointer' }}>
                        <span style={{ width: '10px', height: '2px', backgroundColor: '#a1acb8', display: 'inline-block' }} ></span> Entities and Config</Typography>
                    {entitiesAndConfig ? <IoIosArrowDown style={{ color: '#a1acb8' }} /> : <IoIosArrowUp style={{ color: '#a1acb8' }} />}
                </Stack>
                {
                    entitiesAndConfig && <Stack sx={{ paddingLeft: '20px', cursor: 'pointer' }}> <Link to={`${orgID || orgId}/${RoutesUrl.APPS}/${appID || id}/${RoutesUrl.APP_FIELDS}`} state={{ showMenu: true, formName: "Fields_" }}>
                        <Stack direction='row' alignItems='center' gap={1} marginBottom={1}>
                            <GradingIcon style={{ color: '#566a7f', fontSize: '1.2rem' }} />
                            <Typography variant='h6' color={'#566a7f'} sx={{ fontSize: '0.9rem' }}>Fields</Typography>
                        </Stack>
                    </Link>
                        <Link to={`${orgID || orgId}/${RoutesUrl.APPS}/${appID || id}/${RoutesUrl.APP_ENTITIES}`} state={{ showMenu: true, formName: "Entities_" }}>
                            <Stack direction='row' alignItems='center' gap={1} marginBottom={1}>
                                <MdBackupTable style={{ color: '#566a7f', fontSize: '1.2rem' }} />
                                <Typography variant='h6' color={'#566a7f'} sx={{ fontSize: '0.9rem' }}>Entities</Typography>
                            </Stack>
                        </Link>
                        <Link to={`${orgID || orgId}/${RoutesUrl.APPS}/${appID || id}/${RoutesUrl.RELATION}`} state={{ showMenu: true, formName: "Relation_" }}>
                            <Stack direction='row' alignItems='center' gap={1} marginBottom={1}>
                                <TbCirclesRelation style={{ color: '#566a7f', fontSize: '1.2rem' }} />
                                <Typography variant='h6' color={'#566a7f'} sx={{ fontSize: '0.9rem' }}>Relation</Typography>
                            </Stack>
                        </Link>
                        <Link to={`${orgID || orgId}/${RoutesUrl.APPS}/${appID || id}/${RoutesUrl.APP_RULES}`} state={{ showMenu: true, formName: "Rules_" }}>
                            <Stack direction='row' alignItems='center' gap={1} marginBottom={1}>
                                <MdOutlineRuleFolder style={{ color: '#566a7f', fontSize: '1.2rem' }} />
                                <Typography variant='h6' color={'#566a7f'} sx={{ fontSize: '0.9rem' }}>Rules</Typography>
                            </Stack>
                        </Link>

                    </Stack>
                }

                {/* </React.Fragment> */}
                {/* } */}
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ marginBottom: '10px', paddingRight: '15px' }} onClick={() => setManageUsers(!manageUsers)}>
                    <Typography sx={{ color: '#a1acb8', display: 'flex', alignItems: 'center', gap: '10px', cursor: 'pointer' }}>
                        <span style={{ width: '10px', height: '2px', backgroundColor: '#a1acb8', display: 'inline-block' }} ></span> Menu</Typography>
                    {manageUsers ? <IoIosArrowDown style={{ color: '#a1acb8' }} /> : <IoIosArrowUp style={{ color: '#a1acb8' }} />}
                </Stack>
                {manageUsers && <Stack sx={{ paddingLeft: '20px', cursor: 'pointer' }}>
                    <Link to={`${orgID || orgId}/${RoutesUrl.APPS}/${appID || id}/${RoutesUrl.CATEGORY}`} state={{ showMenu: true, formName: "" }}>
                        <Stack direction='row' alignItems='center' gap={1} marginBottom={1}>
                            <TbCategoryFilled style={{ color: '#566a7f', fontSize: '1.2rem' }} />
                            <Typography variant='h6' color={'#566a7f'} sx={{ fontSize: '0.9rem' }}>Category</Typography>
                        </Stack>
                    </Link>
                    <Link to={`${orgID || orgId}/${RoutesUrl.APPS}/${appID || id}/${RoutesUrl.MENU_PROFILE}`} state={{ showMenu: true, formName: "" }}>
                        <Stack direction='row' alignItems='center' gap={1} marginBottom={1}>
                            <IoMdMenu style={{ color: '#566a7f', fontSize: '1.2rem' }} />
                            <Typography variant='h6' color={'#566a7f'} sx={{ fontSize: '0.9rem' }}>Menu Profile</Typography>
                        </Stack>
                    </Link>
                    <Link to={`${orgID || orgId}/${RoutesUrl.APPS}/${appID || id}/${RoutesUrl.MENU_PROFILE_DETAILS}`} state={{ showMenu: true, formName: "" }}>
                        <Stack direction='row' alignItems='center' gap={1} marginBottom={1}>
                            <TbListDetails style={{ color: '#566a7f', fontSize: '1.2rem' }} />
                            <Typography variant='h6' color={'#566a7f'} sx={{ fontSize: '0.9rem' }}>Menu Profile Details</Typography>
                        </Stack>
                    </Link>
                    <Link to={`${orgID || orgId}/${RoutesUrl.APPS}/${appID || id}/${RoutesUrl.MENU_PROFILE_ROLES}`} state={{ showMenu: true, formName: "" }}>
                        <Stack direction='row' alignItems='center' gap={1} marginBottom={1}>
                            <BsMenuUp style={{ color: '#566a7f', fontSize: '1.2rem' }} />
                            <Typography variant='h6' color={'#566a7f'} sx={{ fontSize: '0.9rem' }}>Menu Profile Roles</Typography>
                        </Stack>
                    </Link>

                    {/* <Link to='/admins'>
                        <Stack direction='row' alignItems='center' gap={1} marginY={1}>
                            <SupervisorAccountIcon style={{ color: '#566a7f' }} />
                            <Typography variant='h6' color={'#566a7f'}>Admins</Typography>
                        </Stack>
                    </Link>
                    <Link to='/admins-roles'>
                        <Stack direction='row' alignItems='center' gap={1} marginY={1}>
                            <AdminPanelSettingsIcon style={{ color: '#566a7f' }} />
                            <Typography variant='h6' color={'#566a7f'}>Admins Roles</Typography>
                        </Stack>
                    </Link> */}
                </Stack>
                }
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ marginBottom: '10px', paddingRight: '15px' }} onClick={() => setUsersAndRoles(!usersAndRoles)}>
                    <Typography sx={{ color: '#a1acb8', display: 'flex', alignItems: 'center', gap: '10px', cursor: 'pointer' }} >
                        <span style={{ width: '10px', height: '2px', backgroundColor: '#a1acb8', display: 'inline-block' }} ></span> Users and Roles</Typography>
                    {usersAndRoles ? <IoIosArrowDown style={{ color: '#a1acb8' }} /> : <IoIosArrowUp style={{ color: '#a1acb8' }} />}
                </Stack>
                {
                    usersAndRoles && <Stack sx={{ paddingLeft: '20px', cursor: 'pointer' }}>
                        <Link to={`${orgID || orgId}/${RoutesUrl.APPS}/${appID || id}/${RoutesUrl.APP_USERS}`} state={{ showMenu: true, formName: "Users_" }}>
                            <Stack direction='row' alignItems='center' gap={1} marginBottom={1}>
                                <FaUsers style={{ color: '#566a7f', fontSize: '1.2rem' }} />
                                <Typography variant='h6' color={'#566a7f'} sx={{ fontSize: '0.9rem' }}>Users</Typography>
                            </Stack>
                        </Link>
                        <Link to={`${orgID || orgId}/${RoutesUrl.APPS}/${appID || id}/${RoutesUrl.APP_ROLES}`} state={{ showMenu: true, formName: "Roles_" }} style={{ marginBottom: '15px' }}>
                            <Stack direction='row' alignItems='center' gap={1} marginBottom={1}>
                                <SupervisedUserCircleIcon style={{ color: '#566a7f', fontSize: '1.2rem' }} />
                                <Typography variant='h6' color={'#566a7f'} sx={{ fontSize: '0.9rem' }}>Roles</Typography>
                            </Stack>
                        </Link>
                    </ Stack>
                }
                <Typography onClick={handleLogout} sx={{ position: 'absolute', left: '20px', bottom: '10px', color: '#566a7f', display: 'flex', gap: '5px', alignItems: 'center', cursor: 'pointer' }} ><BiLogOutCircle size={"1.1rem"} />Logout</Typography>
            </Stack>
            <Stack width='90%'>
                <Navbar handleSelect={handleSelect} dropDown={dropDown} setDropDown={setDropDown} setAppID={setAppID} setOrgID={setOrgID} />
                <Box>
                    {
                        location.state?.showMenu ? <FormTemplate /> : ""
                    }
                    <Outlet />
                </Box>
            </Stack>
        </Box >
    )
}

export default Home
