import PagesTemplate from '../components/others/pages-template';
import { Outlet, useLocation } from 'react-router-dom';
import { RoutesUrl } from '../enum/routes-url';
import AdminsTable from '../components/page-admins-appmanager/admins-table';



const AdminsPage: React.FC = () => {

  const location = useLocation();  

  return (
      <PagesTemplate>
        {
          (location.pathname === `/${RoutesUrl.ADMINS}`) ? <AdminsTable /> : <Outlet />
        }
      </PagesTemplate>
  )
}


export default AdminsPage