import { setLoading, showErrorModal } from "../../../store/slices/taskslice";
import Axios, { API_DOMAIN } from "../../axios-config";

const RULES_API = {
    post_rules_api : (orgId: number, appId: number,metaDataId: number)=>`${orgId}/${appId}/${metaDataId}/rules`,
    get_all_rules_api : (orgId: number, appId: number)=>`${orgId}/${appId}/rules?expandAll=true`,
    get_rules_api : (orgId: number, appId: number,metaDataId: number, rulesId: number)=>`${orgId}/${appId}/${metaDataId}/rules/${rulesId}`,
    put_rules_api : (orgId: number, appId: number,metaDataId: number, rulesId: number)=>`${orgId}/${appId}/${metaDataId}/rules/${rulesId}`,
    delete_rules_api : (orgId: number, appId: number,metaDataId: number, rulesId: number)=>`${orgId}/${appId}/${metaDataId}/rules/${rulesId}`,
}

export const getAllRulesApi = async (orgId: any,appId: any,dispatch: any)=>{
    dispatch(setLoading(true));
    try {
        const response = await Axios.get(`${API_DOMAIN}${RULES_API.get_all_rules_api(orgId,appId)}`);
        dispatch(setLoading(false));
        return response?.data
    }
    catch (error: any){
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const getRulesApi = async (orgId: any,appId: any,metaDataId: any,rulesId: any,dispatch: any)=>{
    dispatch(setLoading(true));
    try {
        const response = await Axios.get(`${API_DOMAIN}${RULES_API.get_rules_api(orgId,appId,metaDataId,rulesId)}`)
        dispatch(setLoading(false));
        return response?.data
    }
    catch (error: any){
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const putRulesApi = async (orgId: any,appId: any,metaDataId: any,rulesId: any,payload: any,dispatch: any)=>{
    dispatch(setLoading(true));
    try {
        const response = await Axios.put(`${API_DOMAIN}${RULES_API.put_rules_api(orgId,appId,metaDataId,rulesId)}`,payload)
        dispatch(setLoading(false));
        return response;
    }
    catch (error: any){
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const postRulesApi = async (orgId: any,appId: any,metaDataId: any,payload: any,dispatch: any)=>{
    dispatch(setLoading(true));
    try {
        const response = await Axios.post(`${API_DOMAIN}${RULES_API.post_rules_api(orgId,appId,metaDataId)}`,payload)
        dispatch(setLoading(false));
        return response;
    }
    catch (error: any){
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const deleteRulesApi = async (orgId: any,appId: any,metaDataId: any,rulesId: any, dispatch: any,setRules: any,rules: any,rowsPerPage: number,setPage: any)=>{
    dispatch(setLoading(true));
    try {
        const response = await Axios.delete(`${API_DOMAIN}${RULES_API.delete_rules_api(orgId,appId,metaDataId,rulesId)}`)
        const data = await getAllRulesApi(orgId, appId, dispatch);
        if(data) {
            if(rules?.length > rowsPerPage){
                ((rules?.length - 1)) % rowsPerPage === 0 ? setPage((prev: number) => prev - 1) : setPage((prev: number) => prev);
             }
            setRules(data);
        }
        dispatch(setLoading(false));
        return response;
    }
    catch (error: any){
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}