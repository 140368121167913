import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { Button, FormLabel, Stack, TextField, Typography, FormHelperText, MenuItem } from '@mui/material';
import { NavigateFunction, useLocation, useNavigate, useParams } from 'react-router-dom';
import { RoutesUrl } from '../../enum/routes-url';
import { ToastContainer, toast } from 'react-toastify';
import ErrorModal from '../others/modals/error-modal';
import { getAllFieldsApi, getEntitysApi, postEntityApi, putEntityApi } from '../../config/api';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../others/loading.component';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import { entityFieldMapping } from '../../config/api/api-configs/entity-api-config';
import EntityCard from './entity-card';

function not(a: readonly number[], b: readonly number[]) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: readonly number[], b: readonly number[]) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

const EntitiesForm: React.FC = () => {

    const [state, setState] = React.useState<State>({
        name: "",
    });
    const [locationState, setLoactionState] = React.useState<"create" | "view" | "update">();
    const [disabled, setDisabled] = React.useState<boolean>(false);
    const [updatedisabled, setUpdateDisabled] = React.useState<boolean>(false);
    const [helperText, setHelperText] = React.useState<any>({
        name: "",
    });
    const [open, setOpen] = React.useState(false);
    const [checked, setChecked] = React.useState<any[]>([]);
    const [left, setLeft] = React.useState<any[]>([]);
    const [right, setRight] = React.useState<any[]>([]);
    const [getList, setGetList] = React.useState<any>([]);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const navigate: NavigateFunction = useNavigate();
    const location = useLocation();
    const { orgId, id, entityId } = useParams();
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state: any) => state.tasks);

    useEffect(() => {
        setLoactionState(location.state.action);
        if (location.state.action === `${RoutesUrl.VIEW}`) {
            setDisabled(true);
            (async () => {
                const data = await getEntitysApi(orgId, id, entityId, dispatch);
                if (data) setState(data);
            })();
        }

        if (location.state.action === `${RoutesUrl.UPDATE}`) {
            (async () => {
                const data = await getEntitysApi(orgId, id, entityId, dispatch);
                if (data) setState(data);
            })();
        }
    }, []);

    useEffect(() => {
        (async () => {
            const response = await getAllFieldsApi(orgId, id, dispatch);
            if (response) setLeft(response);
        })();
    }, []);

    useEffect(() => {
        setGetList(right);
        if (right?.length > 0) {
            setUpdateDisabled(true);
        } else {
            setUpdateDisabled(false);
        }
    }, [right]);

    const handleClose = () => setOpen(false);

    const handleNavigate = () => {
        navigate(-1);
    };

    const handleToggle = (value: any, id: number) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            const obj = getList.map((e: any) => id === e.id ? ({ critical: true, ...e }) : e);
            setGetList(obj);
        } else {
            let upd_obj = getList.findIndex((list: any) => list.id === id) as any;
            getList[upd_obj].critical = false;
            setGetList(getList);
        }

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    const handleAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (locationState === `${RoutesUrl.CREATE}`) {
            handleValidate();
        }
        else if (locationState === `${RoutesUrl.UPDATE}`) {
            handleValidate();
        }
    };

    const handleValidate = async () => {
        const error = {} as any
        if (!state.name) {
            error.name = "Name field is required"
        } else if (!/^[a-zA-Z0-9_]*$/.test(state.name)) {
            error.name = "Name should not contain special character except _";
        } else {
            error.name = ""
        }

        if (!error.name) {
            if (locationState === `${RoutesUrl.CREATE}`) {
                const postEntity = await postEntityApi(orgId, id, state, dispatch);                                 
                for (let i = 0; i < getList.length; i++) {
                    let obj = {} as any;
                    obj.entity_metadata_id = postEntity?.data?.id;
                    obj.critical = getList[i].critical ? getList[i].critical : false;
                    obj.sort_id = i;
                    obj.field_type_id = getList[i].id;
                     await entityFieldMapping(orgId, id, dispatch, obj);
                    console.log("payload", obj);
                }
                if (postEntity) {
                    setState({
                        name: "",
                    });
                    setRight([]);
                    const response = await getAllFieldsApi(orgId, id, dispatch);
                    if (response) setLeft(response);
                    toast.success('Created successfully', {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                } else {
                    setOpen(true);
                }
            }
            if (locationState === `${RoutesUrl.UPDATE}`) {
                const obj = Object.assign({}, state) as any;
                if (obj.id) {
                    delete obj.id
                }

                const updateRoles = await putEntityApi(orgId, id, entityId, obj, dispatch);
                if (updateRoles?.status === 200) {
                    toast.success('Orgsform Successfully Updated', {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                } else {
                    setOpen(true);
                }
            }
        }
        setHelperText({ ...helperText, ...error });
    }

    const handleChange = (e: any) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        });
    };

    const customList = (items: readonly number[]) => (
        <Paper sx={{ width: 300, height: 300, overflow: 'auto' }}>
            <List dense component="div" role="list">
                {items.map((value: any, index: number) => {
                    const labelId = `transfer-list-item-${value}-label`;
                    return (
                        <ListItem
                            key={value.id}
                            role="listitem"
                            button
                            onClick={handleToggle(value, value.id)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                id={labelId}
                                primary={`${value.key}`} />
                        </ListItem>
                    );
                })}
            </List>
        </Paper>
    );

    if (isLoading) {
        return <Loading />
    }

    if (location.state.action === `${RoutesUrl.VIEW}`) {
        return (
            <Stack direction='row' flexDirection='column' alignItems='center'>
                <Typography variant='h2'>{location.state.setFormName}</Typography>
                <EntityCard state={state} />
            </Stack>
        )
    }

    return (
        <Box sx={{ width: '100ch', margin: '2ch auto' }}>
            <Typography variant='h2' textAlign='center'>{location.state.setFormName}</Typography>
            <form onSubmit={(e) => { handleSubmit(e) }}>
                <Stack>
                    <FormLabel sx={{ marginBottom: '0.5ch' }}>Name</FormLabel>
                    <TextField
                        type='text'
                        size='small'
                        name='name'
                        disabled={disabled}
                        value={state.name || ""}
                        onChange={handleChange}
                    />
                </Stack>
                <FormHelperText id="outlined-weight-helper-text" error>{helperText.name || " "}</FormHelperText>
                {/* <Typography variant='body2'>In righthand list item check if only active the </Typography> */}
                <Grid container spacing={2} justifyContent="center" alignItems="center" marginBottom={4}>
                    <Grid item>{customList(left)}</Grid>
                    <Grid item>
                        <Grid container direction="column" alignItems="center">
                            <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                onClick={handleAllRight}
                                disabled={left.length === 0}
                                aria-label="move all right"
                            >
                                ≫
                            </Button>
                            <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                onClick={handleCheckedRight}
                                disabled={leftChecked.length === 0}
                                aria-label="move selected right"
                            >
                                &gt;
                            </Button>
                            <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                onClick={handleCheckedLeft}
                                disabled={rightChecked.length === 0}
                                aria-label="move selected left"
                            >
                                &lt;
                            </Button>
                            <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                onClick={handleAllLeft}
                                disabled={right.length === 0}
                                aria-label="move all left"
                            >
                                ≪
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item>{customList(right)}</Grid>
                </Grid>
                <Stack direction='row' justifyContent='center' alignItems='center' spacing={3}>
                    <Button
                        style={{ maxWidth: '20%' }}
                        variant='contained'
                        onClick={handleNavigate}
                    >Back
                    </Button>
                    {
                        locationState === `${RoutesUrl.VIEW}` ? null :
                            <Button variant='contained' color='success' type='submit' disabled={!updatedisabled}>
                                {locationState === `${RoutesUrl.CREATE}` ? "create" : "update"}
                            </Button>}

                </Stack>
            </form>
            <ToastContainer autoClose={1000} />
            <ErrorModal open={open} setOpen={setOpen} handleClose={handleClose} />
        </Box>
    )
}
interface State {
    name: string;
}

export default EntitiesForm