import { Box, Button, FormHelperText, FormLabel, Stack, TextField, MenuItem, Typography, FormControlLabel, Checkbox } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import React from 'react';
import { RoutesUrl } from '../../enum/routes-url';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getAllEntityApi, getAllEntityFieldMappingApi, getAllFieldsApi, getEnitityFieldMappingApi } from '../../config/api';
import { useDispatch, useSelector } from 'react-redux';
import { INITIAL_LIMIT } from '../../constant/constant';
import EntityFieldMappingCard from '../page-entities/entity-card';
import Loading from '../others/loading.component';
import CardComponent from '../others/modals/card-component';
import { putEnitityFieldMappingApi } from '../../config/api/api-configs/entity-field-mapping-config';
import { ToastContainer, toast } from 'react-toastify';
import ErrorModal from '../others/modals/error-modal';
import { entityFieldMapping } from '../../config/api/api-configs/entity-api-config';

const EntityFieldMappingForm: React.FC = () => {

    const location = useLocation();
    const [state, setState] = React.useState<State>({
        entity_metadata_id: location.state.setEntityId,
        field_type_id: "",
        sort_id: "",
        critical: false
    });
    const [helperText, setHelperText] = React.useState<any>({
        entity_metadata_id: "",
        field_type_id: '',
        sort_id: "",
        critical: false
    });
    const [entities, setEntities] = React.useState<string[]>([]);
    const [fields, setFields] = React.useState<string[]>([]);
    const [updatedisabled, setUpdateDisabled] = React.useState<boolean>(false);
    const [open, setOpen] = React.useState(false);
    const [disabledInput, setDisabledInput] = React.useState({
        entity: true,
        field: true,
    });

    const navigate = useNavigate();
    const { orgId, id, entityId, fieldId } = useParams();
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state: any) => state.tasks);

    React.useEffect(() => {
        // setDisabledInput(true);
        (async () => {
            if (location.state.action === `${RoutesUrl.VIEW}` || location.state.action === `${RoutesUrl.UPDATE}`) {
                const response = await getEnitityFieldMappingApi(orgId, id, entityId, dispatch, fieldId);
                if (response) {
                    state.entity_metadata_id = response.entity_metadata_id.id;
                    state.field_type_id = response.field_type_id.id;
                    state.sort_id = response.sort_id;
                    state.critical = response.critical;
                }
            }
            if (location.state.action === `${RoutesUrl.CREATE}`) {
                setDisabledInput({ field: false, entity: true });
            }
        })();
    }, []);

    React.useEffect(() => {
        (async () => {
            const response = await getAllEntityApi(orgId, id, dispatch);
            setEntities(response);
        })();
    }, []);

    React.useEffect(() => {
        (async () => {
            const response = await getAllFieldsApi(orgId, id, dispatch);
            setFields(response);
        })();
    }, [])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement> | any) => {

        let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
        console.log(value);

        setState({
            ...state,
            [e.target.name]: value
        });
        setUpdateDisabled(true);
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (location.state.action === `${RoutesUrl.CREATE}`) {
            handleValidate();
        }
        else if (location.state.action === `${RoutesUrl.UPDATE}`) {
            handleValidate();
        }
    };

    const handleValidate = async () => {
        const error = {} as any
        if (!state.entity_metadata_id) {
            error.entity_metadata_id = "Name field is required"
        }
        if (!state.field_type_id) {
            error.field = "Name field is required"
        }
        if (!state.critical) {
            error.critical = "Name field is required"
        }

        if (!error.entity_metadata_id && !error.field_type_id && !error.sort_id) {
            if (location.state.action === `${RoutesUrl.CREATE}`) {
                const obj = Object.assign({}, state) as any;
                // if (obj.critical === 1) {
                //     obj.critical = true;
                // }
                // if (obj.critical === 0) {
                //     obj.critical = false;
                // }
                obj.entity_metadata_id = Number(obj.entity_metadata_id);
                obj.sort_id = Number(obj.sort_id);
                
                const EntityFieldMappingCreate = await entityFieldMapping(orgId, id, dispatch, obj);
                if (EntityFieldMappingCreate) {
                    setState({
                        entity_metadata_id: location.state.setEntityId,
                        field_type_id: "",
                        sort_id: "",
                        critical: false
                    });
                    toast.success('EntityFieldMap Successfully Created', {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                } else {
                    setOpen(true);
                }
            }
            if (location.state.action === `${RoutesUrl.UPDATE}`) {
                const updateEntityFieldMap = await putEnitityFieldMappingApi(orgId, id, entityId, fieldId, dispatch, state)
                if (updateEntityFieldMap) {
                    toast.success('EntityFieldMap Successfully Updated', {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                } else {
                    setOpen(true);
                }
            }
        }

        setHelperText({ ...helperText, ...error })
    }

    const handleNavigate = () => {
        navigate(-1);
    }

    const handleClose = () => setOpen(false);

    if (isLoading) {
        return (
            <Loading />
        )
    }

    if (location.state.action === `${RoutesUrl.VIEW}`) {
        return (
            <Stack direction='row' flexDirection='column' alignItems='center'>
                <Typography variant='h2'>{location.state.setFormName}</Typography>
                <CardComponent state={state} />
            </Stack>
        )
    }

    return (
        <Box sx={{ width: '100ch', margin: '2ch auto' }}>
            <Typography variant='h2' textAlign='center'>{location.state.setFormName}</Typography>
            <form onSubmit={handleSubmit}>
                <Stack>
                    <FormLabel sx={{ marginBottom: '0.5ch' }}>Entity</FormLabel>
                    <FormControl fullWidth>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={state.entity_metadata_id || ""}
                            size='small'
                            name="entity_metadata_id"
                            disabled={disabledInput.entity}
                            onChange={handleChange}
                        >
                            {
                                entities?.map((entity: any, index: number) => (
                                    <MenuItem value={entity.id} key={index}>{entity.name}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Stack>
                <FormHelperText id="outlined-weight-helper-text" error>{helperText.entity || " "}</FormHelperText>
                <Stack>
                    <FormLabel sx={{ marginBottom: '0.5ch' }}>Field_Type</FormLabel>
                    <FormControl fullWidth>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={state.field_type_id || ""}
                            size='small'
                            name="field_type_id"
                            disabled={disabledInput.field}
                            onChange={handleChange}
                        >
                            {
                                fields?.map((field: any, index: number) => (
                                    <MenuItem value={field.id} key={index}>{field.key}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Stack>
                <FormHelperText id="outlined-weight-helper-text" error>{helperText.field_type_id || " "}</FormHelperText>
                <Stack>
                    <FormLabel sx={{ marginBottom: '0.5ch' }}>Sort ID</FormLabel>
                    <TextField
                        type='text'
                        size='small'
                        name='sort_id'
                        value={state.sort_id}
                        onChange={handleChange}
                    />
                </Stack>
                <FormHelperText id="outlined-weight-helper-text" error>{helperText.sort_id || " "}</FormHelperText>
                <Stack>
                    <FormControlLabel
                        name='critical'
                        value={state.critical}
                        control={<Checkbox checked={state.critical} onChange={handleChange} />}
                        label="critical"
                    />
                </Stack>
                <Stack direction='row' justifyContent='center' alignItems='center' spacing={3}>
                    <Button
                        style={{ maxWidth: '20%' }}
                        variant='contained'
                        onClick={handleNavigate}
                    >Back
                    </Button>
                    {
                        location.state.view === `${RoutesUrl.VIEW}` ? null :
                            <Button variant='contained' color='success' type='submit' disabled={!updatedisabled}>
                                {location.state.action === `${RoutesUrl.CREATE}` ? "create" : "update"}
                            </Button>}

                </Stack>
            </form>
            <ToastContainer autoClose={1000} />
            <ErrorModal open={open} setOpen={setOpen} handleClose={handleClose} />
        </Box>
    )
}

interface State {
    entity_metadata_id: string;
    field_type_id: string;
    sort_id: string;
    critical: boolean | any;
}

export default EntityFieldMappingForm