import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { Button, FormLabel, Stack, TextField, Typography, FormHelperText } from '@mui/material';
import { NavigateFunction, useLocation, useNavigate, useParams } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { RoutesUrl } from '../../enum/routes-url';
import { getAllEntityApi, getAllEntityFieldMappingApi, getRelationApi, postRelationApi } from '../../config/api';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import ErrorModal from '../others/modals/error-modal';
import Loading from '../others/loading.component';
import CardComponent from '../others/modals/card-component';

const RelationForm: React.FC = () => {

    const { orgId, id, relationId } = useParams();

    const [state, setState] = React.useState<Form>({
        type: "",
        primary_entity: "",
        name: "",
        field: "",
        label: "",
        name1: "",
        field1: "",
        label1: "",
    });
    const [relationDetails, setRelationDetails] = React.useState([]);
    const [disabled, setDisabled] = React.useState<boolean>(false);
    const [updatedisabled, setUpdateDisabled] = React.useState<boolean>(false);
    const [helperText, setHelperText] = React.useState<any>({});
    const [open, setOpen] = React.useState(false);
    const [getEntityId, setGetEntityId] = React.useState([]);
    const [getEntityId1, setGetEntityId1] = React.useState([]);
    const [foreignEntityId, setForeignEntityId] = React.useState([]);
    const [foreignEntityId1, setForeignEntityId1] = React.useState([]);
    const [fieldMappingId, setFieldMappingId] = React.useState([]);
    const [fieldMappingId1, setFieldMappingId1] = React.useState([]);

    const navigate: NavigateFunction = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const handleClose = () => setOpen(false);
    const { isLoading } = useSelector((state: any) => state.tasks);

    useEffect(() => {
        (async () => {
            if (location.state.action === "view") {
                const data = await getRelationApi(orgId, id, relationId, dispatch);
                setRelationDetails(data);
            }
            const data = await getAllEntityApi(orgId, id, dispatch);
            if (data) setGetEntityId(data);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const getForeignEntityId = getEntityId.filter((e: any) => e.name !== state.primary_entity);
            setForeignEntityId(getForeignEntityId);
            if (state.type === "many_to_many") {
                const getForeignEntityId1 = foreignEntityId.filter((e: any) => e.name !== state.name);
                setForeignEntityId1(getForeignEntityId1);
            }
        })()
    }, [state]);

    useEffect(() => {
        if (state.name) {
            let field_id;
            const findFieldId = getEntityId.filter((e: any) => e.name === state.name ? field_id = e.id : "");
            (async () => {
                const data = await getAllEntityFieldMappingApi(orgId, id, dispatch, field_id);
                setFieldMappingId(data);
            })();
        }
    }, [state.name]);

    useEffect(() => {
        if (state.name1) {
            let field_id1;
            getEntityId.filter((e: any) => e.name === state.name1 ? field_id1 = e.id : "");
            console.log(field_id1);

            (async () => {
                const data = await getAllEntityFieldMappingApi(orgId, id, dispatch, field_id1);
                console.log(data);
                setFieldMappingId1(data);
            })();
        }
    }, [state.name1]);


    // useEffect(() => {
    //     setLoactionState(location.state?.action);
    //     if (location.state?.action === `${RoutesUrl.VIEW}`) {
    //         setDisabled(true);
    //         (async () => {
    //             const data = await getAllEntityApi(orgId, id, dispatch);
    //             if (data) setState(data);
    //         })();
    //     }
    // }, []);

    const handleNavigate = () => {
        navigate(-1);
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (location.state.action === `${RoutesUrl.CREATE}`) {
            handleValidate();
        }
    };

    const handleValidate = async () => {
        const obj = Object.assign({}, state) as any;
        if (obj.type === "many_to_many") {
            obj.foreign_entities = [{ name: state.name, field: state.field, label: state.label }, { name: state.name1, field: state.field1, label: state.label1 }]
            delete obj.primary_entity;
        } else {
            obj.foreign_entities = [{ name: state.name, field: state.field, label: state.label }]
        }

        delete obj.name;
        delete obj.name1;
        delete obj.field;
        delete obj.field1;
        delete obj.label;
        delete obj.label1;
        console.log(obj);

        const postRelations = await postRelationApi(orgId, id, obj, dispatch);
        if (postRelations) {
            setState({
                type: "",
                primary_entity: "",
                name: "",
                field: "",
                label: "",
                name1: "",
                field1: "",
                label1: "",
            });
            toast.success('Created successfully', {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        } else {
            setOpen(true);
        }
    }

    const handleChange = (e: any) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        });
        setUpdateDisabled(true);
    };

    if (isLoading) {
        return <Loading />
    }

    if (location.state.action === `${RoutesUrl.VIEW}`) {
        return (
            <Stack direction='row' flexDirection='column' alignItems='center'>
                <Typography variant='h2'>{location.state.setFormName}</Typography>
                <CardComponent state={relationDetails} />
            </Stack>
        )
    }

    return (
        <Box sx={{ width: '100ch', margin: '2ch auto' }}>
            <Typography variant='h2' textAlign='center'>{location.state.setFormName}</Typography>
            <form onSubmit={(e) => { handleSubmit(e) }}>
                <Stack>
                    <FormLabel sx={{ marginBottom: '0.5ch' }}>Type</FormLabel>
                    <FormControl fullWidth>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={state.type}
                            size='small'
                            name="type"
                            disabled={disabled}
                            onChange={handleChange}
                        >
                            <MenuItem value='one_to_one'>one_to_one</MenuItem>
                            <MenuItem value='many_to_one'>many_to_one</MenuItem>
                            <MenuItem value='many_to_many'>many_to_many</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>
                <FormHelperText id="outlined-weight-helper-text" error>{helperText.type || " "}</FormHelperText>
                {
                    state.type === "many_to_many" ? "" && state.primary_entity === "" : <Stack>
                        <FormLabel sx={{ marginBottom: '0.5ch' }}>Primary Entity</FormLabel>
                        <FormControl>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={state.primary_entity || ""}
                                size='small'
                                name="primary_entity"
                                disabled={disabled}
                                onChange={handleChange}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 150
                                        }
                                    }
                                }}
                            >
                                {
                                    getEntityId.map((entity: any, index: any) => (
                                        <MenuItem key={index} value={entity.name}>{entity.name}</MenuItem>

                                    ))
                                }
                            </Select>
                        </FormControl>
                        <FormHelperText id="outlined-weight-helper-text" error>{helperText.primary_entity || " "}</FormHelperText>
                    </Stack>
                }

                <Stack paddingBottom='10px'>
                    <FormLabel sx={{ marginBottom: '0.5ch' }}>Foreign Entity</FormLabel>
                    <Stack sx={{ border: '1px solid #ccc', padding: '30px 30px 0 30px', marginBottom: "15px", borderRadius: "5px" }}>
                        <Stack>
                            <FormLabel sx={{ marginBottom: '0.5ch' }}>name</FormLabel>
                            <FormControl>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={state.name || ""}
                                    size='small'
                                    name="name"
                                    disabled={disabled}
                                    onChange={handleChange}
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 150
                                            }
                                        }
                                    }}
                                >
                                    {
                                        foreignEntityId.map((entity: any, index: any) => (
                                            <MenuItem key={index} value={entity.name}>{entity.name}</MenuItem>

                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Stack>
                        <FormHelperText id="outlined-weight-helper-text" error>{helperText.name || " "}</FormHelperText>
                        <Stack>
                            <FormLabel sx={{ marginBottom: '0.5ch' }}>Field</FormLabel>
                            <FormControl>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={state.field || ""}
                                    size='small'
                                    name="field"
                                    disabled={disabled}
                                    onChange={handleChange}
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 150
                                            }
                                        }
                                    }}
                                >
                                    {
                                        fieldMappingId?.map((entity: any, index: any) => (
                                            <MenuItem key={index} value={entity.field_type_id.key}>{entity.field_type_id.key}</MenuItem>

                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Stack>
                        <FormHelperText id="outlined-weight-helper-text" error>{helperText.field || " "}</FormHelperText>
                        <Stack>
                            <FormLabel sx={{ marginBottom: '0.5ch' }}>Label</FormLabel>
                            <TextField
                                type='text'
                                size='small'
                                name='label'
                                disabled={disabled}
                                value={state.label || ""}
                                onChange={handleChange}
                            />
                        </Stack>
                        <FormHelperText id="outlined-weight-helper-text" error>{helperText.label || " "}</FormHelperText>
                    </Stack>
                    {
                        state.type === "many_to_many" ?
                            <Stack sx={{ border: '1px solid #ccc', padding: '30px 30px 0 30px', marginBottom: "15px", borderRadius: "5px" }}>
                                <Stack>
                                    <FormLabel sx={{ marginBottom: '0.5ch' }}>name</FormLabel>
                                    <FormControl>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={state.name1 || ""}
                                            size='small'
                                            name="name1"
                                            disabled={disabled}
                                            onChange={handleChange}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 150
                                                    }
                                                }
                                            }}
                                        >
                                            {
                                                foreignEntityId1.map((entity: any, index: any) => (
                                                    <MenuItem key={index} value={entity.name}>{entity.name}</MenuItem>

                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Stack>
                                <FormHelperText id="outlined-weight-helper-text" error>{helperText.name || " "}</FormHelperText>
                                <Stack>
                                    <FormLabel sx={{ marginBottom: '0.5ch' }}>Field</FormLabel>
                                    <FormControl>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={state.field1 || ""}
                                            size='small'
                                            name="field1"
                                            disabled={disabled}
                                            onChange={handleChange}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 150
                                                    }
                                                }
                                            }}
                                        >
                                            {
                                                fieldMappingId1?.map((entity: any, index: any) => (
                                                    <MenuItem key={index} value={entity.field_type_id.key}>{entity.field_type_id.key}</MenuItem>

                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Stack>
                                <FormHelperText id="outlined-weight-helper-text" error>{helperText.field || " "}</FormHelperText>
                                <Stack>
                                    <FormLabel sx={{ marginBottom: '0.5ch' }}>Label</FormLabel>
                                    <TextField
                                        type='text'
                                        size='small'
                                        name='label1'
                                        disabled={disabled}
                                        value={state.label1 || ""}
                                        onChange={handleChange}
                                    />
                                </Stack>
                                <FormHelperText id="outlined-weight-helper-text" error>{helperText.label || " "}</FormHelperText>
                            </Stack> : null
                    }

                </Stack>
                <Stack direction='row' justifyContent='center' alignItems='center' spacing={3}>
                    <Button
                        style={{ maxWidth: '20%' }}
                        variant='contained'
                        onClick={handleNavigate}
                    >Back
                    </Button>
                    {
                        location.state.action === `${RoutesUrl.VIEW}` ? null :
                            <Button variant='contained' color='success' type='submit' disabled={!updatedisabled}>
                                Create
                            </Button>}

                </Stack>
            </form>
            <ToastContainer autoClose={1000} />
            <ErrorModal open={open} setOpen={setOpen} handleClose={handleClose} />
        </Box>
    )
}
interface Form {
    type: string;
    primary_entity?: string;
    name: string;
    field: string;
    label: string;
    name1?: string;
    field1?: string;
    label1?: string;
}

export default RelationForm