import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const ActionButton: React.FC<Props> = ({handleOpen,handleView,handleEdit,showDelete = false,showEdit = false}: Props) => {

    return (
        <Box sx={{ display: 'flex', gap: 4, justifyContent: 'center' }}>
            <Button variant='contained' color='primary' onClick={handleView}>View</Button>
            <Button variant='contained' color='success' disabled={showEdit} onClick={handleEdit}>Edit</Button>
            <Button variant='contained' color='error' disabled={showDelete} onClick={handleOpen}>Delete</Button>
        </Box>
    )
}

interface Props {
     handleOpen: () => void;
     handleView: () => void;
     handleEdit: () => void;
     showDelete?: boolean;
     showEdit?: boolean;
}

export default ActionButton