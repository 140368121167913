import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { Button, FormHelperText, FormLabel, MenuItem, Stack, Typography,FormControlLabel, Checkbox } from '@mui/material';
import { NavigateFunction, useLocation, useNavigate, useParams } from 'react-router-dom';
import { RoutesUrl } from '../../enum/routes-url';
import { getAllAdmins_AppManagerApi, getPermissionApi, postPermissionApi, putPermissionApi } from '../../config/api';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import ErrorModal from '../others/modals/error-modal';
import Loading from '../others/loading.component';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CardComponent from '../others/modals/card-component';

const PermissionsForm = () => {

  const { orgId, id, userId } = useParams();

  const [state, setState] = React.useState<State>({
    // user: "",
    create: false,
    update: false,
    read: false,
    remove: false,
    user_id: ""
  });
  const [helperText, setHelperText] = React.useState<any>({
    user_id: "",
    create: "",
    update: "",
    read: "",
    remove: ""
  });
  const [open, setOpen] = React.useState(false);
  const [updatedisabled, setUpdateDisabled] = React.useState<boolean>(false);

  const navigate: NavigateFunction = useNavigate();
  const location = useLocation();
  const { isLoading } = useSelector((state: any) => state.tasks);
  const [users, setUsers] = React.useState<string[]>([]);
  const dispatch = useDispatch();

  const handleClose = () => setOpen(false);

  useEffect(() => {
    (async () => {
      console.log(userId);
      
      const data = await getPermissionApi(id, userId, dispatch);
      if (data) setState(data);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const data = await getAllAdmins_AppManagerApi(dispatch);
      console.log(data);
      if (data) setUsers(data);
    })();
  }, []);

  const handleNavigate = () => {
    navigate(-1);
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (location.state.action === `${RoutesUrl.CREATE}` || location.state.action === `${RoutesUrl.UPDATE}`) {
      handleValidation();
    }
  }

  const handleValidation = async () => {
    const error = {} as any
    console.log(state);

    if (!state.user_id) {
      error.user_id = "Name field is required";
    } else {
      error.name = "";
    }
    console.log(error);


    if (!error.user) {
      if (location.state.action === `${RoutesUrl.CREATE}`) {
        const postPermission = await postPermissionApi(id, state, dispatch);
        if (postPermission) {
          setState({
            create: false,
            update: false,
            read: false,
            remove: false,
            user_id: ""
          });
          toast.success('Created successfully', {
            position: toast.POSITION.BOTTOM_RIGHT
          });
        } else {
          setOpen(true);
        }
      }
      if (location.state.action === `${RoutesUrl.UPDATE}`) {
        const obj = Object.assign({}, state) as any;
        if (obj.id) {
          delete obj.id
        }
        if (obj.app_id) {
          delete obj.app_id
        }
        const updatePermission = await putPermissionApi(id, obj, dispatch);
        if (updatePermission) {
          toast.success('Permissions Form Successfully Updated', {
            position: toast.POSITION.BOTTOM_RIGHT
          });
        } else {
          setOpen(true);
        }
      }
    }
    setHelperText({ ...helperText, ...error });
  }


  const handleChange = (e: any) => {
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    console.log(value);
    setState({
      ...state,
      [e.target.name]: value
    });
    setUpdateDisabled(true);
  }

  if (isLoading) {
    return <Loading />
  }

  if (location.state.action === `${RoutesUrl.VIEW}`) {
    return (
      <Stack direction='row' flexDirection='column' alignItems='center'>
        <Typography variant='h2'>{location.state.setFormName}</Typography>
        <CardComponent state={state} />
      </Stack>
    )
  }

  return (
    <>
      <Box sx={{ width: '100ch', margin: '2ch auto' }}>
        <Typography variant='h2' textAlign='center'>{location.state.setFormName}</Typography>
        <form onSubmit={(e) => { handleSubmit(e) }}>
          <Stack>
            <FormLabel sx={{ marginBottom: '0.5ch' }}>Users</FormLabel>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={state.user_id}
                size='small'
                name="user_id"
                onChange={handleChange}
              >
                {
                  users?.map((list: any, index: number) => (
                    <MenuItem key={index} value={list.id}>{list.email}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Stack>
          <FormHelperText id="outlined-weight-helper-text" error>{helperText.user || " "}</FormHelperText>
          <Stack>
            <FormControlLabel
              name='create'
              value={state.create}
              control={<Checkbox checked={state.create} onChange={handleChange} />}
              label="Create"
            />
          </Stack>
          <Stack>
            <FormControlLabel
              name='read'
              value={state.read}
              control={<Checkbox checked={state.read} onChange={handleChange} />}
              label="Read"
            />
          </Stack>
          <Stack>
            <FormControlLabel
              name='remove'
              value={state.remove}
              control={<Checkbox checked={state.remove} onChange={handleChange} />}
              label="Delete"
            />
            </Stack>
            <Stack>
            <FormControlLabel
              name='update'
              value={state.update}
              control={<Checkbox checked={state.update} onChange={handleChange} />}
              label="Update"
            />
            </Stack>

          <Stack direction='row' justifyContent='center' alignItems='center' spacing={3}>
            <Button
              style={{ maxWidth: '20%' }}
              variant='contained'
              onClick={handleNavigate}
            >Back
            </Button>
            {
              location.state?.action === `${RoutesUrl.VIEW}` ? null :
                <Button variant='contained' color='success' type='submit' disabled={!updatedisabled}>
                  {location.state?.action === `${RoutesUrl.CREATE}` ? "create" : "update"}
                </Button>}
          </Stack>
        </form>
        <ToastContainer autoClose={1000} />
        <ErrorModal open={open} setOpen={setOpen} handleClose={handleClose} />
      </Box>
    </>

  )
}

interface State {
  user?: string,
  create: boolean,
  update: boolean,
  read: boolean,
  remove: boolean,
  user_id?: string;
}

export default PermissionsForm