export enum RoutesUrl{
    LOGIN_PAGE = 'login',
    HOME = '/',
    APPS = 'apps',
    ADMINS = 'admins',
    ADMINS_ROLES = 'admins-roles',
    APPS_FORM = ':org_id/apps/:id',
    ORGS = 'orgs',
    ORGS_FORM= 'orgs/:id',
    CREATE ='create',
    UPDATE ='update',
    VIEW ='view',
    APP_ROLES='roles',
    APP_USERS='users',
    APP_FIELDS='field-types',
    APP_ENTITIES='entities',
    APP_RULES='rules',
    PERMISSIONS='permissions',
    ENTITY_FIELD_MAPPING='entity-field-mapping',
    MENU_PROFILE = 'menu-profile',
    RELATION = 'relations',
    MENU_PROFILE_ROLES = 'menu-profile-roles',
    MENU_PROFILE_DETAILS = 'menu-profile-details',
    CATEGORY = 'category',
}