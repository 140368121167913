import React, { useEffect } from 'react';
import { Box, Stack } from '@mui/material';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';
import { RoutesUrl } from '../../enum/routes-url';
import { getAppApi } from '../../config/api';
import { useDispatch } from 'react-redux';

const LinkItem = styled(Link)({
    color: "#3498db",
    fontSize: '1.5rem',
    "&:hover": {
        color: "#000000",
        cursor: 'pointer'
    }
});

const FormTemplate: React.FC = () => {

    const [systemName,setsystemName] = React.useState('');

    const location = useLocation();
    const dispatch = useDispatch();
    const { orgId, id } = useParams();

    useEffect(() => {
        if (location.state?.showMenu) {
            (async () => {
                const data = await getAppApi(orgId, id, dispatch);
                setsystemName(data?.system_name);
            })();
        }
    }, [location]);

    return (
        <Box sx={{ paddingX: '1.5rem', boxShadow: 'rgba(17, 12, 46, 0.15) 0px 48px 100px 0px' }}>
            <Stack direction='row' alignItems='center' justifyContent='space-between'>
                <Typography variant='h1'>{systemName?.replace(/_/g, " ").toLowerCase().replace(/\b\w/g, (c) => c.toUpperCase())}</Typography>
                {/* <Stack>
                    {
                        location.state?.showMenu ? <Stack direction='row' spacing={3}>
                            <LinkItem to={`${orgId}/${RoutesUrl.APPS}/${id}/${RoutesUrl.APP_ROLES}`} state={{ showMenu: true, formName: "Roles_" }} >Roles</LinkItem>
                            <LinkItem to={`${orgId}/${RoutesUrl.APPS}/${id}/${RoutesUrl.APP_USERS}`} state={{ showMenu: true, formName: "Users_" }}>Users</LinkItem>
                            <LinkItem to={`${orgId}/${RoutesUrl.APPS}/${id}/${RoutesUrl.APP_FIELDS}`} state={{ showMenu: true, formName: "Fields_" }}>Fields</LinkItem>
                            <LinkItem to={`${orgId}/${RoutesUrl.APPS}/${id}/${RoutesUrl.APP_ENTITIES}`} state={{ showMenu: true, formName: "Entities_" }}>Entities</LinkItem>
                            <LinkItem to={`${orgId}/${RoutesUrl.APPS}/${id}/${RoutesUrl.RELATION}`} state={{ showMenu: true, formName: "Relation_" }}>Relation</LinkItem>
                            <LinkItem to={`${orgId}/${RoutesUrl.APPS}/${id}/${RoutesUrl.APP_RULES}`} state={{ showMenu: true, formName: "Rules_" }}>Rules</LinkItem>
                            <LinkItem to={`${orgId}/${RoutesUrl.APPS}/${id}/${RoutesUrl.MENU_PROFILE}`} state={{ showMenu: true, formName: "Menu_Profile_" }}>Menu_Profile</LinkItem>
                        </Stack> : null
                    }
                </Stack> */}
            </Stack>
        </Box>
    )
}

export default FormTemplate