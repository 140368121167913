import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, Stack, Pagination } from '@mui/material';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';

import ActionButton from '../others/actionbutton';
import DeleteModal from '../others/modals/delete-modal';
import { useDispatch, useSelector } from 'react-redux';
import { RoutesUrl } from '../../enum/routes-url';
import { getAllFieldsApi } from '../../config/api';
import Loading from '../others/loading.component';
import { deleteFieldsApi } from '../../config/api/api-configs/fields-api-config';
import { INITIAL_LIMIT } from '../../constant/constant';

const FieldsTable = () => {

    const [fields, setFields] = React.useState([]);
    const [open, setOpen] = React.useState<boolean>(false);
    const [deleteId, setDeleteId] = React.useState();
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const navigate: NavigateFunction = useNavigate();
    const { orgId, id } = useParams();
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state: any) => state.tasks);

    React.useEffect(() => {
        (async () => {
            const data = await getAllFieldsApi(orgId, id, dispatch);
            if (data){
                let sortData = data?.sort((data1: any,data2: any)=>(data1.id > data2.id) ? 1 : (data1.id < data2.id) ? -1 : 0);
                setFields(sortData);
            } 
        })();
    }, []);

    const handleDelete = async () => {
        await deleteFieldsApi(orgId, id, deleteId, dispatch, setFields,fields,rowsPerPage,setPage);
        setOpen(false);
    };

    const handleOpen = (id: any) => {
        setDeleteId(id);
        setOpen(true);
    };

    const handleView = (fieldId: number) => {
        navigate(`${fieldId}`, { state: { action: "view", showMenu: true, formName: "Fields View Form", setFormName: "Fields_Details" } });
    };

    const handleEdit = (fieldId: number) => {
        navigate(`${fieldId}`, { state: { action: "update", showMenu: true, formName: "Fields Update Form", setFormName: "Fields_Update" } });
    };

    const handleCreate = () => {
        navigate(`${RoutesUrl.CREATE}`, { state: { action: "create", showMenu: true, formName: "Fields Create Form", setFormName: "Fields_Create" } });
    }

    const handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
      };

    if (isLoading) {
        return <Loading />
    }

    return (
        <Box>
            <Stack
                direction='row'
                alignItems='center'
                justifyContent='flex-end'
                my={2}
            >
                <Button variant='contained' color='success' onClick={handleCreate}>Add Field</Button>
            </Stack>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" sx={{ fontWeight: 700 }}>ID</TableCell>
                            <TableCell align="center" sx={{ fontWeight: 700 }}>NAME</TableCell>
                            <TableCell align="center" sx={{ fontWeight: 700 }}>TYPE</TableCell>
                            <TableCell align="center" sx={{ fontWeight: 700 }}>DATA_TYPE</TableCell>
                            <TableCell align="center" sx={{ fontWeight: 700 }}>LABEL</TableCell>
                            <TableCell align="center" sx={{ fontWeight: 700 }}>ACTIONS</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            fields?.slice((page-1) * rowsPerPage, page * rowsPerPage).map((fieldsList: any, index: any) => (
                                <TableRow key={index}>
                                    <TableCell align='center'>{fieldsList.id}</TableCell>
                                    <TableCell align='center'>{fieldsList.key}</TableCell>
                                    <TableCell align='center'>{fieldsList.type}</TableCell>
                                    <TableCell align='center'>{fieldsList.data_type}</TableCell>
                                    <TableCell align='center'>{fieldsList.label}</TableCell>
                                    <TableCell align='center'>
                                        <ActionButton
                                            handleView={() => handleView(fieldsList.id)}
                                            handleOpen={() => handleOpen(fieldsList.id)}
                                            handleEdit={() => handleEdit(fieldsList.id)}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
                <DeleteModal handleDelete={handleDelete} open={open} setOpen={setOpen} deleteId={deleteId} />
            </TableContainer>
            <Stack marginY={2} alignItems='center' direction='row' flexDirection='column'>
                <Pagination count={Math.ceil(fields?.length / rowsPerPage)} page={page} onChange={handlePagination} color='primary' />
            </Stack>
        </Box>
    )
}
export default FieldsTable