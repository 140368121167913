import React, { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, Stack, Typography,Pagination } from '@mui/material';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAdmins_AppManagerApi, getAllAdmins_AppManagerApi } from '../../config/api';
import ActionButton from '../others/actionbutton';
import Loading from '../others/loading.component';
import { RoutesUrl } from '../../enum/routes-url';
import DeleteModal from '../others/modals/delete-modal';

const AdminsTable: React.FC = () => {

    const [admins, setAdmins] = React.useState<string[]>([]);
    const [open, setOpen] = React.useState<boolean>(false);
    const [deleteId, setDeleteId] = React.useState();
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const { isLoading } = useSelector((state: any) => state.tasks);
    const dispatch = useDispatch();
    const navigate: NavigateFunction = useNavigate();

    useEffect(() => {
        (async () => {
            const data = await getAllAdmins_AppManagerApi(dispatch);  
            if (data){
                let sortData = data?.sort((data1: any,data2: any)=>(data1.id > data2.id) ? 1 : (data1.id < data2.id) ? -1 : 0);
                setAdmins(sortData);
            } 
        })();
    }, [])

    const handleOpen = (id: any) => {
        setDeleteId(id);
        setOpen(true);
    };

    const handleDelete = async () => {
        await deleteAdmins_AppManagerApi(deleteId, setAdmins, dispatch,admins,rowsPerPage,setPage);
        setOpen(false);
    };

    const handleView = (id: number) => {
        navigate(`${id}`, { state: { action: "view", showMenu: false, formName: "Admin_Details" } });
    }

    const handleEdit = (id: number) => {
        navigate(`${id}`, { state: { action: "update", showMenu: false, formName: "Admin_Update" } });
    }

    const handleCreate = () => {
        navigate(`${RoutesUrl.CREATE}`, { state: { action: "create", showMenu: false, formName: "Admin_Create" } });
    }

    const handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    if (isLoading) {
        return <Loading />
    }

    return (
        <Box>
            <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                m={2}
            >
                <Typography variant='h3'>Admins</Typography>
                <Button variant='contained' color='success' onClick={handleCreate}>Add Admin</Button>
            </Stack>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" sx={{ fontWeight: 700 }}>ID</TableCell>
                            <TableCell align="center" sx={{ fontWeight: 700 }}>NAME</TableCell>
                            <TableCell align="center" sx={{ fontWeight: 700 }}>EMAIL</TableCell>
                            <TableCell align="center" sx={{ fontWeight: 700 }}>ROLE</TableCell>
                            <TableCell align="center" sx={{ fontWeight: 700 }}>ACTIONS</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            admins?.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((admins_list: any, index: number) => (
                                <TableRow key={index}>
                                    <TableCell align='center'>{admins_list.id}</TableCell>
                                    <TableCell align='center'>{admins_list.name}</TableCell>
                                    <TableCell align='center'>{admins_list.email}</TableCell>
                                    <TableCell align='center'>{admins_list.role_id.name}</TableCell>
                                    <TableCell align='center'>
                                        <ActionButton
                                            handleView={() => handleView(admins_list.id)}
                                            handleOpen={() => handleOpen(admins_list.id)}
                                            handleEdit={() => handleEdit(admins_list.id)}
                                            showDelete = {admins.length === 1 ? true : false}
                                            showEdit = {admins.length === 1 ? true : false}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
                <DeleteModal handleDelete={handleDelete} open={open} setOpen={setOpen} deleteId={deleteId} />
            </TableContainer>
            <Stack marginY={2} alignItems='center' direction='row' flexDirection='column'>
                <Pagination count={Math.ceil(admins?.length / rowsPerPage)} page={page} onChange={handlePagination} color='primary' />
            </Stack>
        </Box>
    )
}

export default AdminsTable