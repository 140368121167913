import { setLoading, showErrorModal } from "../../store/slices/taskslice";
import Axios, { API_DOMAIN, API_PATH } from "../axios-config";

//login users
// export const getUsersApi = async ({dispatch} : any) => {
//     try {
//         dispatch(setLoading(true));
//         const response = await Axios.get(ORG_API.get_org_api);
//         dispatch(setLoading(false));
//         return response.data;
//     } catch (error) {
//         dispatch(setLoading(false));
//         dispatch(showErrorModal(error));
//     }
// }

//Support Admin
export const getAppSupportAdminApi = async (dispatch: any) => {
    dispatch(setLoading(true));
    try {
        const response =  await Axios.get(`${API_DOMAIN}${API_PATH.myPermissions}`);
        dispatch(setLoading(false));
         return response?.data;
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export { postOrgsApi, getAllOrgsApi, getOrgsApi, putOrgsApi } from './api-configs/org-api-config';
export { getAllAppApi, getAppApi, putAppApi, postAppApi } from './api-configs/app-api-config';
export { getAllRolesApi, getRolesApi, putRolesApi, postRolesApi } from './api-configs/roles-api-config';
export { getAllFieldsApi, getFieldsApi,putFieldsApi,postFieldsApi} from './api-configs/fields-api-config';
export { getAllEntityApi, getEntitysApi,putEntityApi,postEntityApi} from './api-configs/entity-api-config';
export { getAllUsersApi, getOneUsersApi,putUsersApi,postUsersApi} from './api-configs/users-api-config';
export { getAllRulesApi, getRulesApi,putRulesApi,postRulesApi} from './api-configs/rules-api-config';
export { getMyAdminApi, getAllAdminApi,getAdminIdApi,putAdminApi,postAdminApi,deleteAdminApi} from './api-configs/admin-api-config';
export { getPermissionApi,postPermissionApi,putPermissionApi,deletePermissionApi} from './api-configs/permissions-app-manager-api-config';
export { getAllAdminRoles_AppManagerApi,getAdminRoles_AppManagerApi,postAdminRoles_AppManagerApi,putAdminRoles_AppManagerApi,deleteAdminRoles_AppManagerApi} from './api-configs/admin-roles-app-manager-config';
export { getAllAdmins_AppManagerApi,getAdmins_AppManagerApi,postAdmins_AppManagerApi,putAdmins_AppManagerApi,deleteAdmins_AppManagerApi} from './api-configs/admins-app-manager-config';
export {getAllEntityFieldMappingApi,getEnitityFieldMappingApi,putEnitityFieldMappingApi,deleteEntityFieldMappingApi} from './api-configs/entity-field-mapping-config';
export {getAllRelationApi,getRelationApi,postRelationApi,deleteRelationApi} from './api-configs/relation-api-config';
export {getAllMenuProfileRolesApi,getMenuProfileRolesApi,putMenuProfileRolesApi,postMenuProfileRolesApi,deleteMenuProfileRolesApi} from './api-configs/menu-profile-roles-config';
export {getAllMenuProfileDetailsApi,getMenuProfileDetailsApi,putMenuProfileDetailsApi,postMenuProfileDetailsApi,deleteMenuProfileDetailsApi} from './api-configs/menu-profile-details';
export {getAllCategoryApi,getCategoryApi,putCategoryApi,postCategoryApi,deleteCategoryApi} from './api-configs/category-api-config';
