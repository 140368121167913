import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { Button, Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { NavigateFunction, useLocation, useNavigate, useParams } from 'react-router-dom';
import { RoutesUrl } from '../../enum/routes-url';
import { getAllUsersApi, getAppApi, postAppApi, putAppApi } from '../../config/api';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import Select from '@mui/material/Select';

import ErrorModal from '../others/modals/error-modal';
import Loading from '../others/loading.component';
import AppDetails from './app-details';
import { INITIAL_LIMIT } from '../../constant/constant';

const AppsForm: React.FC = () => {

    const [state, setState] = React.useState<State>({
        name: "",
        description: "",
        type: "",
        schema_type: "",
        system_name: "",
        active: true,
    });
    const [users, setUsers] = React.useState<string[]>([]);
    const [locationState, setLoactionState] = React.useState<"create" | "view" | "update">();
    const [disabled, setDisabled] = React.useState<boolean>(false);
    const [updatedisabled, setUpdateDisabled] = React.useState<boolean>(false);
    const [helperText, setHelperText] = React.useState<any>({
        name: "",
        system_name: "",
    });
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);

    const navigate: NavigateFunction = useNavigate();
    const location = useLocation();
    const { isLoading } = useSelector((state: any) => state.tasks);
    const { orgId, id } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        setLoactionState(location.state?.action);
        if (location.state?.action !== `${RoutesUrl.CREATE}`) {
            if (location.state?.action === `${RoutesUrl.VIEW}`) {
                setDisabled(true);
                (async () => {
                    const data = await getAppApi(orgId, id, dispatch);
                    if (data) setState(data);
                })();
            }

            if (location.state?.action === `${RoutesUrl.UPDATE}`) {
                (async () => {
                    const data = await getAppApi(orgId, id, dispatch);
                    if (data) setState(data);
                })();
            }
        }
    }, []);

    useEffect(() => {
        (async () => {
            const data = await getAllUsersApi(orgId, id, dispatch);
            setUsers(data);
        })();
    }, [])

    const handleNavigate = () => {
        navigate(-1);
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (locationState === `${RoutesUrl.CREATE}`) {
            handleValidation();
        }
        else if (locationState === `${RoutesUrl.UPDATE}`) {
            handleValidation();
        }
    }

    const handleValidation = async () => {
        const error = {} as any
        if (!state.name) {
            error.name = "Name field is required";
        } else if (!/^\D*$/.test(state.name)) {
            error.name = "Name should not contain number";
        } else if (!(state.name.trim().length > 0)) {
            error.name = "Name should not contain empty space";
        } else {
            error.name = "";
        }
        if (!state.system_name) {
            error.system_name = "System Name field is required";
        } else if (!/^[a-zA-Z0-9_]*$/.test(state.system_name)) {
            error.system_name = "System Name should not contain special character except _";
        } else {
            error.system_name = "";
        } if (!state.org_id) {
            error.org_id = "Name field is required";
        }
        if (!state.description) {
            delete state.description;
        }
        if (!state.type) {
            delete state.type;
        }
        if (!state.schema_type) {
            delete state.schema_type;
        }

        if (!error.name && !error.legal_name && !error.system_name && !error.logo) {
            if (locationState === `${RoutesUrl.CREATE}`) {
                const postOrgs = await postAppApi(orgId, state, dispatch);
                if (postOrgs) {
                    setState({
                        name: "",
                        description: "",
                        type: "",
                        schema_type: "",
                        system_name: "",
                        active: true,
                    });
                    toast.success('Created successfully', {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                } else {
                    setOpen(true);
                }
            }
            if (locationState === `${RoutesUrl.UPDATE}`) {
                const obj = Object.assign({}, state) as any;
                if (obj.id) {
                    delete obj.id
                }
                if (obj.org_id) {
                    delete obj.org_id
                }
                if (obj.system_name) {
                    delete obj.system_name
                }
                const updateOrgs = await putAppApi(orgId, id, obj, dispatch);
                if (updateOrgs) {
                    toast.success('Appsform Successfully Updated', {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                } else {
                    setOpen(true);
                }
            }
        }
        setHelperText({ ...helperText, ...error });
    }

    const handleChange = (e: any) => {

        let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value

        if (state.active === "true") {
            state.active = true;
        }
        else if (state.active === "false") {
            state.active = false;
        }
        setState({
            ...state,
            [e.target.name]: value
        });
        setUpdateDisabled(true);
    }

    const handlePermissions = () => {
        navigate(`${RoutesUrl.PERMISSIONS}`, { state: { action: "view", showMenu: true, formName: "Permissions_" } });
    }

    if (isLoading) {
        return (
            <Loading />
        )
    }

    if (location.state.action === "view") {
        return <AppDetails state={state} />
    }

    return (
        <>
            <Box sx={{ width: '100ch', margin: '2ch auto' }}>
                <Stack direction='row' justifyContent='flex-end'>
                    <Button variant='contained' color='success' onClick={handlePermissions}>Permissions</Button>
                </Stack>
                <Typography variant='h1'>{location.state.setName}</Typography>
                <form onSubmit={(e) => { handleSubmit(e) }}>
                    <Stack>
                        <FormLabel sx={{ marginBottom: '0.5ch' }}>Name</FormLabel>
                        <TextField
                            type='text'
                            size='small'
                            name='name'
                            disabled={disabled}
                            value={state.name}
                            onChange={handleChange}
                        />
                    </Stack>
                    <FormHelperText id="outlined-weight-helper-text" error>{helperText.name || " "}</FormHelperText>
                    <Stack>
                        <FormLabel sx={{ marginBottom: '0.5ch' }}>Description</FormLabel>
                        <TextField
                            type='text'
                            size='small'
                            name='description'
                            disabled={disabled}
                            value={state.description || ""}
                            onChange={handleChange}
                        />
                    </Stack>
                    <FormHelperText id="outlined-weight-helper-text" error>{" "}</FormHelperText>
                    <Stack>
                        <FormLabel sx={{ marginBottom: '0.5ch' }}>Type</FormLabel>
                        <TextField
                            type='text'
                            size='small'
                            name='type'
                            disabled={disabled}
                            value={state.type || ""}
                            onChange={handleChange}
                        />
                    </Stack>
                    <FormHelperText id="outlined-weight-helper-text" error>{" "}</FormHelperText>
                    <Stack>
                        <FormLabel sx={{ marginBottom: '0.5ch' }}>Schema Type</FormLabel>
                        <TextField
                            type='text'
                            size='small'
                            name='schema_type'
                            disabled={disabled}
                            value={state.schema_type || ""}
                            onChange={handleChange}
                        />
                    </Stack>
                    <FormHelperText id="outlined-weight-helper-text" error>{" "}</FormHelperText>
                    <Stack>
                        <FormLabel sx={{ marginBottom: '0.5ch' }}>System Name</FormLabel>
                        <TextField
                            type='text'
                            size='small'
                            name='system_name'
                            disabled={disabled}
                            value={state.system_name}
                            onChange={handleChange}
                        />
                    </Stack>
                    <FormHelperText id="outlined-weight-helper-text" error>{helperText.system_name || " "}</FormHelperText>
                    {
                        location.state.action === `${RoutesUrl.CREATE}` ? null : <Stack>
                            <FormControlLabel
                                name='active'
                                value={state.active}
                                control={<Checkbox checked={state.active} onChange={handleChange} />}
                                label="Active"
                            />
                        </Stack>
                    }

                    <FormHelperText id="outlined-weight-helper-text" error>{helperText.active || " "}</FormHelperText>
                    <Stack direction='row' justifyContent='center' alignItems='center' spacing={3}>
                        <Button
                            style={{ maxWidth: '20%' }}
                            variant='contained'
                            onClick={handleNavigate}
                        >Back
                        </Button>
                        {
                            locationState === `${RoutesUrl.VIEW}` ? null :
                                <Button variant='contained' color='success' type='submit' disabled={!updatedisabled}>
                                    {locationState === `${RoutesUrl.CREATE}` ? "create" : "update"}
                                </Button>}
                    </Stack>
                </form>
                <ToastContainer autoClose={1000} />
                <ErrorModal open={open} setOpen={setOpen} handleClose={handleClose} />
            </Box>
        </>
    )
}

interface State {
    name: string;
    description?: string;
    type?: string;
    schema_type?: string;
    system_name?: string;
    org_id?: string;
    email?: string;
    template?: string;
    active: any;
}

interface HelperText {
    name: string;
    system_name: string;
}

export default AppsForm