import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, Stack,Pagination } from '@mui/material';

import ActionButton from '../others/actionbutton';
import DeleteModal from '../others/modals/delete-modal';
import { NavigateFunction, useLocation, useNavigate, useParams } from 'react-router-dom';
import { RoutesUrl } from '../../enum/routes-url';
import { deleteRelationApi, getAllRelationApi } from '../../config/api';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../others/loading.component';

const RelationTable = () => {

  const [relations, setRelations] = React.useState([]);
  const [open, setOpen] = React.useState<boolean>(false);
  const [deleteId, setDeleteId] = React.useState();
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const { orgId, id } = useParams();
  const navigate: NavigateFunction = useNavigate();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state: any) => state.tasks);
  const location = useLocation();
  console.log(relations);
  

  React.useEffect(() => {
    (async () => {
      let data = await getAllRelationApi(orgId, id, dispatch);
      let sortData = data?.sort((data1: any,data2: any)=>(data1.id > data2.id) ? 1 : (data1.id < data2.id) ? -1 : 0);
      setRelations(sortData);
    })();
  }, [])

  const handleDelete = async () => {
    await deleteRelationApi(orgId, id, deleteId, dispatch, setRelations,relations,rowsPerPage,setPage);
    setOpen(false);
  };

  const handleOpen = (deleteId: any) => {
    setDeleteId(deleteId);
    setOpen(true);
  };

  const handleView = (relationId: number) => {
    navigate(`${relationId}`, { state: { action: "view", showMenu: true, formName: "Relation_", setFormName: "Relation_Details" } });
  };

  const handleEdit = (relationId: number) => {
    navigate(`${relationId}`, { state: { action: "update", showMenu: true, formName: "Relation_", setFormName: "Relation_Update" } });
  };

  const handleCreate = () => {
    navigate(`${RoutesUrl.CREATE}`, { state: { action: "create", showMenu: true, formName: "Relation_", setFormName: "Relation_Create" } });
  }

  const handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  if (isLoading) {
    return <Loading />
  }

  return (
    <Box>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='flex-end'
        my={2}
      >
        <Button variant='contained' color='success' onClick={handleCreate}>Add Relation</Button>
      </Stack>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{ fontWeight: 700 }}>ID</TableCell>
              <TableCell align="center" sx={{ fontWeight: 700 }}>PRIMARY ENTITY</TableCell>
              <TableCell align="center" sx={{ fontWeight: 700 }}>TYPE</TableCell>
              <TableCell align="center" sx={{ fontWeight: 700 }}>ACTIONS</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              relations?.slice((page-1) * rowsPerPage, page * rowsPerPage).map((relation: any, index: number) => {
                return (
                  <TableRow key={index}>
                    <TableCell align='center'>{relation.id}</TableCell>
                    <TableCell align='center'>{relation.primary_entity}</TableCell>
                    <TableCell align='center'>{relation.type}</TableCell>
                    <TableCell align='center'>
                      <ActionButton
                        handleView={() => handleView(relation.id)}
                        handleOpen={() => handleOpen(relation.id)}
                        handleEdit={() => handleEdit(relation.id)}
                        showEdit={true}
                      />
                    </TableCell>
                  </TableRow>
                )
              }
              )
            }
          </TableBody>
        </Table>
        <DeleteModal handleDelete={handleDelete} open={open} setOpen={setOpen} deleteId={deleteId} />
      </TableContainer>
      <Stack marginY={2} alignItems='center' direction='row' flexDirection='column'>
        <Pagination count={Math.ceil(relations?.length / rowsPerPage) || 0} page={page} onChange={handlePagination} color='primary' />
      </Stack>
    </Box>
  )
}


export default RelationTable