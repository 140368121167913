import { setLoading, showErrorModal } from "../../../store/slices/taskslice";
import Axios, { API_DOMAIN, API_PATH } from "../../axios-config";

export const PERMISSIONS_APP_MANAGER_API = {
    get_all_permissions_api : (appId: number)=>`${API_DOMAIN}${appId}/${API_PATH.permissions}?expandAll=true`,
    post_permission_api : (appId: number)=>`${API_DOMAIN}${appId}/${API_PATH.permissions}`,
    put_permission_api : (appId: number)=>`${API_DOMAIN}${appId}/${API_PATH.permissions}`,
    delete_permission_api : (appId: number)=>`${API_DOMAIN}${appId}/${API_PATH.permissions}`,
    get_permission_api : (appId: number,userId: number)=>`${API_DOMAIN}${appId}/${API_PATH.permissions}?user_id=${userId}`,
}

export const getAllPermissionApi = async (id: any,dispatch: any) => {
    dispatch(setLoading(true));
    try {
        const response = await Axios.get(`${PERMISSIONS_APP_MANAGER_API.get_all_permissions_api(id)}`);
        dispatch(setLoading(false));
        return response?.data;
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const getPermissionApi = async (appId: any,userId: any,dispatch: any) => {
    dispatch(setLoading(true));
    try {
        const response = await Axios.get(`${PERMISSIONS_APP_MANAGER_API.get_permission_api(appId,userId)}`);
        dispatch(setLoading(false));
        return response?.data;
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const postPermissionApi = async (appId: any,payload: any,dispatch: any) => {
    dispatch(setLoading(true));
    try {
        const response = await Axios.post(`${PERMISSIONS_APP_MANAGER_API.post_permission_api(appId)}`,payload);
        dispatch(setLoading(false));
        return response;
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const putPermissionApi = async (appId: any,payload: any,dispatch: any) => {
    dispatch(setLoading(true));
    try {
        const response = await Axios.put(`${PERMISSIONS_APP_MANAGER_API.put_permission_api(appId)}`,payload);
        dispatch(setLoading(false));
        return response;
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}

export const deletePermissionApi = async (deleteId: any,payload: any,setPermissions: any,dispatch: any,permissions: any,rowsPerPage: number,setPage: any) => {
    console.log(payload);
    dispatch(setLoading(true));
    try {
        const response = await Axios.delete(`${PERMISSIONS_APP_MANAGER_API.delete_permission_api(deleteId)}`,{ data:{user_id:payload.user_id} });
        const data = await getAllPermissionApi(deleteId,dispatch);
        if(data){
                if(permissions?.length > rowsPerPage){
                    ((permissions?.length - 1)) % rowsPerPage === 0 ? setPage((prev: number) => prev - 1) : setPage((prev: number) => prev);
                 }
                 setPermissions(data);
        }
        dispatch(setLoading(false));
        return response;
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(showErrorModal(error.response?.data.message));
    }
}