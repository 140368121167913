import PagesTemplate from '../components/others/pages-template';
import EntitiesTable from '../components/page-entities/entities-table';

const EntitiesPage: React.FC = () => {
  return (
      <PagesTemplate>
        <EntitiesTable />
      </PagesTemplate>
  )
}

export default EntitiesPage